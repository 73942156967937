import React, { useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { IconSVG } from "components/Common/IconSvg"

const ParentDetailsCol = ({ parentDetails }) => {
  return (
    <ul className="list-unstyled parent-details__container">
      <div className="d-flex justify-content-between">
        <CardTitle className="mb-4">
          <h3>Contact Info</h3>
        </CardTitle>
        <div className="history-doc-cta">
          <Link
            to={`/edit-parent/${parentDetails._id}`}
            className="font-fontFamily font-weight-text"
          >
            Edit <IconSVG icon="edit" />
          </Link>
        </div>
      </div>
      <Row>
        <Col sm="6">
          <li>
            <h6 className="parent-details__title font-fontFamily">Name</h6>
            <p className="parent-details__value font-fontFamily">
              {parentDetails?.fullName}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">Email</h6>
            <p className="parent-details__value font-fontFamily">
              {parentDetails?.email}
            </p>
          </li>
        </Col>
        <Col sm="6">
          <li>
            <h6 className="parent-details__title font-fontFamily">Gender</h6>
            <p className="parent-details__value font-fontFamily">
              {parentDetails?.gender ? parentDetails.gender : "NA"}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">Phone</h6>
            <p className="parent-details__value font-fontFamily">
              {parentDetails?.phone ? parentDetails.phone : "NA"}
            </p>
          </li>
        </Col>
      </Row>
    </ul>
  )
}

ParentDetailsCol.propTypes = {
  details: PropTypes.any,
}

export default ParentDetailsCol
