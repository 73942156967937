// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dates-container {
  border-bottom: 1px solid #ececec;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.dates__show-hide-cta {
  border: none;
  background: transparent;
  color: #e75c25;
  border-bottom: 1px solid;
  min-width: 120px;
}
.dates__show-hide-cta--disabled {
  opacity: 0.4;
  color: #444;
}

.timings {
  border: 1px solid #ececec;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
}

.btn-time {
  margin-right: 10px;
  margin-bottom: 10px;
  min-width: 100px;
}
.btn-time--unavailable {
  background: #ececec !important;
  color: #444 !important;
  border: transparent !important;
}
.btn-time--blocked {
  color: #000 !important;
  background: repeating-linear-gradient(-45deg, rgba(34, 34, 34, 0.45), rgba(34, 34, 34, 0.45) 1px, #fff 2px, #fff 7px) !important;
  border: 1px solid rgba(34, 34, 34, 0.45) !important;
}
.btn-time--blocked-by-time {
  background: #ddd !important;
  color: #444 !important;
  opacity: 0.2;
  pointer-events: none !important;
  border: transparent !important;
}

.numInputWrapper input {
  pointer-events: none;
}

.disabled-button {
  background-color: grey;
  cursor: not-allowed;
}

.active-button {
  background-color: orange;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Administration/components/DaySchedule.scss"],"names":[],"mappings":"AACE;EACE,gCAAA;EACA,oBAAA;EACA,mBAAA;AAAJ;AAEE;EACE,YAAA;EACA,uBAAA;EACA,cAAA;EACA,wBAAA;EACA,gBAAA;AAAJ;AACI;EACE,YAAA;EACA,WAAA;AACN;;AAIA;EACE,yBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AADF;;AAGA;EACE,kBAAA;EACA,mBAAA;EACA,gBAAA;AAAF;AACE;EACE,8BAAA;EACA,sBAAA;EACA,8BAAA;AACJ;AACE;EACE,sBAAA;EACA,gIAAA;EAOA,mDAAA;AALJ;AAOE;EACE,2BAAA;EACA,sBAAA;EACA,YAAA;EACA,+BAAA;EACA,8BAAA;AALJ;;AAQA;EACE,oBAAA;AALF;;AAQA;EACE,sBAAA;EACA,mBAAA;AALF;;AASA;EACE,wBAAA;EACA,eAAA;AANF","sourcesContent":[".dates {\n  &-container {\n    border-bottom: 1px solid #ececec;\n    padding-bottom: 20px;\n    margin-bottom: 20px;\n  }\n  &__show-hide-cta {\n    border: none;\n    background: transparent;\n    color: #e75c25;\n    border-bottom: 1px solid;\n    min-width: 120px;\n    &--disabled {\n      opacity: 0.4;\n      color: #444;\n    }\n  }\n}\n\n.timings {\n  border: 1px solid #ececec;\n  margin-top: 20px;\n  padding: 20px;\n  border-radius: 10px;\n}\n.btn-time {\n  margin-right: 10px;\n  margin-bottom: 10px;\n  min-width: 100px;\n  &--unavailable {\n    background: #ececec !important;\n    color: #444 !important;\n    border: transparent !important;\n  }\n  &--blocked {\n    color: #000!important;\n    background: repeating-linear-gradient(\n      -45deg,\n      rgba(34, 34, 34,0.45),\n      rgba(34, 34, 34,0.45) 1px,\n      #fff 2px,\n      #fff 7px\n    )!important;\n    border:1px solid rgba(34, 34, 34,0.45) !important;\n  }\n  &--blocked-by-time {\n    background: #ddd !important;\n    color: #444 !important;\n    opacity: 0.2;\n    pointer-events: none !important;\n    border: transparent !important;\n  }\n}\n.numInputWrapper input {\n  pointer-events: none;\n}\n\n.disabled-button {\n  background-color: grey;\n  cursor: not-allowed;\n  // border: 1px solid red;\n}\n\n.active-button {\n  background-color: orange;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
