import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Form,
  ModalHeader,
  ModalFooter,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

// actions
import {
  updateDayAvailabilityByDayIdAndDocId,
  updateStartAndEndTimeByDayIdAndDocId,
} from "helpers/api_helper"
import {
  getAllTimings,
  getDetails,
  getTimingDetails,
  getTimingsByDocId,
  getUserDetails,
  getVetCurrentTimings,
} from "store/slices"
import DaySchedule from "../pages/Administration/components/DaySchedule"

import { showMessage } from "components/Notification"
import { Carousel } from "react-responsive-carousel"
import ButtonMain from "./Common/Button"

const DoctorTimings = ({ isOpen, setModal, onChangeDate }) => {
  //meta title
  document.title = "Timing | Supaw"
  const userDetails = useSelector(getUserDetails)

  const timingDetails = useSelector(getAllTimings)

  const [nextDays, setNextDays] = useState(timingDetails.timings)

  const dispatch = useDispatch()

  const [isUpdatingButton, setIsUpdatingButton] = useState(false)

  const [isFetchingData, setFetchingData] = useState(true)
  const [idx] = useState(userDetails._id)

  // *****************************
  const [selectedDate, setSelectedDate] = useState(null)

  useEffect(() => {
    async function newLoad() {
      if (localStorage.getItem("authUser")) {
        setFetchingData(true)
        const res = await dispatch(getVetCurrentTimings(idx))
        setNextDays(res.timings)
        setFetchingData(false)
      }
    }
    newLoad()
  }, [])
  const onSelectedDate = (time, index) => {
    setSelectedDate({ time: time, dateIndex: index })

    onChangeDate({
      day: timingDetails.timings[index].day,
      time,
      dateIndex: index,
      timingId: timingDetails.timings[index]?._id,
      slotId: timingDetails.timings[index]?.slots.find((el)=> el.time === time)?._id,
    })
    setModal(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setModal(prevState => !prevState)}
      size="lg"
    >
      <ModalHeader toggle={() => setModal(false)}>Select Time</ModalHeader>
      <ModalBody className="py-3 px-5">
        <Row>
          {!isFetchingData ? (
            <Carousel showArrows showIndicators>
              {nextDays?.length
                ? nextDays.map((el, index) => {
                    return (
                      <DaySchedule
                        isCurrentDay={index === 0}
                        key={el._id}
                        value={el}
                        difference={30}
                        isUpdatingButton={isUpdatingButton}
                        setError={obj => handleError(el, obj)}
                        isSelectOnly
                        selectedDate={
                          selectedDate?.dateIndex === index
                            ? selectedDate.time
                            : null
                        }
                        onSelectedDate={time => onSelectedDate(time, index)}
                      />
                    )
                  })
                : "No Timings found"}
            </Carousel>
          ) : null}
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default DoctorTimings
