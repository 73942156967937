import React, { PureComponent } from 'react';
import { v4 as uuid } from 'uuid';
import Message from './Message';
import '../styles/Message.scss';

class Notification extends PureComponent {
  state = { messages: [], previousActiveElement: null };

  hideMessage = (messageId) => {
    this.setState(
      ({ messages }) => ({
        messages: messages.map((message) => {
          if (message.id !== messageId) return message;
          if (message.onCancel) {
            message.onCancel(messageId);
          }
          return { ...message, hide: true };
        })
      }),
      () => {
        const { messages } = this.state;
        messages.forEach((message) => {
          const isMessageId = message.id === messageId;
          if (isMessageId && message.overrideDefaultAnimation) {
            this.hideAfterAnimation(messageId);
          } else if (isMessageId) {
            setTimeout(() => this.hideAfterAnimation(messageId), 300);
          }
        });
        const { previousActiveElement } = this.state;
        previousActiveElement?.focus();
      }
    );
  };

  hideAfterAnimation = (messageId) => {
    this.setState(({ messages }) => ({
      messages: messages.filter(({ id }) => id !== messageId)
    }));
  };

  showMessage = (message, override = true) => {
    const id = uuid();
    const { messages } = this.state;
    let prevMessages = messages;
    if (override) {
      prevMessages = [];
      messages.forEach((bar) => {
        this.hideMessage(bar.id);
      });
    }
    this.setState({ messages: [{ ...message, id }, ...prevMessages] });
    const { duration = 3 } = message;

    this.setState({ previousActiveElement: document.activeElement });
    setTimeout(() => document.querySelector('.notification-message__close')?.focus(), 1);

    if (duration !== -1) {
      setTimeout(() => this.hideMessage(id), duration * 1000);
      const { previousActiveElement } = this.state;
      previousActiveElement?.focus();
    }

    return id;
  };

  updateMessageText = (messageId, text) => {
    const { messages } = this.state;
    const message = messages.find((msg) => msg.id === messageId);
    if (!text || !message) {
      return null;
    }

    const updatedMessages = messages.map((msg) => (msg.id === messageId ? { ...msg, label: text } : msg));
    return this.setState({ messages: updatedMessages });
  };

  render() {
    const { messages } = this.state;
    // To keep the last message on top of the page
    return messages
      .slice()
      .reverse()
      .map((item) => <Message {...item} hide={item.hide} onClose={this.hideMessage} key={item.id} {...this.props} />);
  }
}

export default Notification;
