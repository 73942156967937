// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-container {
  background: #f5f5fa;
  border: 1px solid #eeebef;
  border-radius: 4px;
  padding: 10px;
  height: 800px;
  width: 100%;
}
.video-container i {
  font-size: 20px;
  margin: 0 !important;
}
.video-container .participant video {
  max-width: 100%;
}
.video-container .video-phone {
  background: red;
  color: #fff;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 100%;
  border: 0;
  cursor: pointer;
  margin-bottom: 10px;
}
.video-container .video-phone--connect {
  background: green;
}

button#startVideo {
  margin: 0 auto;
}

.video-container--full {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: #000;
  height: 100vh;
}
.video-container--full .local-participant video {
  position: absolute;
  width: 200px;
  bottom: 20px;
  right: 30px;
}
.video-container--full .remote-participants video {
  width: 100%;
  height: 100vh;
  width: 100%;
  height: calc(100vh - 100px);
  margin: 0 auto;
}

button#videolink {
  background: #e79f24;
  color: #fff;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 100%;
  border: 0;
  cursor: pointer;
  margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Appointments/video.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,WAAA;AACF;AAAE;EACE,eAAA;EACA,oBAAA;AAEJ;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,eAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,mBAAA;AAEJ;AADI;EACE,iBAAA;AAGN;;AAEA;EACE,cAAA;AACF;;AACA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,cAAA;EACA,gBAAA;EACA,aAAA;AAEF;AADE;EACE,kBAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;AAGJ;AADE;EACE,WAAA;EACA,aAAA;EACA,WAAA;EACA,2BAAA;EACA,cAAA;AAGJ;;AACA;EACE,mBAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,iBAAA;AAEF","sourcesContent":[".video-container {\n  background: #f5f5fa;\n  border: 1px solid #eeebef;\n  border-radius: 4px;\n  padding: 10px;\n  height: 800px;\n  width: 100%;\n  i {\n    font-size: 20px;\n    margin: 0 !important;\n  }\n  .participant video {\n    max-width: 100%;\n  }\n  .video-phone {\n    background: red;\n    color: #fff;\n    width: 40px;\n    height: 40px;\n    padding: 0;\n    border-radius: 100%;\n    border: 0;\n    cursor: pointer;\n    margin-bottom: 10px;\n    &--connect {\n      background: green;\n    }\n  }\n}\n\nbutton#startVideo {\n  margin: 0 auto;\n}\n.video-container--full {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 99999;\n  background: #000;\n  height: 100vh;\n  .local-participant video {\n    position: absolute;\n    width: 200px;\n    bottom: 20px;\n    right: 30px;\n  }\n  .remote-participants video {\n    width: 100%;\n    height: 100vh;\n    width: 100%;\n    height: calc(100vh - 100px);\n    margin: 0 auto;\n  }\n}\n\nbutton#videolink {\n  background: #e79f24;\n  color: #fff;\n  width: 40px;\n  height: 40px;\n  padding: 0;\n  border-radius: 100%;\n  border: 0;\n  cursor: pointer;\n  margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
