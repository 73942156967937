import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { GoogleLogin } from "react-google-login"
import CarouselPage from "./AuthenticationInner/CarouselPage"
import { google } from "../../config"

// import images
import logodark from "../../assets/images/supaw-vets.svg"
import logolight from "../../assets/images/supaw-vets.svg"

import { useDispatch } from "react-redux"
import ButtonMain from "components/Common/Button"

import landingImage from "../../assets/images/auth/Invoices_landingPage_new.png"

import ReactSelect from "react-select"
import { fetchTimings, updateVet } from "helpers/api_helper"
import { getTimingsByDocId } from "store/slices"
import Stepper from "./AuthenticationInner/Stepper"

const RegisterStep3 = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const [fromTime, setFromTime] = useState({ value: "09:30", label: "09:30" })
  const [toTime, setToTime] = useState({ value: "18:00", label: "18:00" })

  //meta title
  document.title = "Register | Supaw"

  const userId = localStorage.getItem("id")
  // const userId = JSON.parse(id)
  // console.log("Id", userId)
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,

    initialValues: {
      about: "",
    },
    validationSchema: Yup.object({
      about: Yup.string().required(),
    }),
    onSubmit: async values => {
      const timingObject = {
        startTime: fromTime?.value,
        endTime: toTime?.value,
      }
      setIsLoading(true)
      console.log("TimingsObjet", timingObject)
      const values1 = {
        stepThree: true,
        startTime: fromTime?.value,
        endTime: toTime?.value,
        about: values.about,
      }
      await fetchTimings(userId, timingObject)
      const response = await updateVet(userId, values1)
      if (response.status == 1) {
        history.push(
          "/register-step4?successMessage=Registered Successfully. Login to continue"
        )
      }
      setIsLoading(false)
    },
  })

  const generateTimings = () => {
    const timings = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const hourString = hour.toString().padStart(2, "0")
        const minuteString = minute.toString().padStart(2, "0")
        const time = `${hourString}:${minuteString}`
        timings.push({ value: time, label: time })
      }
    }
    return timings
  }
  const timingOptions = generateTimings()
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            

            <Col xl={5}>
              <div className="auth-full-page-content p-md-5 p-4 h-100">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 onboarding-logo-container">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img src={logodark} alt="" className="auth-logo-dark" />
                        <img
                          src={logolight}
                          alt=""
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div style={{ padding: "0px 20%" }}>
                      <div>
                      <Stepper active={3}/>
                        {/* <p className="font-fontFamily fontSize-16 font-color-purple">
                          Step 3
                        </p> */}
                        <h1>Add your clinic timings</h1>
                        <p className="text-muted">
                          Start using askforvets for your vet clinic management.
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <Row>
                            <div className="col-lg-6">
                              <Label
                                htmlFor="projectdesc"
                                className="col-form-label col-lg-5  text-right text-input-label text-input-label--md font-fontFamily"
                              >
                                From
                              </Label>
                              <ReactSelect
                                classNamePrefix="form-react-select"
                                className="form-react-select"
                                options={timingOptions}
                                defaultValue={fromTime} // Set the default value for "From" ReactSelect
                                onChange={setFromTime}
                              />
                            </div>
                            <div className="col-lg-6">
                              <Label
                                htmlFor="projectdesc"
                                className="col-form-label col-lg-5  text-right text-input-label text-input-label--md font-fontFamily"
                              >
                                To
                              </Label>
                              <ReactSelect
                                classNamePrefix="form-react-select"
                                className="form-react-select"
                                options={timingOptions}
                                defaultValue={toTime}
                                onChange={setToTime}
                              />
                            </div>
                            <div className="mt-4 col-lg-12">
                              <h1>Tell us more...</h1>
                              <textarea
                                rows="8"
                                style={{ resize: "none", minHeight: 80 }}
                                name="about"
                                id="ttil1"
                                className={`form-control ${
                                  validation.touched.about &&
                                  validation.errors.about
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter info about you"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.about || ""}
                              />
                              {validation.touched.about &&
                                validation.errors.about && (
                                  <div className="invalid-feedback">
                                    {validation.errors.about}
                                  </div>
                                )}
                            </div>
                          </Row>

                          <div className="mt-4 d-grid col-lg-12">
                            <ButtonMain
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                              isLoading={isLoading}
                            >
                              Next
                            </ButtonMain>
                          </div>
                        </Form>
                        {/* <ButtonMain
                          className="btn btn-primary waves-effect waves-light"
                          type="button"
                          isLoading={isLoading}
                          onClick={() => history.push("/register-step4")}
                        >
                          Next
                        </ButtonMain> */}
                        <Form action="dashboard">
                          <div className="text-center">
                            {/* <hr data-text="OR" /> */}

                            {/* <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="auth-google"
                                  onClick={renderProps.onClick}
                                >
                                  <img src={googleIcon} /> Sign in with Google
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => {}}
                            /> */}
                          </div>
                        </Form>

                        <div className="mt-5 text-center">
                          <p>
                            Already have an account ?{" "}
                            <Link to="login" className="fw-medium text-primary">
                              {" "}
                              Login
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage
              landingPage={landingImage}
              imageTitle="Front desk & finance"
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RegisterStep3
