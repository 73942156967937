// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../fonts/campton/CamptonBook.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Campton";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.font-fontFamily {
  font-family: "Campton";
}

.fontSize-36 {
  font-size: 36px;
}

.fontSize-14 {
  font-size: 14px;
}

.fontSize-16 {
  font-size: 16px;
}

.fontSize-18 {
  font-size: 18px;
}

.fontSize-24 {
  font-size: 24px;
}

.fontSize-28 {
  font-size: 28px;
}

.fontSize-20 {
  font-size: 20px;
}

.margin-top-86 {
  margin-top: 86px;
}

.text-color-primary {
  color: #e75c25;
}

.box-radius-12 {
  border-radius: 12px;
}

.box-radius-16 {
  border-radius: 16px;
}

.font-fontFamily-inter {
  font-family: "Inter";
  font-weight: 500;
}

.font-hover-underline:hover {
  text-decoration: underline;
}

.margin-bottom-39 {
  margin-bottom: 39px;
}

.font-fontFamily-400 {
  font-family: "Campton";
  font-weight: 400;
}

.margin-top-45 {
  margin-top: 45px;
}

.color-black {
  color: black;
}

.font-weight-text {
  font-weight: 500;
}

.font-color-gray {
  color: #8E8E93;
}

.font-color-purple {
  color: #652B88;
}

.font-weight-500 {
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/custom/pages/_dashboard.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,4CAAA;AACF;AAEA;EACE,sBAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAEA;EACE,eAAA;AACF;;AACA;EACE,eAAA;AAEF;;AAAA;EACE,eAAA;AAGF;;AADA;EACE,eAAA;AAIF;;AAFA;EACE,eAAA;AAKF;;AAHA;EACE,eAAA;AAMF;;AAJA;EACE,gBAAA;AAOF;;AALA;EACE,cAAA;AAQF;;AANA;EACE,mBAAA;AASF;;AAPA;EACE,mBAAA;AAUF;;AARA;EACE,oBAAA;EACA,gBAAA;AAWF;;AATA;EACA,0BAAA;AAYA;;AAVA;EACE,mBAAA;AAaF;;AAXA;EACE,sBAAA;EACA,gBAAA;AAcF;;AAZA;EACE,gBAAA;AAeF;;AAbA;EACE,YAAA;AAgBF;;AAdA;EACE,gBAAA;AAiBF;;AAfA;EACE,cAAA;AAkBF;;AAhBA;EACE,cAAA;AAmBF;;AAjBA;EACE,gBAAA;AAoBF","sourcesContent":["@font-face {\n  font-family: \"Campton\";\n  src: url(\"../../../fonts/campton/CamptonBook.otf\");\n}\n\n.font-fontFamily {\n  font-family: \"Campton\";\n  // font-weight: 500;\n}\n.fontSize-36 {\n  font-size: 36px;\n}\n.fontSize-14 {\n  font-size: 14px;\n}\n.fontSize-16 {\n  font-size: 16px;\n}\n.fontSize-18 {\n  font-size: 18px;\n}\n.fontSize-24 {\n  font-size: 24px;\n}\n.fontSize-28 {\n  font-size: 28px;\n}\n.fontSize-20 {\n  font-size: 20px;\n}\n.margin-top-86 {\n  margin-top: 86px;\n}\n.text-color-primary {\n  color: #e75c25;\n}\n.box-radius-12 {\n  border-radius: 12px;\n}\n.box-radius-16 {\n  border-radius: 16px;\n}\n.font-fontFamily-inter {\n  font-family: \"Inter\";\n  font-weight: 500;\n}\n.font-hover-underline:hover{\ntext-decoration: underline;\n}\n.margin-bottom-39{\n  margin-bottom: 39px;\n}\n.font-fontFamily-400 {\n  font-family: \"Campton\";\n  font-weight: 400;\n}\n.margin-top-45 {\n  margin-top: 45px;\n}\n.color-black{\n  color:black\n}\n.font-weight-text{\n  font-weight: 500;\n}\n.font-color-gray{\n  color:#8E8E93\n}\n.font-color-purple{\n  color:#652B88\n}\n.font-weight-500{\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
