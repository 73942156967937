import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { CardTitle, Col, Row } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { IconSVG } from "components/Common/IconSvg"

const data = [
  {
    itemName: "Thermoxil D500 - Tablets",
    quantity: 100,
    amount: 5000,
  },
  {
    itemName: "Thermoxil D500 - Tablets",
    quantity: 100,
    amount: 5000,
  },
  {
    itemName: "Thermoxil D500 - Tablets",
    quantity: 100,
    amount: 5000,
  },
  {
    itemName: "Thermoxil D500 - Tablets",
    quantity: 100,
    amount: 5000,
  },
]
const PurchaseOrderDetailsCol = ({ testDetails }) => {
  console.log("testDetails", testDetails)
  return (
    <ul className="list-unstyled">
      <Row>
        <Col>Items</Col>
        <Col></Col>
        <Col>Quantity</Col>
        <Col>Amount</Col>
      </Row>
      {data.map((ele,index)=>{
        return(
        <Row key={index}>
            <Col><h3>{ele.itemName}</h3></Col>
            <Col></Col>
            <Col><h3>{ele.quantity}Strips</h3></Col>
            <Col><h3>INR {ele.amount}</h3></Col>
        </Row>)
      })}
      
      <Row>
        <Col></Col>
        <Col></Col>
        <Col>Total</Col>
        <Col><h1>INR 500</h1></Col>
      </Row>
    </ul>
  )
}

PurchaseOrderDetailsCol.propTypes = {
  details: PropTypes.any,
}

export default PurchaseOrderDetailsCol
