import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { CardTitle, Col, Row } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { IconSVG } from "components/Common/IconSvg"

const TestDetailsCol = ({ testDetails }) => {
  console.log("testDetails", testDetails)
  return (
    <ul className="list-unstyled parent-details__container">
      <div className="d-flex justify-content-between">
        <CardTitle className="mb-4 font-fontFamily">
          <h3>Item Details</h3>
        </CardTitle>
        <div className="history-doc-cta">
          <Link
            to={`/edit-inventory-items-recieved/${testDetails._id}`}
            className="font-fontFamily"
          >
            Edit <IconSVG icon="edit" />
          </Link>
        </div>
      </div>
      <Row>
        <Col sm="3">
          <li>
            <h6 className="parent-details__title font-fontFamily">Item Name</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.itemName}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">ItemCode</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.itemCode}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">ItemCode</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.type}
            </p>
          </li>
          <li>
            <h6 className="parent-details__title font-fontFamily">
              Invoice Number
            </h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.purchaseInformation?.invoiceNumber}
            </p>
          </li>
        </Col>
        <Col sm="3">
          <li>
            <h6 className="parent-details__title font-fontFamily">
              Re-Order Level
            </h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.reOrderLevel}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">
              Manufacturer
            </h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.purchaseInformation?.manufacturerDetails}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">
              Retail Price
            </h6>
            <p className="parent-details__value font-fontFamily">
              Rs{testDetails?.retailPrice}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">
              Purchase Cost
            </h6>
            <p className="parent-details__value font-fontFamily">
              Rs{testDetails?.purchaseInformation?.purchaseCost}
            </p>
          </li>
        </Col>
        <Col sm="3">
          <li>
            <h6 className="parent-details__title font-fontFamily">
              Stocking Unit
            </h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.stockingUnit}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">Item Type</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.itemType}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">Tax</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.tax}%
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">
              Expiration Date
            </h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.purchaseInformation?.expirationDate === ""
                ? "NA"
                : testDetails?.purchaseInformation?.expirationDate}
            </p>
          </li>
        </Col>
        <Col sm="3">
          <li>
            <h6 className="parent-details__title font-fontFamily">Quantity</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.quantity}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">Strength</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.Strength}mg
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">
              Purchase Date
            </h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.purchaseInformation?.date}
            </p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">Aisle</h6>
            <p className="parent-details__value font-fontFamily">
              {testDetails?.aisleLocationTitle}
            </p>
          </li>
        </Col>
       
      </Row>
    </ul>
  )
}

TestDetailsCol.propTypes = {
  details: PropTypes.any,
}

export default TestDetailsCol
