import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import { filter, isEmpty } from "lodash"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  OrderId,
  BillingName,
  Total,
  Parent,
} from "../Pets/components/PatientsCol"

//redux
import { useSelector, useDispatch } from "react-redux"
import ReactSelect from "react-select"
import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  NavLink,
  NavItem,
  Nav,
} from "reactstrap"
import { deletePet, deletePricing } from "helpers/api_helper"
import {
  getCategoryOfPricing,
  getPricingCategory,
  getPricingDetails,
  getUserDetails,
  getpricing,
  setGlobalLoading,
} from "../../store/slices"
import { IconSVG } from "components/Common/IconSvg"

import { showMessage } from "components/Notification"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
function PurchaseOrder() {
  //meta title
  document.title = "Purchase Order || Supaw"

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [pricingCategory, setPricingCategory] = useState([])
  const [selectedPatients, setSelectedGeneralPatients] = useState(null)

  const history = useHistory()

  const toggleViewModal = () => setModal1(!modal1)

  const dispatch = useDispatch()

  // const generalPatients = useSelector(state => state.Patients.generalPatients)
  const userDetails = useSelector(getUserDetails)
  console.log("userDetails", userDetails)

  const pricingArr = useSelector(getpricing) || []
  console.log("pricingArr", pricingArr)

  
 

  
  

  const toggle = () => {
    if (modal) {
      setModal(false)
      setOrder(null)
    } else {
      setModal(true)
    }
  }

  const LabTests = cell => {
    return (
      <Link to={`/pricingItem/${cell.row?.original?._id}`}>
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  //delete order
  // const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = patient => {
    setSelectedGeneralPatients(patient)
    setDeleteModal(true)
    toggle
  }

  const handleDeleteOrder = async () => {
    console.log("Id", selectedPatients._id)
    // if (order._id) {
    const res = await deletePricing(selectedPatients._id)
    if (res.status == 1) {
      dispatch(getPricingDetails({ vetId: userDetails?._id }))
    }
    setDeleteModal(false)
  }

  const handleOrderClicks = () => {
    history.push("/add-pet")
  }

  // useEffect(() => {
  //   async function fetchData() {
  //     dispatch(getGeneralPatientsList())
  //   }
  //   fetchData()
  // }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: "Order ID",
        accessor: "invoiceNo",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <LabTests {...cellProps} />
        },
      },
      {
        Header: "Item Name",
        accessor: "billDate",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <LabTests {...cellProps} />
        },
      },
      {
        Header: "Vendor",
        accessor: "status",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <LabTests {...cellProps} />
        },
      },
      {
        Header: "Date",
        accessor: "patient",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <LabTests {...cellProps} />
        },
      },
      {
        Header: "price",
        accessor: "total",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <LabTests {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "dueBalance",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <LabTests {...cellProps} />
        },
      },
      {
        Header: "Actions",
        accessor: "action",
        disableFilters: true,
        className: "text-right",
        style: {
          textAlign: "right",
          background: "#0000",
        },
        Cell: cellProps => {
          return (
            <div
              className="d-flex gap-3 justify-content-end
            "
            >
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  history.push(`/edit-pricing/${cellProps.row.original._id}`)
                }}
              >
                <i id="edittooltip">
                  <IconSVG icon="edit" />
                </i>
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i id="deletetooltip">
                  <IconSVG icon="delete" />
                </i>

                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  // const filterData = categories.map(ele => ele.pricingCategoryName)

 

  
  const [activeTab, setActiveTab] = useState(0)
  const [activeTabName,setActiveTabName] = useState()



  useEffect(()=>{
setActiveTabName(pricingCategory[0])
  },[pricingCategory.length])
console.log("ActiveTabName",activeTabName)
  const handleTabClick = tabId => {
    setActiveTab(tabId)
    setActiveTabName(invoiceItems[tabId])
  }

  //   const activeState={
  // color:"#652B88",
  // borderBottom:"1px solid #652B88"
  //   }

const invoiceItems=["All Orders","Accepted", "Rejected","Waiting"]
const invoiceData=[];
//handle empty categories for add new item
 

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <div className="appointment-heading-div ">
          <div className="d-sm-flex align-items-end">
              <img
                src={LeftArrow}
                width={25}
                onClick={() => history.goBack()}
                className="mt-2"
                role="button"
              /><h4 className="mb-sm-0 font-size-18">Purchase Order</h4></div>
            
            <div className="create-link">
              <Link
                color="primary"
                className="font-16 btn-block"
                to={"/add-purchase-order"}
               
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                 Add New PO
              </Link>
            </div>
          </div>

          <Row>
            <Nav tabs>
              {invoiceItems.map((pVal, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={activeTab === index ? "active" : ""}
                    onClick={() => handleTabClick(index)}
                    style={{
                      color: activeTab === index ? "#652B88" : "#6B7280",
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: "none",
                      borderBottom:
                        activeTab === index ? "2px solid #652B88" : "",
                      fontSize: "18px",
                      paddingBottom: "7px",
                    }}
                  >
                    {pVal}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={invoiceData || []}
                    isGlobalFilter={false}
                    // isAddOptions={true}
                    // handleOrderClicks={handleOrderClicks}
                    emptyListLink="/add-new-invoice"
                    emptyText="No invoice found"
                    emptyLinkText="+ Add New Items"
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PurchaseOrder
