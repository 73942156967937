import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter,useHistory } from "react-router-dom"

//redux
import { useDispatch } from "react-redux"

const Logout = props => {
  const dispatch = useDispatch()
const history =useHistory()
  useEffect(() => {
    localStorage.removeItem("authUser")
    history.push("/login")
    // dispatch(logoutUser(props.history))
  }, [dispatch])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
