import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { Col, FormGroup, Input, Label, Row } from "reactstrap"
import {
  getAllParents,
  getAppointmentsByDocId,
  getAppointmentsOfDoc,
  getParentList,
  getUserDetails,
} from "store/slices"

export default function SearchBarComponent() {
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredItems, setFilteredItems] = useState([])
  const [filteredAppointments, setFilteredAppointments] = useState([])
  const [showAllPatients, setShowAllPatients] = useState(false)
  const [showAllAppointments, setShowAllAppointments] = useState(false)
  const dispatch = useDispatch()
  const userDetails = useSelector(getUserDetails)
  const parents = useSelector(getAllParents)
  const appointments = useSelector(getAppointmentsOfDoc) || []
  useEffect(() => {
    const fetchData = async () => {
      const list = await dispatch(getParentList({ doctorId: userDetails?._id }))
      await dispatch(getAppointmentsByDocId(userDetails?._id))
    }
    fetchData()
  }, [dispatch])
  console.log("parentsList", parents)
  console.log("appointmentsList", appointments)
  useEffect(() => {
    const filtered = parents?.filter(item => {
      const fullNameMatch = item?.fullName
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
      const phoneMatch =
        typeof item?.phone === "number" &&
        item?.phone.toString().includes(searchTerm)
      return fullNameMatch || phoneMatch
    })
    setFilteredItems(filtered)

    const filteredAppointments = appointments?.filter(item => {
      const fullNameMatch = item?.parentId.fullName
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
      //xpecto Developer 23/7/2024
      const petfullNameMatch = item?.petObjectId.fullName
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
      console.log("petfullNameMatch????", petfullNameMatch)

      const petId = item?.petObjectId.petId
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
      console.log("petId????", petId)

      const phoneMatch =
        typeof item?.phone === "number" &&
        item?.phone.toString().includes(searchTerm)

      return fullNameMatch || phoneMatch || petfullNameMatch || petId

      //xpecto Developer 23/7/2024
    })
    setFilteredAppointments(filteredAppointments)
  }, [searchTerm])
  console.log("FilteredItems", filteredItems)
  console.log("FilteredAppointments", filteredAppointments)
  return (
    <div>
      <FormGroup className="mb-4" row>
        <div>
          <Input
            id="itemName"
            name="itemName"
            type="text"
            className="form-control"
            placeholder="Search by Pet Parent / Pet Name / Mobile Number / Pet ID"
            value={searchTerm}
            style={{
              width: "444px",
              border: "none",
              borderBottom: "1px solid #E8E8E8",
              borderRadius: "0",
              paddingLeft: "0",
              paddingBottom: "0",
            }}
            onChange={e => setSearchTerm(e.target.value)}
            autoComplete="off"
          />
        </div>
      </FormGroup>
      {searchTerm && (
        <div style={{ position: "relative", marginTop: "10px" }}>
          <div
            style={{
              position: "absolute",
              top: 80,
              right: 20,
              width: "444px",
              height: "334px",
              background: "#FFFFFF",
              zIndex: "2",
              boxShadow: "0px 18px 30px 16px #00000008",
              borderRadius: "12px",
              overflowY: showAllAppointments ? "scroll" : "hidden",
            }}
          >
            <div>
              {filteredItems.length > 0 ? (
                <>
                  {showAllPatients ? (
                    <>
                      {filteredItems.map((ele, index) => (
                        <div
                          key={index}
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginTop: "30px",
                            marginLeft: "27px",
                          }}
                        >
                          <Link
                            to={`/parent/${ele._id}`}
                            style={{ color: "black" }}
                          >
                            {ele.fullName}
                          </Link>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {filteredItems.slice(0, 3).map((ele, index) => (
                        <div
                          key={index}
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginTop: "30px",
                            marginLeft: "27px",
                          }}
                        >
                          <Link
                            to={`/parent/${ele._id}`}
                            style={{ color: "black" }}
                          >
                            {ele.fullName}
                          </Link>
                        </div>
                      ))}
                      {filteredItems.length > 3 && (
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginTop: "30px",
                            marginLeft: "27px",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowAllPatients(true)}
                        >
                          <i className="fa fa-search me-1" />
                          See All Patients
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginTop: "30px",
                    marginLeft: "27px",
                    textAlign: "center",
                  }}
                >
                  No items found
                </div>
              )}
              <hr />
              {filteredAppointments.length > 0 ? (
                <>
                  {showAllAppointments ? (
                    <>
                      {filteredAppointments.map((ele, index) => (
                        <div
                          key={index}
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginTop: "30px",
                            marginLeft: "27px",
                          }}
                        >
                          <Link
                            to={`/appointment/${ele._id}`}
                            style={{ color: "black" }}
                          >
                            {ele.parentId.fullName} · {ele.petType} · {ele.time}
                          </Link>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {filteredAppointments.slice(0, 3).map((ele, index) => (
                        <div
                          key={index}
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginTop: "30px",
                            marginLeft: "27px",
                          }}
                        >
                          <Link
                            to={`/appointment/${ele._id}`}
                            style={{ color: "black" }}
                          >
                            {ele.parentId.fullName} · {ele.petType} · {ele.time}
                          </Link>
                        </div>
                      ))}
                      {filteredAppointments.length > 3 && (
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginTop: "30px",
                            marginLeft: "27px",
                            marginBottom: "30px",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowAllAppointments(true)}
                        >
                          <i className="fa fa-search me-1" />
                          See All Appointments
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginTop: "30px",
                    marginLeft: "27px",
                    textAlign: "center",
                  }}
                >
                  No appointments found
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
