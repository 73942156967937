import React, { useState } from "react"
// import './App.css';
import VideoChat from "./VideoChat"

const App = () => {
  const [userName, setUserName] = useState("")
  return (
    <React.Fragment>
      <div className="video-container video-container--full">
        <VideoChat
          userName={userName}
          onUserName={setUserName}
          // onEnd={handleOnEnd}
          isFromSeperateWindow
        />
      </div>
    </React.Fragment>
  )
}

export default App
