import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, Alert, CardBody, Form } from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

import { useHistory, withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar_placeholder.png"
// actions
import {
  BASE,
  updateDayAvailabilityByDayIdAndDocId,
  updateStartAndEndTimeByDayIdAndDocId,
  updateStartAndEndTimeByDayIdAndDocIdForParticularDay,
} from "helpers/api_helper"
import {
  getDetails,
  getTimingDetails,
  getTimingsByDocId,
  getUserDetails,
  getVetCurrentTimings,
} from "store/slices"
import DaySchedule from "./components/DaySchedule"

import { showMessage } from "components/Notification"
import Loading from "components/Common/Loading"
import LeftArrow from "../../assets/icons/ArrowLeft.png"

const UserProfile = () => {
  //meta title
  document.title = "Timing | Supaw"
  const userDetails = useSelector(getUserDetails)

  const timingDetails = useSelector(getTimingDetails)

  const [nextDays, setNextDays] = useState(timingDetails.timings)

  const dispatch = useDispatch()

  const [isUpdatingButton, setIsUpdatingButton] = useState(false)

  const [isFetchingData, setFetchingData] = useState(true)

  const [, setImageUrl] = useState(userDetails?.images[0])
  const [idx, setidx] = useState(userDetails?._id)

  const [hasErrors, setHasErrors] = useState({})
  const history = useHistory()
  const timingObj = {
    startTime: userDetails?.startTime,
    endTime: userDetails?.endTime,
  }
  console.log("TimingObject", timingObj)
  useEffect(() => {
    const newLoad = async () => {
      if (localStorage.getItem("authUser")) {
        setFetchingData(true)
        await dispatch(getDetails())
        setImageUrl(userDetails.images[0])
        const res = await dispatch(getVetCurrentTimings(idx))
        // console.log("res",res)
        setNextDays(res?.timings)
        setFetchingData(false)
      }
    }
    newLoad()
  }, [dispatch])
  console.log("Next Days", nextDays)
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      timings: [],
    },

    validationSchema: Yup.object({}),
    onSubmit: async values => {},
  })

  const handleError = (curr, obj) => {
    const _h = hasErrors
    _h[curr.value] = obj[curr.value]
    setHasErrors(_h)
  }
  const onDayChange = async (id, isChecked) => {
    try {
      const res = await updateDayAvailabilityByDayIdAndDocId({
        doctorId: idx,
        dayId: id,
        isAvailable: !!isChecked,
      })
      if (res.status === 1) {
        setNextDays(res.data.timings)
      }
    } catch (error) {
      showMessage({
        label:
          error?.message ||
          "Something went wrong while changing day availability",
        status: "error",
      })
    }
  }
  const onTimeChange = async obj => {
    try {
      setIsUpdatingButton(true)
      const res = await updateStartAndEndTimeByDayIdAndDocId({
        doctorId: idx,
        dayId: obj._id,
        startTime: obj.startTime,
        endTime: obj.endTime,
        slots: obj.slots,
      })
      if (res.status === 1) {
        setNextDays(res.data.timings)
        setIsUpdatingButton(false)
      }
    } catch (error) {
      setIsUpdatingButton(false)
      showMessage({
        label:
          error?.message ||
          "Something went wrong while changing time availability",
        status: "error",
      })
    }
  }

  const onDayTimeChange = async obj => {
    try {
      setIsUpdatingButton(true)
      const res = await updateStartAndEndTimeByDayIdAndDocIdForParticularDay({
        doctorId: idx,
        dayId: obj._id,
        startTime: obj.startTime,
        endTime: obj.endTime,
      })
      if (res.status === 1) {
        showMessage({
          label: res.message,
          status: "success",
        })
        setIsUpdatingButton(false)
      }
    } catch (error) {
      setIsUpdatingButton(false)
      showMessage({
        label:
          error?.message ||
          "Something went wrong while changing time availability",
        status: "error",
      })
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="mb-4 ">
            <Col className="d-flex align-items-center justify-content-start gap-2">
              <img
                src={LeftArrow}
                width={36}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-36">
                Operating Hours
              </h4>
            </Col>

            <Col className="d-flex align-items-center justify-content-between gap-4"></Col>
            {/* <div className="create-link">
              <Link
                color="primary"
                className="font-16 btn-block"
                to={"/add-appointment"}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New Appointment
              </Link>
            </div> */}
          </Row>
          {/* <Breadcrumb title="Supaw" breadcrumbItem="Timings" /> */}
          <Row>
            <label
              className="text-muted font-fontFamily fontSize-16 "
              style={{ margin: "0px 48px" }}
            >
              Update your timings
            </label>

            <Card>
              <CardBody>
                <hr />

                {!isFetchingData ? (
                  <Form
                    className="form-horizontal row"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Col lg={8}>
                      {nextDays?.length
                        ? nextDays.map((el, index) => {
                            console.log("value", el)
                            return (
                              <DaySchedule
                                isCurrentDay={index === 0}
                                key={el._id}
                                value={el}
                                difference={30}
                                isUpdatingButton={isUpdatingButton}
                                setError={obj => handleError(el, obj)}
                                onTimeChange={onTimeChange}
                                onDayTimeChange={onDayTimeChange}
                                onDayChange={isChecked =>
                                  onDayChange(el._id, isChecked)
                                }
                              />
                            )
                          })
                        : "No Timings found"}
                    </Col>
                  </Form>
                ) : (
                  <Loading />
                )}
              </CardBody>
            </Card>
            {/* </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
