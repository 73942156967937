import classnames from "classnames"
import ButtonMain from "components/Common/Button"
import { IconSVG } from "components/Common/IconSvg"
import {
  BASE,
  fetchAppointmentsListBasedOnPetId,
  getImagingAndLabsBasedOnPetId,
} from "helpers/api_helper"
import { getDateDDMMYYYY } from "helpers/utils"
import React, { useEffect, useState } from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"
import { Link } from "react-router-dom"
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap"
import DocViewer, {
  PDFRenderer,
  PNGRenderer,
  JPGRenderer,
  MSDocRenderer,
} from "react-doc-viewer"
import ReactSelect from "react-select"
const ImagingRecords = ({ petDetails, parentPets, testType }) => {
  const [currentPetDetails, setCurrentPetDetails] = useState([])
  const [photoIndex, setphotoIndex] = useState(0)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)
  const [scansOrReports, setScansOrReports] = useState([])
  const [currentScans, setCurrentScans] = useState([])
  const [currentAppointmentType, setCurrentAppointmentType] = useState({
    label: "All",
    value: "All",
  })
  const NoMedicalHistoryAvailable = () => {
    return (
      <h6 className="no-data font-fontFamily" style={{ background: "white" }}>
        No tests available
      </h6>
    )
  }
  const MAP = {
    labs: "Lab",
    imaging: "Imaging",
  }
  useEffect(() => {
    async function fetchData() {
      if (petDetails._id) {
        const firstPetDetails = await getImagingAndLabsBasedOnPetId({
          petObjectId: activeTabName?._id,
        })
        if (firstPetDetails.status === 1) {
          setCurrentPetDetails(
            firstPetDetails.data.filter(el => el.testType === testType)
          )
        }
      }
    }
    fetchData()
  }, [])

  const pricingCategory = parentPets.filter(ele => ele.colour)
  const [activeTab, setActiveTab] = useState(0)
  const [activeTabName, setActiveTabName] = useState(pricingCategory[activeTab])

  const appointmentTypes = [
    { label: "All", value: "All" },
    { label: "Primary Care", value: "Primary Care" },
    { label: "Virtual Care", value: "Virtual Care" },
    { label: "Urgent Care", value: "Urgent Care" },
  ]
  const handleTabClick = tabId => {
    setActiveTab(tabId)
    setActiveTabName(pricingCategory[tabId])
  }

  useEffect(() => {
    async function fetchData() {
      if (petDetails._id) {
        const firstPetDetails = await getImagingAndLabsBasedOnPetId({
          petObjectId: activeTabName?._id,
        })
        if (firstPetDetails.status === 1) {
          setCurrentPetDetails(
            firstPetDetails.data.filter(el => el.testType === testType)
          )
        }
      }
    }
    fetchData()
  }, [activeTabName])

  const filterVal = currentPetDetails?.filter(
    obj => obj.visitType === currentAppointmentType?.value
  )
  console.log("FilterVal", filterVal)
  console.log("AppointmentId", currentPetDetails)
  return (
    <React.Fragment>
      <div className="history-doc-cta mb-4 d-flex align-items-center justify-content-between mx-2">
        <div>
          <h3>Appointment history</h3>
        </div>
        <div className="d-flex gap-2">
          {/* <ReactSelect
            className="font-fontFamily fontSize-18 font-weight-500"
            styles={{
              control: provided => ({
                ...provided,
                width: "210px",
                background: "#FFFFFF",
                color: "#2C2C2E",
              }),
            }}
            value={currentAppointmentType}
            onChange={setCurrentAppointmentType}
            options={appointmentTypes}
          ></ReactSelect> */}
          <Link
            to={`/add-appointment/pet/${activeTabName._id}`}
            className="font-fontFamily fontSize-18 font-weight-500"
            style={{
              background: "#652B88",
              color: "white",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
            }}
          >
            + Add Appointment
          </Link>
        </div>
      </div>
      <Row className="mx-2 my-4">
        <Nav tabs>
          {pricingCategory.map((pVal, index) => (
            <NavItem key={index}>
              <NavLink
                className={activeTab === index ? "active" : ""}
                onClick={() => handleTabClick(index)}
                style={{
                  color: activeTab === index ? "#652B88" : "#6B7280",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: activeTab === index ? "2px solid #652B88" : "",
                  fontSize: "18px",
                  paddingBottom: "7px",
                }}
              >
                <p className="font-fontFamily">{pVal.fullName}</p>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Row>
      {currentAppointmentType?.value === "All" ? (
        <ul className="verti-timeline list-unstyled">
          {currentPetDetails?.length ? (
            currentPetDetails.map((item, key) => {
              return (
                <li key={key} className="event-list">
                  <div className="event-timeline-dot" />
                  <div className="d-flex">
                    <div className="history-list-item">
                      <div className="history-appointment-details">
                        <div className="history-appointment-type font-fontFamily">
                          {MAP[item.testType]}
                          <span className="history-appointment-type font-fontFamily">
                            {getDateDDMMYYYY(item.createdAt)}
                          </span>
                        </div>
                        <div className="history-appointment-reason font-fontFamily">
                          Notes: {item.notes}
                        </div>
                      </div>
                      <div className="history-appointment-notes font-fontFamily">
                        {item.type}
                      </div>

                      {item.radiologist && (
                        <div className="history-appointment-notes font-fontFamily">
                          Radiologist: {item.radiologist}
                        </div>
                      )}

                      <div className="history-appointment-otherdetails">
                        {item.doctorId && (
                          <div className="history-doc-details">
                            {item.doctorId.images && item.doctorId.images[0] ? (
                              <img
                                src={item.doctorId.images[0]}
                                alt={`${item.doctorId.fullName}'s image`}
                              />
                            ) : (
                              <p className="font-fontFamily">
                                Image not available
                              </p> // Optionally, display a default message or image here
                            )}
                            <p className="font-fontFamily">
                              {item.doctorId.fullName}
                            </p>
                          </div>
                        )}

                        <div className="history-doc-cta gap-2">
                          {item.appointmentId !== null ? (
                            <div className="history-doc-cta font-fontFamily font-weight-text">
                              <Link
                                to={`/prescription-details/${item.appointmentId}`}
                              >
                                View Prescription
                              </Link>
                            </div>
                          ) : (
                            ""
                          )}

                          {item?.testType === "imaging" &&
                          item.scansOrReports?.length > 0 ? (
                            <button
                              className="font-fontFamily font-weight-text"
                              onClick={() => {
                                if (item.testType === "imaging") {
                                  setScansOrReports(item.scansOrReports)
                                  setisGalleryZoom(true)
                                  setphotoIndex(0)
                                } else {
                                  const arr = item.scansOrReports?.map(el => {
                                    return { uri: el }
                                  })
                                  setCurrentScans(arr)
                                }
                              }}
                            >
                              {" "}
                              {item.testType !== "labs"
                                ? "View Scans"
                                : "View Reports"}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })
          ) : (
            <NoMedicalHistoryAvailable />
          )}
        </ul>
      ) : (
        <ul className="verti-timeline list-unstyled">
          {filterVal?.length ? (
            filterVal.map((item, key) => {
              return (
                <li key={key} className="event-list">
                  <div className="event-timeline-dot" />
                  <div className="d-flex">
                    <div className="history-list-item">
                      <div className="history-appointment-details">
                        <div className="history-appointment-type font-fontFamily">
                          {MAP[item.testType]}
                          <span className="history-appointment-type font-fontFamily">
                            {getDateDDMMYYYY(item.createdAt)}
                          </span>
                        </div>
                        <div className="history-appointment-reason font-fontFamily">
                          Notes: {item.notes}
                        </div>
                      </div>
                      <div className="history-appointment-notes font-fontFamily">
                        {item.type}
                      </div>

                      {item.radiologist && (
                        <div className="history-appointment-notes font-fontFamily">
                          Radiologist: {item.radiologist}
                        </div>
                      )}

                      <div className="history-appointment-otherdetails">
                        {item.doctorId && (
                          <div className="history-doc-details">
                            <img src={item.doctorId.images[0]} />
                            <p className="font-fontFamily">
                              {item.doctorId.fullName}
                            </p>
                          </div>
                        )}
                        <div className="history-doc-cta">
                          <button
                            className="font-fontFamily font-weight-text"
                            onClick={() => {
                              if (item.testType === "imaging") {
                                setScansOrReports(item.scansOrReports)
                                setisGalleryZoom(true)
                                setphotoIndex(0)
                              } else {
                                const arr = item.scansOrReports?.map(el => {
                                  return { uri: el }
                                })
                                setCurrentScans(arr)
                              }
                            }}
                          >
                            {" "}
                            {item.testType !== "labs"
                              ? "View Scans"
                              : "View Reports"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })
          ) : (
            <NoMedicalHistoryAvailable />
          )}
        </ul>
      )}
      {/* {currentScans?.length > 0 && (
        <Modal
          isOpen={currentScans?.length}
          toggle={() => setCurrentScans(prevState => !prevState)}
          size="lg"
        >
          <ModalBody className="py-3 px-5">
            <DocViewer
              pluginRenderers={[
                PDFRenderer,
                PNGRenderer,
                JPGRenderer,
                MSDocRenderer,
              ]}
              documents={currentScans}
            />
          </ModalBody>
        </Modal>
      )} */}
      {isGalleryZoom ? (
        <Lightbox
          mainSrc={scansOrReports[photoIndex]}
          nextSrc={scansOrReports[(photoIndex + 1) % scansOrReports.length]}
          prevSrc={
            scansOrReports[
              (photoIndex + scansOrReports.length - 1) % scansOrReports.length
            ]
          }
          onCloseRequest={() => {
            setisGalleryZoom(false)
          }}
          onMovePrevRequest={() => {
            setphotoIndex(
              (photoIndex + scansOrReports.length - 1) % scansOrReports.length
            )
          }}
          onMoveNextRequest={() => {
            setphotoIndex((photoIndex + 1) % scansOrReports.length)
          }}
        />
      ) : null}
    </React.Fragment>
  )
}

export default ImagingRecords
