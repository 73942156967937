import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Col, Container, Form, FormFeedback, Input, Row } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { GoogleLogin } from "react-google-login"
import CarouselPage from "./AuthenticationInner/CarouselPage"
import { google } from "../../config"
import logodark from "../../assets/images/supaw-vets.svg"
import logolight from "../../assets/images/supaw-vets.svg"
import googleIcon from "../../assets/images/auth/google.png"
import { useDispatch } from "react-redux"
import ButtonMain from "components/Common/Button"
import { registerVet } from "helpers/api_helper"
import landingImage from "../../assets/images/auth/Step1_landingPage2.png"
import { showMessage } from "components/Notification"
import Stepper from "./AuthenticationInner/Stepper"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import zxcvbn from "zxcvbn"
import "./register.css"

const generateStrongPassword = () => {
  const length = 12 // Ensure this is at least 8
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-="
  let password = ""
  for (let i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n))
  }
  return password
}

const Register2 = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [passwordVisible1, setPasswordVisible1] = useState(false)
  const [passwordStrength, setPasswordStrength] = useState("")

  console.log("passwordStrength????", passwordStrength)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: "",
      email: "",
      password: "",
      role: "vet",
      gender: "",
      confirmPassword: "",
      specialization: "",
      images: [],
      stepOne: true,
      latitude: 0,
      longitude: 0,
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Please Enter Your Fullname"),
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .max(16, "Password cannot be more than 16 characters long")
        .required("Please Enter Your Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please Enter Your Confirm password"),
    }),
    onSubmit: async values => {
      try {
        setIsLoading(true)
        const response = await registerVet(values)
        if (response.status === 1) {
          localStorage.setItem("id", response.data._id)
          localStorage.setItem("token", response.data.token)
          history.push("/register-step2")
        }
      } catch (error) {
        let message = error?.response?.data?.message
        if (message && typeof message !== "string") {
          message = "something went wrong!"
        }
        setError(message)
        setIsLoading(false)
        showMessage({
          label: message,
          status: "warning",
        })
      }
    },
  })

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      localStorage.setItem("authUser", JSON.stringify(postData))
      history.push("/dashboard")
    }
  }

  const googleResponse = response => {
    signIn(response, "google")
  }
  //xpecto developer
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(!passwordVisible1)
  }

  // const handlePasswordChange = e => {
  //   const password = e.target.value
  //   validation.handleChange(e)
  //   const result = zxcvbn(password)
  //   setPasswordStrength(result.score)
  // }
  const calculatePasswordStrength = password => {
    let strength = 0

    if (password.length >= 8) strength += 1 // Length
    if (password.match(/[a-z]/)) strength += 1 // Lowercase
    if (password.match(/[A-Z]/)) strength += 1 // Uppercase
    if (password.match(/[0-9]/)) strength += 1 // Numbers
    if (password.match(/[^a-zA-Z0-9]/)) strength += 1 // Special characters

    return strength // Value from 0 to 5
  }

  const handlePasswordChange = e => {
    const password = e.target.value
    // validation.setFieldValue("password", password)

    // Calculate and set password strength
    // const strength = calculatePasswordStrength(password)
    // setPasswordStrength(strength)
    if (password.length <= 16) {
      validation.setFieldValue("password", password)

      // Calculate and set password strength
      const strength = calculatePasswordStrength(password)
      setPasswordStrength(strength)
    }
  }

  const strengthLabels = ["Weak", "Fair", "Good", "Strong", "Very Strong"]
  const strengthColors = ["#f46a6a", "#564ab1", "#E75C25", "#8BC34A", "#008000"]

  const handleGeneratePassword = () => {
    const newPassword = generateStrongPassword()
    validation.setFieldValue("password", newPassword)
    validation.setFieldValue("confirmPassword", newPassword)
    const result = zxcvbn(newPassword)
    setPasswordStrength(result.score)
  }
  //xpecto developer
  const getStrengthLabel = score => {
    switch (score) {
      case 0:
        return "Very Weak"
      case 1:
        return "Weak"
      case 2:
        return "Moderate"
      case 3:
        return "Strong"
      case 4:
        return "Very Strong"
      default:
        return ""
    }
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={5}>
              <div className="auth-full-page-content p-md-5 p-4 vh-100">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 onboarding-logo-container">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt="Logo Dark"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt="Logo Light"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div style={{ padding: "0px 20%" }}>
                      <div>
                        <Stepper active={1} />
                        <h1>Add your Details</h1>
                        <p className="text-muted">
                          Start using askforvets for your vet clinic management.
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Your Email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Input
                              name="fullName"
                              type="text"
                              placeholder="Your Full Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.fullName || ""}
                              invalid={
                                validation.touched.fullName &&
                                validation.errors.fullName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.fullName &&
                            validation.errors.fullName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.fullName}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3 position-relative">
                            <Input
                              name="password"
                              type={passwordVisible ? "text" : "password"}
                              placeholder="Password"
                              onChange={handlePasswordChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                              }
                              className="password-input"
                            />
                            <span
                              aria-label={
                                passwordVisible
                                  ? "Hide password"
                                  : "Show password"
                              }
                              className="password-visibility-toggle"
                              onClick={togglePasswordVisibility}
                            >
                              {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                            </span>
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}

                            {/* Password strength label */}
                            {validation.values.password.length > 0 && (
                              <div className="password-strength-meter">
                                <div
                                  className="strength-line"
                                  style={{
                                    backgroundColor:
                                      strengthColors[passwordStrength],
                                    width: `${(passwordStrength + 1) * 20}%`,
                                    height: "4px",
                                    marginBottom: "5px",
                                  }}
                                ></div>
                                <span
                                  style={{
                                    color: strengthColors[passwordStrength],
                                  }}
                                >
                                  {strengthLabels[passwordStrength]}
                                </span>
                              </div>
                            )}
                          </div>
                          {/* <div className="mb-3 position-relative">
                            <Input
                              name="password"
                              type={passwordVisible ? "text" : "password"}
                              placeholder="Password"
                              onChange={handlePasswordChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                              }
                              className="password-input"
                            />
                            <span
                              aria-label={
                                passwordVisible
                                  ? "Hide password"
                                  : "Show password"
                              }
                              className="password-visibility-toggle"
                              onClick={togglePasswordVisibility}
                            >
                              {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                            </span>
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <div
                              className="password-strength-meter"
                              style={{
                                color: strengthColors[passwordStrength],
                              }}
                            >
                              {strengthLabels[passwordStrength]}
                            </div>
                          </div> */}

                          <div className="mb-3 position-relative">
                            <div className="input-container">
                              <Input
                                name="confirmPassword"
                                type={passwordVisible1 ? "text" : "password"}
                                placeholder="Confirm Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.confirmPassword || ""}
                                invalid={
                                  validation.touched.confirmPassword &&
                                  validation.errors.confirmPassword
                                    ? true
                                    : false
                                }
                                className="password-input"
                              />
                              <span
                                aria-label={
                                  passwordVisible1
                                    ? "Hide password"
                                    : "Show password"
                                }
                                className="password-visibility-toggle"
                                onClick={togglePasswordVisibility1}
                              >
                                {passwordVisible1 ? <FaEye /> : <FaEyeSlash />}
                              </span>
                              {validation.touched.confirmPassword &&
                              validation.errors.confirmPassword ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.confirmPassword}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className="d-grid mb-3">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={handleGeneratePassword}
                            >
                              Generate Strong Password
                            </button>
                          </div>

                          <div className="mt-4 d-grid">
                            <ButtonMain
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                              isLoading={isLoading}
                            >
                              Next
                            </ButtonMain>
                          </div>
                        </Form>

                        <Form action="dashboard">
                          <div className="text-center">
                            {/* <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="auth-google"
                                  onClick={renderProps.onClick}
                                >
                                  <img src={googleIcon} alt="Google Icon" /> Sign in with Google
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => {}}
                            /> */}
                          </div>
                        </Form>

                        <div className="mt-5 text-center">
                          <p>
                            Already have an account?{" "}
                            <Link to="login" className="fw-medium text-primary">
                              Login
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage
              landingPage={landingImage}
              imageTitle="Appointments"
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register2
