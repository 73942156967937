import ButtonMain from "components/Common/Button"
import {
  addImagingRequest,
  BASE,
  getAllPetsByParentId,
  getImagingAndLabsBasedOnId,
  updateImagingRequest,
  uploadAttachment,
} from "helpers/api_helper"
import Type from "../Appointments/Type"
import { modifyTestTypeOptions } from "helpers/utils"
import * as Yup from "yup"
import { useFormik } from "formik"

import { use } from "i18next"
import React, { useEffect, useReducer, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Alert,
  FormFeedback,
} from "reactstrap"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { showMessage } from "components/Notification"
import ReactSelect from "react-select"
import {
  getAllParents,
  getParentList,
  getpricing,
  getPricingDetails,
  getUserDetails,
  setGlobalLoading,
} from "store/slices"
import { useDispatch, useSelector } from "react-redux"

const AddNewPurchaseOrder = () => {
  //meta title
  document.title = "Invoices"
  // const appointmentType = useSelector(getServices)
  const [isLoading, setIsLoading] = useState(false)
  const [details, setDetails] = useState({})

  const [selectedPet, setSelectedPet] = useState({})
  const [allPets, setAllPets] = useState([])
  const [currentPet, setCurrentSelectedPet] = useState({})
  const [isFetchingPets, setIsFetchingPets] = useState(false)
  const [addPet, setAddPet] = useState(false)
  const parentsList = useSelector(getAllParents)
  const userDetails = useSelector(getUserDetails)

  const [testType, settestType] = useState()
  const [testName, setTestName] = useState(null)
  const [selectedParent, setSelectedParent] = useState({})
  const [typeOfModal, setTypeOfModal] = useState("")
  const [patientTests, setPatientTests] = useState(details?.labTests || [])
  const [modal, setModal] = useState(false)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [selectedStatus, setStatus] = useState({
    label: "Requested",
    value: "Requested",
  })

  const [selectedFiles, setselectedFiles] = useState([])
  const [ImageUrls, setImageUrl] = useState([])
  const { labId } = useParams()
  const dispatch = useDispatch()
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: details?.name || "",
      admissionDate: details?.admissionDate || "",
      quantity: details?.quantity || "",
      total: details?.total || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      admissionDate: Yup.string().required(),
      quantity: Yup.number().required(),
      total: Yup.number().required(),
    }),
    onSubmit: async values => {
      if (!currentPet._id) {
        showMessage({ label: "Please Select Parent and Pet", status: "info" })
        return
      }
      // if (!patientTests?.length) {
      //   showMessage({ label: "Please Add tests", status: "info" })
      //   return
      // }
      if (!testType) {
        showMessage({ label: "Please Select Test Type", status: "info" })
        return
      }
      if (!testName) {
        showMessage({ label: "Please Select Test Name", status: "info" })
        return
      }
      if (!values.notes) {
        showMessage({
          label: `Please add notes`,
          status: "warning",
        })
        return
      }
      const obj = {
        ...values,
        status: "Requested",
        scansOrReports: ImageUrls,
        testType: "labs",
        type: testType.value,
        labTests: patientTests,
        name: testName.value,
        pricing: parseInt(testName.pricing),
      }
      obj.petObjectId = selectedPet.value
      obj.parentId = selectedParent.value
      obj.doctorId = userDetails._id
      if (labId) {
        obj.reportId = labId
      }
      handleSubmit(obj)
      console.log(obj)
    },
  })

  const history = useHistory()
  useEffect(() => {
    async function fetchData() {
      if (!labId) return
      dispatch(setGlobalLoading(true))
      const _detail = await getImagingAndLabsBasedOnId({
        reportId: labId,
        type: "labs",
      })
      setDetails(_detail.data)
      console.log("labTests", _detail)
      // setTimeout(() => {
      setPatientTests(_detail?.data.labTests || [])
      // }, 100);

      dispatch(setGlobalLoading(true))
      setTimeout(() => {
        setSelectedParent({
          label: _detail?.data.parentId?.fullName,
          value:
            _detail?.data.parentId?._id || _detail?.data.petObjectId?.parentId,
        })
        setSelectedPet({
          label: _detail?.data.petObjectId?.fullName,
          value: _detail?.data.petObjectId?._id,
        })
        settestType({
          label: _detail?.data.type,
          value: _detail?.data.type,
        })
        setTestName({
          label: _detail?.data.name,
          value: _detail?.data.name,
        })
        setCurrentSelectedPet({ ..._detail?.data.petObjectId })
        dispatch(setGlobalLoading(false))
      }, 1000)
    }
    fetchData()
  }, [labId])

  const handleSubmit = async values => {
    console.log(values)
    // return
    try {
      setIsLoading(true)
      let res
      if (!labId) {
        res = await addImagingRequest(values)
      } else {
        res = await updateImagingRequest({ ...values, status: "Completed" })
      }

      if (res.status === 1) {
        showMessage({ label: res.message, status: "success" })
        history.push(labId ? "/labs-completed" : "/lab-requests")
      } else {
        showMessage({
          label: res.message || "something went wrong",
          status: "error",
        })
      }
      setIsLoading(false)
    } catch (error) {
      showMessage({
        label: error.message || "something went wrong",
        status: "error",
      })
      setIsLoading(false)
    }
  }
  const handleSelectedParent = async item => {
    setSelectedParent(item)
    setIsFetchingPets(true)
    const response = await getAllPetsByParentId(item.value)
    if (response.status == 1) {
      if (response.data?.length) {
        setAllPets(response.data)
        setIsFetchingPets(false)
        setAddPet(false)
      } else {
        showMessage({
          label: `No pets found for ${item.label} parent`,
          status: "info",
        })
        setAddPet(true)
        setAllPets([])
      }
    }
    setSelectedPet(null)
    setIsFetchingPets(false)
    return response.data
  }
  const handleSelectedPet = async (item, petlist = null) => {
    setSelectedPet(item)
    const selected = [...(petlist?.length ? petlist : allPets)].find(
      ele => ele._id === item.value
    )
    setCurrentSelectedPet(selected)
  }

  useEffect(() => {
    async function newLoad() {
      dispatch(setGlobalLoading(true))
      await dispatch(getParentList({ doctorId: userDetails._id }))
      dispatch(setGlobalLoading(false))
    }
    newLoad()
  }, [dispatch])

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const handleStatus = item => {
    setStatus(item)
    validation.handleChange(item.label)
  }
  const handleUploadScans = async event => {
    try {
      setIsFileUploading(true)

      const formData = new FormData()

      formData.append("files", event.target.files[0])
      const response = await uploadAttachment(formData)
      if (response.status === 1) {
        showMessage({
          label: `Uploaded Successfully! Please press confirm  to continue`,
          status: "success",
        })
        const imageUrl = [...ImageUrls]
        imageUrl.push(response.data[0])
        setImageUrl(imageUrl)
      }

      setIsFileUploading(false)
    } catch (error) {
      console.log(error)
      setIsFileUploading(false)
      showMessage({ label: "Something went wrong!", status: "error" })
    }
  }
  const FileUpload = () => {
    return (
      <div className="file-upload-container mb-4">
        {ImageUrls?.length
          ? ImageUrls.map((item, index) => (
              <div key={index} className="file-upload-image-container">
                <div
                  className="file-upload-image-delete"
                  onClick={() => {
                    const imageUrl = [...ImageUrls]
                    imageUrl.splice(index, 1)
                    setImageUrl(imageUrl)
                  }}
                >
                  X
                </div>
                <img className="file-upload-image" src={BASE + item} />
              </div>
            ))
          : null}
        <div className="file-upload-square">
          +<br />
          Upload Scan
          <input
            type="file"
            onChange={handleUploadScans}
            accept="*"
            className="form-control"
          />
        </div>
      </div>
    )
  }
  const handlePatientTestsDelete = index => {
    const _patientTests = [...patientTests]
    _patientTests.splice(index, 1)
    setPatientTests(_patientTests)
  }

  const renderTests = () => {
    return (
      <div className="cell-tag-container">
        {patientTests?.length ? (
          <>
            <h6>Tests:</h6>
            <div className="cell-tags-roe">
              {patientTests.map(({ title }, index) => {
                return (
                  <div key={`${title}-${index}`} className="cell-tags">
                    <button
                      className="cell-tag-close"
                      onClick={() => handlePatientTestsDelete(index)}
                    >
                      x
                    </button>
                    {title}
                  </div>
                )
              })}
            </div>
          </>
        ) : null}
      </div>
    )
  }

  const handleDataSet = data => {
    const _patientTests = [...patientTests]
    _patientTests.push(data)
    setPatientTests(_patientTests)

    setTypeOfModal("")
  }
  const handleOpenModal = type => {
    setTypeOfModal(type)
  }

  useEffect(() => {
    async function fetchPricingData() {
      if (userDetails?._id) {
        dispatch(setGlobalLoading(true))
        await dispatch(getPricingDetails({ vetId: userDetails?._id }))
        dispatch(setGlobalLoading(false))
      }
    }
    fetchPricingData()
  }, [dispatch])

  const dummyAppointment = useSelector(getpricing)
  console.log("dummyAppointment", dummyAppointment)
  const filterPricingOptions = dummyAppointment
    .filter(ele => ele.category.pricingCategoryName === "Lab Pricing")
    .filter(ele => ele.testingType === testType?.value)
  console.log("filterPricingoptions", filterPricingOptions)

  const LabTestTypeOption1 = dummyAppointment
    .filter(ele => ele.category.pricingCategoryName === "Lab Pricing")
    .filter((obj, index, array) => {
      const firstIndex = array.findIndex(
        item => item.testingType === obj.testingType
      )
      return index === firstIndex
    })
  const LabTestTypeOptions = modifyTestTypeOptions(LabTestTypeOption1)

  const handleLabTypeMenuOpen = () => {
    setTimeout(() => {
      if (LabTestTypeOptions.length === 0) {
        showMessage({
          label: "No Tests Available! Need to Create First",
          status: "info",
        })
      }
    }, 1000)
  }

  const handleParentListMenuOpen = () => {
    setTimeout(() => {
      if (parentsList.length === 0) {
        showMessage({
          label: "No Parents Available! Need to Create First",
          status: "info",
        })
      }
    }, 1000)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="appointment-heading-div">
            <div className="d-sm-flex align-items-end">
              <img
                src={LeftArrow}
                width={25}
                onClick={() => history.goBack()}
                className="mt-2"
                role="button"
              />
              <h4 className="mb-sm-0 font-size-18">Create New PO</h4>
            </div>

            <div className="create-link">
              {/* <Button
                outline
                color="secondary"
                className="mx-2"
                onClick={() => history.push(`/inventory-items-recieved`)}
              >
                + Add deposit
              </Button> */}
            </div>
          </div>
          <Row>
          <Col>
              <FormGroup className="row mb-9">
                <Label className="col-form-label col-lg-5 text-right text-input-label ">
                  Order ID
                </Label>
                <div className="col-lg-7 gray-note d-flex flex-column align-items-start h-80 py-2 ms-3" style={{minWidth:"224px", maxHeight:"50px"}}>
                  <h3>544514</h3>
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="mb-4" row>
                <Label
                  htmlFor="admissionDate"
                  className="col-form-label col-lg-5 text-right text-input-label"
                >
                  Date
                </Label>
                <div className="col-lg-7">
                  <Input
                    id="admissionDate"
                    name="admissionDate"
                    type="date"
                    value={validation.values.admissionDate || ""}
                    onChange={validation.handleChange}
                    className="form-control"
                    placeholder="Name"
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.admissionDate &&
                      validation.errors.admissionDate
                        ? true
                        : false
                    }
                  />
                  {validation.touched.admissionDate &&
                  validation.errors.admissionDate ? (
                    <FormFeedback type="invalid">
                      {validation.errors.admissionDate}
                    </FormFeedback>
                  ) : null}
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup className="row mb-9">
                <Label className="col-form-label col-lg-5 text-right text-input-label">
                  Vendor Name
                </Label>
                <div className="col-lg-7">
                  <ReactSelect
                    classNamePrefix="form-react-select"
                    className="form-react-select"
                  />
                </div>
              </FormGroup>
            </Col>
            <Col></Col>
          </Row>

          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th
                  style={{
                    borderBottom: "1px solid #ddd",
                    borderTop: "1px solid #ddd",
                    padding: "10px",
                  }}
                >
                  Items
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #ddd",
                    borderTop: "1px solid #ddd",
                    padding: "10px",
                  }}
                >
                  Qty.
                </th>

                <th
                  style={{
                    borderBottom: "1px solid #ddd",
                    borderTop: "1px solid #ddd",
                    padding: "10px",
                  }}
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="col-md-2">
                  <Input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Items"
                    value={validation.values.name || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.name &&
                      validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name &&
                  validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </td>
                <td className="col-md-1">
                  <Input
                    type="number"
                    name="quantity"
                    className="form-control"
                    placeholder="Qty."
                    value={validation.values.quantity || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.quantity && validation.errors.quantity
                        ? true
                        : false
                    }
                  />
                  {validation.touched.quantity && validation.errors.quantity ? (
                    <FormFeedback type="invalid">
                      {validation.errors.quantity}
                    </FormFeedback>
                  ) : null}
                </td>

                <td className="col-md-1">
                  <Input
                    type="number"
                    name="total"
                    className="form-control"
                    placeholder="Total"
                    value={validation.values.total || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.total && validation.errors.total
                        ? true
                        : false
                    }
                  />
                  {validation.touched.total && validation.errors.total ? (
                    <FormFeedback type="invalid">
                      {validation.errors.total}
                    </FormFeedback>
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            outline
            color="secondary"
            className="mx-2 mt-2"
            onClick={() => history.push(`/inventory-items-recieved`)}
          >
            + Add Item
          </Button>
          <hr />
          <Row className="d-flex justify-content-end">
            <div className="w-auto">
              <h2>Total</h2>
            </div>
            <div className="w-auto">
              <h2>INR 500</h2>
            </div>
          </Row>

          <Row className="justify-content-end mt-8 ms-15 mb-4 my-4">
            <ButtonMain
              // isLoading={isLoading}
              type="submit"
              className="btn btn-med btn-primary me-1"
            >
              Cancel
            </ButtonMain>

            <ButtonMain
              // isLoading={isLoading}
              type="submit"
              className="btn btn-med btn-primary"
            >
              Save
            </ButtonMain>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddNewPurchaseOrder
