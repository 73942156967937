import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
// import { createStore, applyMiddleware } from 'redux'
import rootReducer from "../src/store/reducers"

const middleware = getDefaultMiddleware({ serializableCheck: false })
// import reducer from "./store/patients"

if (true) {
  // push in logger as the last middleware
  // eslint-disable-next-line global-require
  const { createLogger } = require("redux-logger")
  middleware.push(createLogger({ diff: true, collapsed: true }))
}

// export default function makeStore() {
const store = configureStore({
  devTools: true,
  middleware,
  reducer: rootReducer,
})
// }
export default store
