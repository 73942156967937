import ButtonMain from "components/Common/Button"
import React from "react"
import { Col, Label, Row, UncontrolledTooltip } from "reactstrap"

const Lobby = ({ handleSubmit, connecting, onUserName, getUserName, isFromSeperateWindow }) => {
  return (
    <form className="text-center pt-4" onSubmit={handleSubmit}>
      {!getUserName && <h4 className="pb-2">Connect with patient</h4>}

      {getUserName && (
        <Row>
          <Col lg={3} />
          <Col lg={6}>
          <br/>
          <br/>
          <br/>
            <Label className="col-form-label text-white">
              Enter your name
            </Label>
<br/>
            <input
              type="text"
              id="subject"
              onChange={e => onUserName(e.target.value)}
              className="form-control"
              placeholder="Enter name"
            />
            <br/><br/>
          </Col>
          <Col lg={3} />
        </Row>
      )}

      <UncontrolledTooltip placement="right" target="startVideo">
        Connect
      </UncontrolledTooltip>

      <ButtonMain
        isLoading={connecting}
        className="video-phone video-phone--connect"
        type="submit"
        onClick={handleSubmit}
        id="startVideo"
      >
        <i className="mdi mdi-phone-outline" />
      </ButtonMain>
    </form>
  )
}

export default Lobby
