import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory, useParams } from "react-router-dom"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { Col, Row, Card, CardBody, Button } from "reactstrap"
import { getPricingItemById } from "helpers/api_helper"
// import "./style.scss"
import PurchaseOrderDetailsCard from "./components/PurchaseOrderCard"
// import PetCompleteDetails from "./components/PetCompleteDetails"
import LeftArrow from "../../assets/icons/ArrowLeft.png"

function PurchaseOrderDetails() {
  //meta title
  document.title = "Pet Parents"

  const [itemDetails, setItemDetails] = useState({})
  const [noDetails, setNoDetails] = useState(false)

  const [parentPets, setParentPets] = useState([])

  const [verticalActiveTab, setverticalActiveTab] = useState("1")

  const { itemId } = useParams()

  // fetchAppointmentsListBasedOnPetId
  // const toggleVertical = tab => {
  //   if (verticalActiveTab !== tab) {
  //     setverticalActiveTab(tab)
  //   }
  // }
  useEffect(() => {
    async function fetchData() {
      const detail = await getPricingItemById(itemId)
      if (detail.status === 1 && detail.data) {
        setItemDetails(detail.data)

        // const petList = await getAllPetsByParentId(id)
        // setParentPets(petList.data)
        // setverticalActiveTab(petList.data[0]._id)
      } else {
        setNoDetails(true)
      }
    }
    fetchData()
  }, [])
  if (noDetails) {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Pricing" breadcrumbItem={itemDetails?.name} />
            <Row>
              <Col xs="12">Invalid Purchase Order!!</Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="appointment-heading-div ">
            <div className="d-sm-flex align-items-end">
              <img
                src={LeftArrow}
                width={25}
                onClick={() => history.goBack()}
                className="mt-2"
                role="button"
              />
              <h1 className="mb-sm-0 font-size-18">{itemDetails?.id}</h1>
            </div>

            <div className="create-link">
              <Button
                outline
                color="secondary"
                className="mx-2 mt-2"
                onClick={() => history.push(`/inventory-items-recieved`)}
              >
                Edit PO
              </Button>
            </div>
          </div>
          <Row>
            <Col>OrderId <div><h3>145125</h3></div></Col>
            <Col>Vendor Name <div><h3>ABC Pharma</h3></div></Col>
            <Col>Date <div><h3>12-07-2021</h3></div></Col>
            <Col>Status <div><h3>Waiting</h3></div></Col>
          </Row>
          <hr />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PurchaseOrderDetailsCard
                    testDetails={itemDetails ? itemDetails : {}}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
PurchaseOrderDetails.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default PurchaseOrderDetails
