import React from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"
import { formatAMPM, getDateDDMMYYYY, getDateTime, getStringtime, getTime } from "helpers/utils"

const formateDate = (date, format) => {
  console.log('date', date)
  const dateFormat = format ? format : "DD MMM Y"
  const date1 = moment(new Date(date)).format(dateFormat)
  return date1
}
const toLowerCase1 = str => {
  return str === "" || str === undefined ? "" : str.toLowerCase()
}

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold font-fontFamily">
      {cell.value ? cell.value : ""}
    </Link>
  )
}

const BillingName = cell => {
  return <span style={cell.value == 'completed' ? {color:"green"}:{}} className="font-fontFamily">{cell.value ? cell.value : ""}</span>
}
const Parent = cell => {
  return (
    <Link  to={`/parent/${cell.row?.original?.parentId?._id}`}>{cell.value ? cell.value : ""}</Link>
  )
}

const Date = cell => {
  return cell.value ? cell.value : ""
}

const FormattedTime = cell => {
  return cell.value ? getDateDDMMYYYY(cell.value) : "" 
}

const Total = cell => {
  return cell.value ? cell.value : ""
}

const Time = cell => {
  return cell.value
}

const NumberVal = cell => {
  return typeof cell.value === 'number' ? Number(cell.value) : ""
}

const PaymentStatus = cell => {
  return (
    <Badge
      className={
        "font-size-12 badge-soft-" +
        (cell.value === "Paid"
          ? "success"
          : "danger" && cell.value === "Refund"
          ? "warning"
          : "danger")
      }
    >
      {cell.value}
    </Badge>
  )
}
const PaymentMethod = cell => {
  return (
    <span>
      <i
        className={
          cell.value === "Paypal"
            ? "fab fa-cc-paypal me-1"
            : "" || cell.value === "COD"
            ? "fab fas fa-money-bill-alt me-1"
            : "" || cell.value === "Mastercard"
            ? "fab fa-cc-mastercard me-1"
            : "" || cell.value === "Visa"
            ? "fab fa-cc-visa me-1"
            : ""
        }
      />{" "}
      {cell.value}
    </span>
  )
}
export { OrderId, BillingName, Date, Total, PaymentStatus, PaymentMethod, Time, Parent, FormattedTime, NumberVal }
