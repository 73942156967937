import React, { useEffect } from "react"
import PropTypes from "prop-types"
import {
  CardTitle,
  Col,
  Row,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { IconSVG } from "components/Common/IconSvg"

const TestDetailsCol = ({ testDetails }) => {
    console.log("testDetails",testDetails)
  return (
    <ul className="list-unstyled parent-details__container">
      <div className="d-flex justify-content-between">
      <CardTitle className="mb-4 font-fontFamily"><h4>Pricing Details</h4></CardTitle>
      <div className="history-doc-cta">
        <Link to={`/edit-pricing/${testDetails._id}`} className="font-fontFamily">Edit <IconSVG icon="edit"/></Link>
      </div>
      </div>
      <Row>
        <Col sm="6">
          <li>
            <h6 className="parent-details__title font-fontFamily">Name</h6>
            <p className="parent-details__value font-fontFamily">{testDetails?.name}</p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">Type</h6>
            <p className="parent-details__value font-fontFamily">{testDetails?.testingType}</p>
          </li>
        </Col>
        <Col sm="6">
          <li>
            <h6 className="parent-details__title font-fontFamily">Price</h6>
            <p className="parent-details__value font-fontFamily">Rs {testDetails?.pricing}</p>
          </li>
          <li className="mt-3">
            <h6 className="parent-details__title font-fontFamily">Category</h6>
            <p className="parent-details__value font-fontFamily">{testDetails?.category?.pricingCategoryName}</p>
          </li>
        </Col>
      </Row>
    </ul>
  )
}

TestDetailsCol.propTypes = {
  details: PropTypes.any,
}

export default TestDetailsCol
