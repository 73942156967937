import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Form,
  Input,
  FormFeedback,
  Alert,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import zxcvbn from "zxcvbn"
import { useDispatch } from "react-redux"
import { updatePassword } from "helpers/api_helper"
import ButtonMain from "components/Common/Button"
import landingImage from "../../assets/images/auth/Invoices_landingPage.png"
import logodark from "../../assets/images/supaw-vets.svg"
import logolight from "../../assets/images/supaw-vets.svg"
import CarouselPage from "./AuthenticationInner/CarouselPage"

const generateStrongPassword = () => {
  const length = 12 // Ensure this is at least 8
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-="
  let password = ""
  for (let i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n))
  }
  return password
}

const ChangePassword = () => {
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState("")
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [passwordStrength, setPasswordStrength] = useState("")
  const history = useHistory()
  const dispatch = useDispatch()

  document.title = "Change Password  | Supaw"

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      token: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .max(16, "Password cannot be more than 16 characters long")
        .required("Please enter new password"),
      token: Yup.string().required("Please enter OTP"),
    }),
    onSubmit: async values => {
      try {
        setIsLoading(true)
        const response = await updatePassword(values)
        if (response.status == 1) {
          validation.resetForm()
          setSuccess("Successfully changed. Login to continue")
        }
        setIsLoading(false)
      } catch (error) {
        let message = error?.response?.data?.message
        if (message && typeof message !== "string") {
          message = "something went wrong!"
        }
        setError(message)
        setIsLoading(false)
      }
    },
  })

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const handleGeneratePassword = () => {
    const newPassword = generateStrongPassword()
    validation.setFieldValue("password", newPassword)
    setPasswordStrength(zxcvbn(newPassword).score)
  }

  const calculatePasswordStrength = password => {
    let strength = 0
    if (password.length >= 8) strength += 1 // Length
    if (password.match(/[a-z]/)) strength += 1 // Lowercase
    if (password.match(/[A-Z]/)) strength += 1 // Uppercase
    if (password.match(/[0-9]/)) strength += 1 // Numbers
    if (password.match(/[^a-zA-Z0-9]/)) strength += 1 // Special characters
    return strength // Value from 0 to 5
  }

  const handlePasswordChange = e => {
    const password = e.target.value
    // validation.setFieldValue("password", password)

    // const strength = calculatePasswordStrength(password)
    // setPasswordStrength(strength)
    if (password.length <= 16) {
      validation.setFieldValue("password", password)

      // Calculate and set password strength
      const strength = calculatePasswordStrength(password)
      setPasswordStrength(strength)
    }
  }

  const strengthLabels = ["Weak", "Fair", "Good", "Strong", "Very Strong"]
  const strengthColors = ["#f46a6a", "#564ab1", "#E75C25", "#8BC34A", "#008000"]

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={5}>
              <div className="auth-full-page-content p-md-5 p-4 vh-100">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 onboarding-logo-container">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img src={logodark} alt="" className="auth-logo-dark" />
                        <img
                          src={logolight}
                          alt=""
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto" style={{ padding: "0px 20%" }}>
                      <div>
                        <h5>Change Password</h5>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          {error && !success ? (
                            <Alert color="danger">{error}</Alert>
                          ) : null}
                          {success ? (
                            <Alert color="success">{success}</Alert>
                          ) : null}
                          <div className="mb-3">
                            <Input
                              name="token"
                              className="form-control"
                              placeholder="Enter OTP"
                              type="password"
                              value={validation.values.token || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.token &&
                                validation.errors.token
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.token &&
                            validation.errors.token ? (
                              <FormFeedback type="invalid">
                                {validation.errors.token}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3 position-relative">
                            <div className="input-container">
                              <Input
                                name="password"
                                type={passwordVisible ? "text" : "password"}
                                placeholder="Password"
                                onChange={handlePasswordChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                }
                                className="password-input"
                              />
                              <span
                                aria-label={
                                  passwordVisible
                                    ? "Hide password"
                                    : "Show password"
                                }
                                className="password-visibility-toggle"
                                onClick={togglePasswordVisibility}
                              >
                                {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                              </span>
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                            {/* Conditionally render the password strength meter */}
                            {!success &&
                              validation.values.password.length > 0 && (
                                <div className="password-strength-meter">
                                  <div
                                    className="strength-line"
                                    style={{
                                      backgroundColor:
                                        strengthColors[passwordStrength],
                                      width: `${(passwordStrength + 1) * 20}%`,
                                      height: "4px",
                                      marginBottom: "5px",
                                    }}
                                  ></div>
                                  <span
                                    style={{
                                      color: strengthColors[passwordStrength],
                                    }}
                                  >
                                    {strengthLabels[passwordStrength]}
                                  </span>
                                </div>
                              )}
                          </div>

                          <div className="d-grid mb-3">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={handleGeneratePassword}
                            >
                              Generate Strong Password
                            </button>
                          </div>
                          <div className="mt-3">
                            <ButtonMain
                              className="btn btn-primary btn-block"
                              type="submit"
                              isLoading={isLoading}
                            >
                              Update
                            </ButtonMain>
                          </div>
                        </Form>
                        <div className="mt-5 text-center">
                          <p>
                            <Link to="login" className="fw-medium text-primary">
                              Sign In here
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage landingPage={landingImage} imageTitle="Pharmacy" />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword
