import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Lottie from "react-lottie"
import {
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import * as animationData from "../../assets/images/lottie/delete.json"
import {
  getGenericCategory,
  getPricingCategory,
  getUserDetails,
} from "store/slices"
import { useDispatch, useSelector } from "react-redux"
import {
  addNewPricingCategory,
  getPricingCategoryById,
  updateCategory,
} from "helpers/api_helper"
import ReactSelect from "react-select"
import "../../assets/scss/custom/pages/_formModal.scss"

const UpdateModal = ({ show, onDeleteClick, onCloseClick, categoryId }) => {
  const userDetails = useSelector(getUserDetails)
  const [details, setDetails] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const [selectedOption, setSelectedOption] = useState(null)

  const selectOptions = [
    { value: "PriceCategories", label: "PriceCategories" },
    { value: "generic_pricing", label: "generic_pricing" },
    // Add more options as needed
  ]

  const dispatch = useDispatch()
  console.log("categoryId", categoryId)
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      categoryName: details.pricingCategoryName,
      categoryType: details.type,
    },
    validationSchema: Yup.object({
      categoryName: Yup.string().required("Please Enter Catgeory Name"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const obj = {
        vetId: userDetails._id,
        pricingCategoryName: values.categoryName,
        type: selectedOption.value,
        icon: "",
      }

      console.log(obj)
      setIsLoading(true)
      try {
        let response = {}
        response = await updateCategory(categoryId, obj)
        if (selectedOption.value === "PriceCategories") {
          dispatch(getPricingCategory({ vetId: userDetails?._id }))
        } else {
          dispatch(getGenericCategory(userDetails?._id))
          console.log("selectedOption5", selectedOption)
        }

        if (response.status == 1) {
          onCloseClick()
          dispatch(getPricingCategory())
        }
      } catch (error) {
        showMessage({
          label: `Something went wrong, try again`,
          status: "error",
        })
      }
      setIsLoading(false)
    },
  })

  useEffect(() => {
    async function fetchCategoryData() {
      console.log("working")
      const _details = await getPricingCategoryById(categoryId)
      console.log(_details.data)
      setDetails(_details.data)
      setSelectedOption({
        label: _details.data.type,
        value: _details.data.type,
      })
    }
    fetchCategoryData()
  }, [categoryId])

  const handleCloseClick = () => {
    validation.resetForm()
    onCloseClick()
  }

  console.log("selectedOption", selectedOption)
  return (
    <Modal
      size="md"
      isOpen={show}
      toggle={handleCloseClick}
      centered={false}
      className="modal-dialog-right"
    >
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={handleCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>

          <p className="font-size-24 mb-4">Update Category</p>
          <div>
            <Form onSubmit={validation.handleSubmit}>
              <FormGroup className="mb-4" row>
                <Label className="col-form-label col-lg-6  text-right text-input-label">
                  Category Name
                </Label>
                <div className="col-lg-6">
                  <Input
                    id="categoryName"
                    name="categoryName"
                    type="text"
                    className="form-control"
                    placeholder="Enter category name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.categoryName}
                    invalid={
                      validation.touched.categoryName &&
                      validation.errors.categoryName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.categoryName &&
                  validation.errors.categoryName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.categoryName}
                    </FormFeedback>
                  ) : null}
                </div>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label
                  htmlFor="category"
                  className="col-form-label col-lg-6  text-right text-input-label"
                >
                  Category
                </Label>
                <div className="col-lg-6">
                  <ReactSelect
                    name="categoryType"
                    options={selectOptions}
                    value={selectedOption}
                    onChange={setSelectedOption}
                    classNamePrefix="form-react-select"
                    className="form-react-select mb-4 "
                  />
                </div>
              </FormGroup>
              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="submit"
                  className="btn btn-primary btn-secondary btn-med btn-lg ms-2"
                >
                  Update Category
                </button>
                {/* <button type="button" style={{minWidth:140}} className="btn btn-primary btn-lg ms-2" onClick={onCloseClick}>Close</button> */}
              </div>
            </Form>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

UpdateModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default UpdateModal
