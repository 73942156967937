import classnames from "classnames"
import ButtonMain from "components/Common/Button"
import { IconSVG } from "components/Common/IconSvg"
import { BASE, fetchAppointmentsListBasedOnPetId } from "helpers/api_helper"
import { getDateDDMMYYYY } from "helpers/utils"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Nav, NavItem, NavLink, Row } from "reactstrap"
import ReactSelect from "react-select"
const HistoryRecords = ({ petDetails, parentPets }) => {
  // console.log("PetDetails",petDetails)
  console.log("ParentPets", parentPets)
  const [currentPetDetails, setCurrentPetDetails] = useState([])
  const [currentAppointmentType,setCurrentAppointmentType] =useState({ label: "All", value: "All" });
  console.log("CurrentPetDetails", currentPetDetails)
  const NoMedicalHistoryAvailable = () => {
    return (
      <h6 className="no-data font-fontFamily" style={{background:"white"}}>No Appointments available</h6>
    )
  }
  useEffect(() => {
    async function fetchData() {
      if (petDetails._id) {
        const firstPetDetails = await fetchAppointmentsListBasedOnPetId(
          activeTabName?._id
        )
        if (firstPetDetails.status === 1) {
          setCurrentPetDetails(firstPetDetails.data)
        }
      }
    }
    fetchData()
  }, [])

  const pricingCategory = parentPets.filter(ele => ele.colour)
  const [activeTab, setActiveTab] = useState(0)
  const [activeTabName, setActiveTabName] = useState(pricingCategory[activeTab])
  const appointmentTypes = [
    { label: "All", value: "All" },
    { label: "Primary Care", value: "Primary Care" },
    { label: "Virtual Care", value: "Virtual Care" },
    { label: "Urgent Care", value: "Urgent Care" },
  ]
  console.log("ActiveTabName:", activeTabName)
  console.log("ActiveTab", activeTab)
  const handleTabClick = tabId => {
    setActiveTab(tabId)
    setActiveTabName(pricingCategory[tabId])
  }
  useEffect(() => {
    async function fetchData() {
      if (petDetails._id) {
        const firstPetDetails = await fetchAppointmentsListBasedOnPetId(
          activeTabName?._id
        )
        if (firstPetDetails.status === 1) {
          setCurrentPetDetails(firstPetDetails.data)
        }
      }
    }
    fetchData()
  }, [activeTabName])
  
  const filterVal = currentPetDetails?.filter(
    obj => obj.visitType === currentAppointmentType?.value
  )
  console.log("FilterVal",filterVal)
  return (
    <React.Fragment>
      <div className="history-doc-cta mb-4 d-flex align-items-center justify-content-between mx-2">
        <div>
          <h3>Appointment history</h3>
        </div>
        <div className="d-flex gap-2">
          <ReactSelect
            className="font-fontFamily fontSize-18 font-weight-500"
            styles={{
              control: provided => ({
                ...provided,
                width: "210px",
                background: "#FFFFFF",
                color: "#2C2C2E",
              }),
            }}
            value={currentAppointmentType}
            onChange={setCurrentAppointmentType}
            options={appointmentTypes}
          ></ReactSelect>
          <Link
            to={`/add-appointment/pet/${activeTabName._id}`}
            className="font-fontFamily fontSize-18 font-weight-500"
            style={{
              background: "#652B88",
              color: "white",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
            }}
          >
            + Add Appointment
          </Link>
        </div>
      </div>
      <Row className="mx-2 my-4">
        <Nav tabs>
          {pricingCategory.map((pVal, index) => (
            <NavItem key={index}>
              <NavLink
                className={activeTab === index ? "active" : ""}
                onClick={() => handleTabClick(index)}
                style={{
                  color: activeTab === index ? "#652B88" : "#6B7280",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: activeTab === index ? "2px solid #652B88" : "",
                  fontSize: "18px",
                  paddingBottom: "7px",
                }}
              >
                <p className="font-fontFamily">{pVal.fullName}</p>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Row>
      {currentAppointmentType?.value==="All"? <ul className="verti-timeline list-unstyled">
        {currentPetDetails?.length ? (
          currentPetDetails.map((item, key) => (
            <li key={key} className="event-list">
              <div className="event-timeline-dot" />
              <div className="d-flex">
                <div className="history-list-item">
                  <div className="history-appointment-details">
                    <div className="history-appointment-type font-fontFamily">
                      {item.visitType}
                      <span className="history-appointment-type font-fontFamily">
                        {getDateDDMMYYYY(item.date)}
                      </span>
                    </div>
                    <div className="history-appointment-reason font-fontFamily">
                      Reason: {item.reasonForVisit}
                    </div>
                    <div className="history-appointment-invoice font-fontFamily fontSize-14">
                      Invoice total: <span> {item.cost}</span>
                    </div>
                  </div>
                  <div className="history-appointment-notes font-fontFamily">
                    {item.notes}
                  </div>
                  <div className="history-appointment-otherdetails font-fontFamily">
                    {item.doctorId && (
                      <div className="history-doc-details">
                        <img src={item.doctorId.images[0]} />
                        <p className="fontSize-14">{item.doctorId.fullName}</p>
                      </div>
                    )}
                    <div className="history-doc-cta font-fontFamily font-weight-text">
                      <Link to={`/prescription-details/${item._id}`}>
                        View Prescription
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))
        ) : (
          <NoMedicalHistoryAvailable />
        )}
      </ul>:<ul className="verti-timeline list-unstyled">
        {filterVal?.length ? (
          filterVal.map((item, key) => (
            <li key={key} className="event-list">
              <div className="event-timeline-dot" />
              <div className="d-flex">
                <div className="history-list-item">
                  <div className="history-appointment-details">
                    <div className="history-appointment-type font-fontFamily">
                      {item.visitType}
                      <span className="history-appointment-type font-fontFamily">
                        {getDateDDMMYYYY(item.date)}
                      </span>
                    </div>
                    <div className="history-appointment-reason font-fontFamily">
                      Reason: {item.reasonForVisit}
                    </div>
                    <div className="history-appointment-invoice font-fontFamily">
                      Invoice total: <span> {item.cost}</span>
                    </div>
                  </div>
                  <div className="history-appointment-notes font-fontFamily">
                    {item.notes}
                  </div>
                  <div className="history-appointment-otherdetails font-fontFamily">
                    {item.doctorId && (
                      <div className="history-doc-details">
                        <img src={item.doctorId.images[0]} />
                        <p className="fontSize-14">{item.doctorId.fullName}</p>
                      </div>
                    )}
                    <div className="history-doc-cta font-fontFamily font-weight-text">
                      <Link to={`/prescription-details/${item._id}`}>
                        View Prescription
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))
        ) : (
          <NoMedicalHistoryAvailable />
        )}
      </ul>}
     
    </React.Fragment>
  )
}

export default HistoryRecords
