import axios from "axios"
import { BASE } from "helpers/api_helper"
import { components } from "react-select"
import { debounce } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import ReactSelect from "react-select"
import AsyncSelect from "react-select/async"
import { Col, Form, Input, Label, Row } from "reactstrap"
import { getAllVaccines } from "store/slices"

const DOSAGE = [
  { value: "0-0-1", label: "0-0-1" },
  { value: "0-1-1", label: "0-1-1" },
  { value: "1-1-1", label: "1-1-1" },
  { value: "1-1-0", label: "1-1-0" },
  { value: "0-1-0", label: "0-1-0" },
  { value: "1-0-0", label: "1-0-0" },
  { value: "1-0-1", label: "1-0-1" },
]

const VaccinationForm = ({ onConfirm, data, agegroup, petType }) => {
  const [formRows, setFormRows] = useState(data || [{ id: 1 }])
  const [inputValue, setInputValue] = useState(null)
  const [secondSelectOptions, setSecondSelectOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setFormRows(data || [{ id: 1 }])
  }, [data])

  const onAddFormRow = () => {
    // // const hasEmptyFields = formRows.some(
    // //   row =>  !row.DOV || !row.EOP
    // // )
    // console.log("onAddFormRow", hasEmptyFields,formRows)
    // setInputValue("")
    // if (hasEmptyFields) {
    //   // Show an error message or handle the validation as required
    //   setInputValue("Please fill  all the fields before adding a new row.")
    //   return
    // }

    // Add a new row
    const modifiedRows = [...formRows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setFormRows(modifiedRows)
  }

  const onDeleteFormRow = id => {
    if (id !== 1) {
      const modifiedRows = formRows.filter(row => row.id !== id)
      setFormRows(modifiedRows)
      onConfirm(modifiedRows)
    }
  }

  const handleAddTitleAndDescription = (index, type, value) => {
    const modifiedRows = [...formRows]
    modifiedRows[index][type] = value
    setFormRows(modifiedRows)
    onConfirm(modifiedRows)
  }

  // handle input change event
  const handleInputChange = value => {
    setInputValue(value)
  }

  // handle selection
  const handleChange = (selected, index) => {
    handleAddTitleAndDescription(index, "vaccine", null)
    handleAddTitleAndDescription(index, "type", selected)
    loadOptionsMed(selected.name)
  }
 

  // load options using API call
  const loadOptions = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser"))
      const headers = {
        Authorization: `${token?.token}`,
        "Content-Type": "application/json",
      }
      const response = await axios.post(
        `${BASE}api/vaccinations/v1/vaccinationtype`,
        { agegroup: agegroup, animalType: petType },
        { headers }
      )
      return response.data.data || []
    } catch (error) {
      console.error("Error fetching options:", error)
      return []
    }
  }

  const loadOptionsMed = async (type) => {
    try {
      // Simulating API call with debounce
      await debounce(async () => {
        const token = JSON.parse(localStorage.getItem("authUser"));
        const headers = {
          Authorization: `${token?.token}`,
          "Content-Type": "application/json",
        };
        setIsLoading(true);
        const response = await axios.get(
          `${BASE}api/vaccinations/v1/vaccinationList/${type}`,
          { headers }
        );
        setSecondSelectOptions(
          response.data.data.map((it) => {
            return {
              label: it.Vaccine,
              value: it.Vaccine,
            };
          }) || []
        );
        setIsLoading(false);
        return response.data.data || [];
      }, 500)();
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    return () => {
      setInputValue(null)
    }
  }, [])
  const Option = props => {
    return (
      <div>
        <components.Option
          {...props}
          className="multiselect-combobox__option-container"
        >
          <span>
            <label className="text-center">{props.data.name}</label>
          </span>
        </components.Option>
      </div>
    )
  }
  
  return (
    <>
      <Col lg={12}>
        <div className="text-center">
          <br />
          <Form className="repeater mt-12" encType="multipart/form-data">
            <div>
              <Row>
                <Col lg={4}>
                  <Label
                    htmlFor="projectdesc"
                    className="col-form-label text-left text-input-label text-input-label--small font-fontFamily"
                  >
                    Type
                  </Label>
                </Col>
                <Col lg={2}>
                  <Label
                    htmlFor="projectdesc"
                    className="col-form-label text-left text-input-label text-input-label--small font-fontFamily"
                  >
                    vaccines
                  </Label>
                </Col>
                <Col lg={3}>
                  <Label
                    htmlFor="projectdesc"
                    className="col-form-label text-left text-input-label text-input-label--small font-fontFamily"
                  >
                    Date of Vaccine
                  </Label>
                </Col>
                <Col lg={2}>
                  <Label
                    htmlFor="projectdesc"
                    className="col-form-label  text-left text-input-label text-input-label--small font-fontFamily"
                  >
                    Expiry Date
                  </Label>
                </Col>
                <Col lg={1}></Col>
              </Row>
              {(formRows || []).map((formRow, key) => {
                return (
                  <Row key={key} className="mb-4">
                    <Col lg={3}>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={formRow.type}
                        getOptionLabel={e => `${e.name}`}
                        getOptionValue={e => e.name}
                        loadOptions={loadOptions}
                        onInputChange={debounce(handleInputChange, 1000)}
                        components={{
                          Option,
                        }}
                        classNamePrefix="form-react-select"
                        className="form-react-select"
                        onChange={sys => handleChange(sys, key)}
                      />
                    </Col>
                    <Col lg={3}>
                      <ReactSelect
                        isLoading={isLoading}
                        isSearchable
                        classNamePrefix="form-react-select"
                        className="form-react-select"
                        placeholder="Select vaccines "
                        options={secondSelectOptions}
                        value={formRow.vaccine}
                        onChange={sys => handleAddTitleAndDescription(key,"vaccine",sys)}
                      />
                    </Col>
                    <Col lg={3}>
                      <Input type="date" placeholder="date of vaccinations" min={new Date().toISOString().split('T')[0]} onChange={(e)=>handleAddTitleAndDescription(key,"DOV",e.target.value)} />
                    </Col>
                    <Col lg={2}>
                      <Input type="date" min={new Date().toISOString().split('T')[0]} onChange={(e)=>handleAddTitleAndDescription(key,"EOV",e.target.value)} />
                    </Col>

                    <Col lg={1} className="align-self-center">
                      <div className="d-grid">
                        <button
                          type="button"
                          style={{ height: 47 }}
                          className="btn btn-dark"
                          onClick={() => onDeleteFormRow(formRow.id)}
                        >
                          <i className="bx bx-minus label-icon"></i>
                        </button>
                      </div>
                    </Col>
                  </Row>
                )
              })}
              <p style={{ color: "red" }}>{inputValue}</p>
            </div>
            <div
              className="justify-content-end d-flex"
              style={{ padding: 40, paddingRight: 0 }}
            >
              <button
                type="button"
                className="btn btn-dark"
                value="Add"
                onClick={onAddFormRow}
              >
                <i className="bx bx-plus label-icon"></i>
              </button>
            </div>
          </Form>
        </div>
      </Col>
    </>
  )
}

VaccinationForm.propTypes = {
  onConfirm: PropTypes.func,
  data: PropTypes.array,
}

export default VaccinationForm
