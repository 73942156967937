import React from 'react'

export default function Stepper({active}) {
  return (
    <div className="stepper-container">
      <div className="circle-container d-flex my-1 ">
        <Circle number={1} active={active} />
        <Circle number={2} active={active} />
        <Circle number={3} active={active} />
        <Circle number={4} active={active} />
      </div>
      <Line />
    </div>
  )
}

const Circle = ({ number,active }) => {
    return <div className={active===number?"circle-active":"circle"}>{number}</div>;
  };

  const Line = () => {
    return <div className="line"></div>;
  };