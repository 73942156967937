import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { addImagingRequest, updateImaging } from "helpers/api_helper"
import { showMessage } from "components/Notification"
import { getpricing } from "store/slices"
import { useSelector } from "react-redux"
import ReactSelect from "react-select"
import { modifyTestType, modifyTestTypeOptions } from "helpers/utils"

const ImagingAndLabTest = ({ relationIds, onImagingTestValues,id }) => {
  const [modal, setModal] = useState(false)

  // ********************************

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      appointmentId:"",
      type: "",
      notes: "",
      radiologist: "",
      testType: "imaging",
      name: "",
    },
    validationSchema: Yup.object({
      // type: Yup.string().required("Please Enter type"),
      notes: Yup.string().required("Please Enter notes"),
      radiologist: Yup.string().required("Please Enter radiologist"),
    }),
    onSubmit: async values => {
      handleConfirm(values)
    },
  })

  const handleConfirm = async values => {
    const obj = {
      appointmentId:id,
      type: imagingType.value,
      notes: values.notes,
      radiologist: values.radiologist,
      status: "Requested",
      testType: "imaging",
      name: imagingName.value,
      pricing: parseInt(imagingName.pricing),
      ...relationIds,
    }
    const imagingTestValues = {
      type: imagingName.value,
      result: "Test for your pet",
      url: "",
      notes: values.notes,
    }
    onImagingTestValues(imagingTestValues)
    try {
      const res = await addImagingRequest(obj)
      if (res.status === 1) {
        showMessage({ label: "Added Successfully", status: "success" })
        return
      }
      showMessage({ label: "Something went wrong", status: "error" })
    } catch (error) {
      showMessage({ label: "Something went wrong", status: "error" })
    } finally {
      setModal(false)
    }
  }

  const [imagingName, setImagingName] = useState(null)
  const [imagingType, setImagingType] = useState(null)

  // const ImagingTestTypeOptions = [
  //   {
  //     value: "Radiography",
  //     label: "Radiography",
  //   },
  //   { value: "Ultrasonography", label: "Ultrasonography" },
  //   { value: "Nuclear Medicine", label: "Nuclear Medicine" },
  //   { value: "Diagnostic Procedures", label: "Diagnostic Procedures" },
  // ]

  const dummyAppointment = useSelector(getpricing)
  console.log("dummyAppointment", dummyAppointment)
  const filterPricingOptions = dummyAppointment
    .filter(ele => ele.category.pricingCategoryName === "Imaging Pricing")
    .filter(ele => ele.testingType === imagingType?.value)
  console.log("filterPricingoptions", filterPricingOptions)

  const ImagingTestTypeOptions1 = dummyAppointment
    .filter(ele => ele.category.pricingCategoryName === "Imaging Pricing")
    .filter((obj, index, array) => {
      const firstIndex = array.findIndex(
        item => item.testingType === obj.testingType
      )
      return index === firstIndex
    })
  const ImagingTestTypeOptions = modifyTestTypeOptions(ImagingTestTypeOptions1)

  const handleOptionChange = value => {
    console.log(value)
    setImagingName({})
    setImagingType(value)
  }
  return (
    <>
      <button
        type="button"
        className="btn btn-dark"
        value="Add"
        style={{ marginRight: 12 }}
        onClick={() => setModal(true)}
      >
        + Add Imaging Tests
      </button>
      {modal ? (
        <Modal
          isOpen={modal}
          toggle={() => setModal(prevState => !prevState)}
          // centered={true}
          size="md"
        >
          <ModalBody className="py-3 px-5">
            <Col lg={12}>
              <div>
                <h2 className="font-fontFamily">Imaging</h2>
                <br />
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <div>
                    <Row>
                      <div className="col-lg-12">
                        <Label className="col-form-label text-input-label font-fontFamily">
                          Type
                        </Label>
                        <div className="col-lg-11">
                          <ReactSelect
                            name="type"
                            value={imagingType}
                            onChange={handleOptionChange}
                            // isDisabled={!!labId}
                            options={ImagingTestTypeOptions}
                            classNamePrefix="form-react-select"
                            className="form-react-select"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <Label className="col-form-label text-input-label font-fontFamily">
                          Select Imaging
                        </Label>
                        <div className="col-lg-11">
                          <ReactSelect
                            name="imagingName"
                            value={imagingName}
                            onChange={setImagingName}
                            // isDisabled={!!labId}
                            options={modifyTestType(filterPricingOptions)}
                            classNamePrefix="form-react-select"
                            className="form-react-select"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <Label className="col-form-label text-input-label font-fontFamily">
                          Notes
                        </Label>
                        <div className="col-lg-11">
                          <Input
                            name="notes"
                            id="ttil"
                            className="form-control"
                            placeholder="Enter Notes"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.notes || ""}
                            invalid={
                              validation.touched.notes &&
                              validation.errors.notes
                                ? true
                                : false
                            }
                          />
                          {validation.touched.notes &&
                          validation.errors.notes ? (
                            <FormFeedback type="invalid">
                              {validation.errors.notes}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <Label className="col-form-label text-input-label font-fontFamily">
                          Radiologist
                        </Label>
                        <div className="col-lg-11">
                          <Input
                            name="radiologist"
                            id="ttil"
                            className="form-control"
                            placeholder="Enter radiologist"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.radiologist || ""}
                            invalid={
                              validation.touched.radiologist &&
                              validation.errors.radiologist
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </Row>
                  </div>

                  <div className="mt-3 col-lg-11">
                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg ms-2 w-40 font-fontFamily"
                      >
                        Confirm
                      </button>
                      <button
                        type="button"
                        className="btn  btn-primary btn-danger btn-lg ms-4 w-40 font-fontFamily"
                        onClick={() => setModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  )
}

ImagingAndLabTest.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default ImagingAndLabTest
