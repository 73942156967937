import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import { isEmpty, map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Import Image
import logo from "../../../assets/images/supaw-vets.svg"
import { fetchAppointmentsBasedOnId } from "helpers/api_helper"
import { getDateDDMMYYYY } from "helpers/utils"
import { useDispatch } from "react-redux"
import { setGlobalLoading } from "store/slices"

import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"

const InvoiceDetail2 = props => {
  const [details, setDetails] = useState({})
  const [noPrescriptionFound, setPrescriptionFound] = useState(false)

  //meta title
  document.title = "Prescription Detail | Supaw"

  const {
    match: { params },
  } = props
  const dispatch = useDispatch()
  useEffect(() => {
    async function fetchData() {
      document.body.classList.add("prescription-container")
      if (params && params.id) {
        // dispatch(setGlobalLoading(true))
        const _details = await fetchAppointmentsBasedOnId(params.id)
        if (_details.status === 1 && _details.data) {
          setDetails(_details.data)
          dispatch(setGlobalLoading(false))
        } else {
          setPrescriptionFound(true)
          dispatch(setGlobalLoading(false))
        }
      }
    }
    fetchData()
  }, [params])

  //Print the Invoice
  const printInvoice = () => {
    window.print()
  }
  const _specialization =
    typeof details?.doctorId?.specialization === "object"
      ? details?.doctorId?.specialization?.map(el => el.label).join(", ")
      : details?.doctorId?.specialization

  const handleDownloadPDF = () => {
    const content = document.querySelector(".download-pdf-content")

    const scale = 0.8 // Adjust the scale factor to control the zoom level

    html2canvas(content, {
      scale: scale,
      useCORS: true,
      scrollX: 0,
      scrollY: -window.scrollY,
    }).then(canvas => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF("p", "mm", "a4")
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = pdfWidth * scale
      const imgHeight = pdfHeight * scale
      const offsetX = (pdfWidth - imgWidth) / 2
      const offsetY = (pdfHeight - imgHeight) / 2
      pdf.addImage(
        imgData,
        "PNG",
        offsetX,
        offsetY,
        imgWidth,
        imgHeight,
        "",
        "FAST"
      )
      pdf.save("document.pdf")
    })
  }
  return (
    <React.Fragment>
      <div className="page-content prescription-container">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Invoices" breadcrumbItem="Detail" />
          {!isEmpty(details) || !noPrescriptionFound ? (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="download-pdf-content">
                      <div className="invoice-title">
                        <div className="float-end ">
                          <div>
                            <h4 className="font-size-16">
                              Invoice Id:{" "}
                              <span className="text-primary">
                                {/* {details?.petObjectId?.petId} */}
                                #235648
                              </span>
                            </h4>
                          </div>
                          <div>
                            <h4 className="font-size-16">
                              GSTIN:{" "}
                              <span className="text-primary">
                                {/* {details?.petObjectId?.petId} */}
                                29AAEC565EY78696
                              </span>
                            </h4>
                          </div>
                          <div>
                            <h4 className="font-size-16">
                              PAN:{" "}
                              <span className="text-primary">
                                {/* {details?.petObjectId?.petId} */}
                                AAEC895RT2
                              </span>
                            </h4>
                          </div>
                        </div>

                        <div className="mb-4">
                          <div className="prescription-doc-details">
                            <img src={logo} alt="logo" height="20" />
                            <span className="prescription-doc-details-name">
                              {/* {details?.doctorId?.fullName} */}
                              <div>
                                D-50, Adarsh Nagar, New Delhi Delhi 110005
                              </div>
                              <div>mercypets@gmail.com</div>
                              <div>+91 (011) 6458 7774</div>
                            </span>
                            {/* <span className="prescription-doc-details-spec">
                              {_specialization || "NA"}
                            </span> */}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <Row>
                        <Col sm="4">
                          Invoice To
                          <div>Rahul Sharma</div>
                        </Col>
                        <Col sm="4">
                          Admission Date
                          <div>26-06-2021</div>
                        </Col>
                        <Col sm="4">
                          Appointment type
                          <div>General</div>
                        </Col>
                      </Row>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 fw-bold">Medicines</h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table table-bordered">
                          <thead>
                            <tr>
                              <th style={{ width: "70px" }}>No.</th>
                              <th>Medicine Name</th>
                              <th>Dosage</th>
                              <th>Duration</th>
                              <th className="text-end">Instructions</th>
                            </tr>
                          </thead>
                          <tbody className="table-group-divider">
                            {details?.medicines?.length
                              ? details?.medicines?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.medicine}</td>
                                    <td>{item.dosage}</td>
                                    <td>{item.duration}</td>
                                    <td className="text-end">
                                      {item.instructions}
                                    </td>
                                  </tr>
                                ))
                              : null}
                          </tbody>
                        </Table>
                      </div>

                      <Row>
                        <Col sm="6">
                          <address>
                            <strong>Primary Diagnosis:</strong>
                            <br />
                            <span>
                              {details?.primaryDiagnosis
                                ?.filter(el => el.type === "primary")
                                .map(el1 => {
                                  return (
                                    <span key={el1.title}>{el1.title}</span>
                                  )
                                })}
                            </span>
                            <br />
                            <strong>Secondary Diagnosis:</strong>

                            <span>
                              {details?.primaryDiagnosis
                                ?.filter(el => el.type === "secondary")
                                .map(el1 => {
                                  return (
                                    <span key={el1.title}>{el1.title}</span>
                                  )
                                })}
                            </span>
                          </address>
                        </Col>
                        <Col sm="6" className="text-sm-end">
                          <address>
                            <strong>Allergy: </strong>
                            <span>
                              {details?.patientAllergies?.map(el1 => {
                                return <span key={el1.title}>{el1.title}</span>
                              })}
                            </span>
                          </address>
                        </Col>
                      </Row>

                      <div className="prescription-notes">
                        <strong>notes: </strong>
                        {details?.notes}
                      </div>
                      <Row>
                        <Col sm="6" />
                        <Col sm="6" className="text-sm-end">
                          <div className="authorized-signatory">
                            Authorised Signatory{" "}
                          </div>
                        </Col>
                      </Row>
                      <div className="text-center">
                        Prescription generated on Supaw.com
                      </div>
                    </div>
                    <div className="d-print-none">
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={handleDownloadPDF}
                          className="btn btn-success  me-2"
                        >
                          <i className="fa fa-download" />
                        </Link>
                        <Link
                          to="#"
                          onClick={printInvoice}
                          className="btn btn-success  me-2"
                        >
                          <i className="fa fa-print" />
                        </Link>
                        <Link to="#" className="btn btn-primary w-md ">
                          Send
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Card>
                <CardBody>
                  <CardText>No Prescription found</CardText>
                </CardBody>
              </Card>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

InvoiceDetail2.propTypes = {
  match: PropTypes.any,
}

export default withRouter(InvoiceDetail2)
