import React from 'react';
import { render } from 'react-dom';
import Notification from './components/Notification';

const el = document.createElement('div');
document.body.appendChild(el);

const { showMessage, hideMessage, updateMessageText } = render(<Notification />, el);

export { showMessage, hideMessage, updateMessageText };
