import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { GoogleLogin } from "react-google-login"
import CarouselPage from "./AuthenticationInner/CarouselPage"
import { google } from "../../config"

// import images
import logodark from "../../assets/images/supaw-vets.svg"
import logolight from "../../assets/images/supaw-vets.svg"

import googleIcon from "../../assets/images/auth/google.png"
import { useDispatch, useSelector } from "react-redux"
import ButtonMain from "components/Common/Button"
import {
  createpricing,
  getAllVetSpecialization,
  registerVet,
  updateVet,
} from "helpers/api_helper"
import landingImage from "../../assets/images/auth/Invoices_landingPage_new.png"
import GooglePlacesAutocomplete from "components/google-location"
import ReactSelect from "react-select"
import { modifySpecializations } from "helpers/utils"
import { getCategoryOfPricing, getPricingCategory } from "store/slices"
import { showMessage } from "components/Notification"
import Stepper from "./AuthenticationInner/Stepper"
const RegisterStep4 = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [selectedAAppointmentType, setAppointmentType] = useState([])
  const [selectedConsultationTypes, setSelectedConsultationTypes] = useState([])

  const allCategories = useSelector(getCategoryOfPricing)
  //meta title
  document.title = "Register | Supaw"

  //form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      consultationType: "",
      price: "",
      appointmentType: selectedAAppointmentType,
    },

    onSubmit: async values => {
      console.log("step1")
      if (pricingArray?.length === 0) {
        showMessage({
          label: "Please Select Consultation Type and Enter Pricing",
          status: "warning",
        })
        return
      }
      if (!selectedAAppointmentType?.length) {
        showMessage({
          label: "Please select appointment type(s)",
          status: "warning",
        })
        return
      }
      try {
        setIsLoading(true)
        const values1 = {
          stepFour: true,
          appointmentType: selectedAAppointmentType,
        }
        const objArray = pricingArray.map(item => ({
          vetId: userId,
          name: item.name,
          pricing: item.price,
          category: allCategories[0]?._id,
          testingType: "NA",
          description: "",
        }))

        for (const obj of objArray) {
          console.log(obj)
          await createpricing(obj)
        }
        const response = await updateVet(userId, values1);
        if (response.status === 1) {
          showMessage({
            label: "Registration Successful! Please log in to continue.",
            status: "success",
          });
          
          setTimeout(() => {
            history.push("/login?successMessage=Registration Successful! Please log in to continue.");
          }, 2000); // Adjust the timeout duration as needed
        }
      } catch (error) {
        let message = error?.response?.data?.message
        if (message && typeof message !== "string") {
          message = "something went wrong!"
        }
        setError(message)
        setIsLoading(false)
      }
    },
  })

  const userId = localStorage.getItem("id")
  // const userId = JSON.parse(id)
  // console.log("Id", userId)

  useEffect(() => {
    async function fetchCategoryData() {
      if (userId) {
        await dispatch(getPricingCategory({ vetId: userId }))
      }
    }
    fetchCategoryData()
  }, [dispatch])
  console.log("AllCategories", allCategories)

  const ConsultationNameOptions = [
    { value: "Primary Care", label: "Primary Care" },
    { value: "Urgent Care", label: "Urgent Care" },
    { value: "Virtual Care", label: "Virtual Care" },
    { value: "Vaccination", label: "Vaccination" },
  ]

  const [pricingArray, setPricingArray] = useState([])
  const appointmentTypes = [
    {
      value: "audio",
      label: "Audio",
    },
    { value: "video", label: "Video" },
    { value: "visit", label: "Visit" },
  ]
  const handleAddPrice = () => {
    // if (validation.values.consultationType && validation.values.price) {
    //   const newPrice = {
    //     name: validation.values.consultationType.value,
    //     price: validation.values.price,
    //   }
    //   if (!validation.values.price || validation.values.price.trim() === "") {
    //     showMessage({
    //       label: "Please enter a price.",
    //       status: "warning",
    //     })
    //     return
    //   }
    //   // Check if the entry already exists
    //   if (pricingArray.some(entry => entry.name === newPrice.name)) {
    //     showMessage({
    //       label: "This consultation type already exists in the list.",
    //       status: "warning",
    //     })
    //   } else {
    //     setPricingArray([...pricingArray, newPrice])
    //   }

    //   // Clear the input fields
    //   validation.setFieldValue("consultationType", null)
    //   validation.setFieldValue("price", "")
    // }
    if (!validation.values.consultationType) {
      showMessage({
        label: "Please select a consultation type.",
        status: "warning",
      })
      return
    }

    if (!validation.values.price || validation.values.price === "") {
      showMessage({
        label: "Please enter a price.",
        status: "warning",
      })
      return
    }

    const newPrice = {
      name: validation.values.consultationType.value,
      price: validation.values.price,
    }

    if (pricingArray.some(entry => entry.name === newPrice.name)) {
      showMessage({
        label: "This consultation type already exists in the list.",
        status: "warning",
      })
    } else {
      setPricingArray([...pricingArray, newPrice])
      setSelectedConsultationTypes([
        ...selectedConsultationTypes,
        newPrice.name,
      ])
      validation.setFieldValue("price", "")
      validation.setFieldValue("consultationType", null) // Clear the consultation type field after adding
    }
  }
  const handleRemovePrice = index => {
    const updatedPricingArray = [...pricingArray]
    // updatedPricingArray.splice(index, 1)
    // setPricingArray(updatedPricingArray)
    const removedConsultationType = updatedPricingArray[index].name
    updatedPricingArray.splice(index, 1)
    setPricingArray(updatedPricingArray)
    setSelectedConsultationTypes(
      selectedConsultationTypes.filter(type => type !== removedConsultationType)
    )
  }
  console.log("ConultationArray", pricingArray)
  useEffect(() => {
    // Update the "appointmentType" field value in the validation object
    validation.setFieldValue("appointmentType", selectedAAppointmentType, true)
  }, [selectedAAppointmentType])

  const filteredConsultationOptions = ConsultationNameOptions.filter(
    option => !selectedConsultationTypes.includes(option.value)
  )

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={5}>
              <div className="auth-full-page-content p-md-5 p-4 vh-100">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 onboarding-logo-container">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img src={logodark} alt="" className="auth-logo-dark" />
                        <img
                          src={logolight}
                          alt=""
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div style={{ padding: "0px 20%" }}>
                      <div>
                        <Stepper active={4} />
                        {/* <p className="font-fontFamily fontSize-16 font-color-purple">
                          Step 4
                        </p> */}
                        <h1>Add your clinic pricing</h1>
                        <p className="text-muted">
                          Start using askforvets for your vet clinic management.
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <Row>
                            <div className="mb-3">
                              <ReactSelect
                                classNamePrefix="form-react-select"
                                className="form-react-select"
                                placeholder="Consultation Type"
                                options={filteredConsultationOptions}
                                onChange={value =>
                                  validation.setFieldValue(
                                    "consultationType",
                                    value
                                  )
                                }
                                name="consultationType"
                                value={validation.values.consultationType}
                              />
                            </div>
                            <Row>
                              <Col xs={9}>
                                <div>
                                  <Input
                                    name="price"
                                    // value={name}
                                    className="form-control"
                                    placeholder="Enter Price"
                                    type="number"
                                    onChange={validation.handleChange}
                                    value={validation.values.price || ""}
                                  />
                                </div>
                              </Col>
                              <Col xs={3}>
                                <button
                                  style={{ padding: "0px 24px" }}
                                  type="button"
                                  onClick={handleAddPrice}
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  +Add
                                </button>
                              </Col>
                            </Row>
                          </Row>

                          <div className="d-flex gap-2 flex-column">
                            {pricingArray?.map((ele, index) => {
                              return (
                                <div
                                  style={{
                                    background: "#EDEDED",
                                    borderRadius: "8px",
                                    padding: "4px 10px",
                                    height: "50px",
                                    marginTop: "10px",
                                  }}
                                  key={index}
                                  className="d-flex justify-content-between align-items-center gap-2"
                                >
                                  <div className="font-fontFamily fontSize-16">
                                    {ele.name}-{ele.price}
                                  </div>
                                  <div
                                    onClick={() => handleRemovePrice()}
                                    role="button"
                                  >
                                    X
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                          <Row className="mt-4">
                            <div>
                              <h1>Add Appointment Types</h1>
                            </div>
                            <div className="mt-2">
                              <ReactSelect
                                isMulti
                                value={selectedAAppointmentType}
                                onChange={setAppointmentType}
                                options={appointmentTypes}
                                classNamePrefix="form-react-select"
                                className="form-react-select"
                                closeMenuOnSelect={false}
                              />
                            </div>
                          </Row>
                          <div className="mt-4 d-grid col-lg-12">
                            <ButtonMain
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                              isLoading={isLoading}
                            >
                              Sign Up
                            </ButtonMain>
                          </div>
                        </Form>

                        <Form action="dashboard">
                          <div className="text-center">
                            {/* <hr data-text="OR" /> */}

                            {/* <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="auth-google"
                                  onClick={renderProps.onClick}
                                >
                                  <img src={googleIcon} /> Sign in with Google
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => {}}
                            /> */}
                          </div>
                        </Form>

                        <div className="mt-5 text-center">
                          <p>
                            Already have an account ?{" "}
                            <Link to="login" className="fw-medium text-primary">
                              {" "}
                              Login
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage
              landingPage={landingImage}
              imageTitle="Front desk & finance"
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RegisterStep4
