import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import ReactSelect from "react-select";
import "@fullcalendar/bootstrap/main.css";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  getUserDetails,
  setGlobalLoading,
  getCategoryOfPricing,
  getPricingCategory,
  getCategoryOfGenericType,
  getGenericCategory,
} from "store/slices";
import { BillingName } from "pages/Pets/components/PatientsCol";
import { deleteCategory } from "helpers/api_helper";
import { IconSVG } from "components/Common/IconSvg";
import DeleteModal from "components/Common/DeleteModal";
import AddCategoryModal from "./AddCategory";
import UpdateModal from "./UpdateCategory";
import "../../assets/scss/custom/pages/_categoryPage.scss";
import LeftArrow from "../../assets/icons/ArrowLeft.png";

const PricingCategory = (props) => {
  document.title = "Administration | Supaw";

  const dispatch = useDispatch();

  const [event, setEvent] = useState({});
  const [currentSelected, setCurrentSelected] = useState(-1);
  const [selectedOption, setSelectedOption] = useState({
    value: "PriceCategories",
    label: "Pricing Category",
  });

  const selectOptions = [
    { value: "PriceCategories", label: "Pricing Category" },
  ];

  const categories = useSelector(getCategoryOfPricing) || [];
  const genericCategories = useSelector(getCategoryOfGenericType);
  const userDetails = useSelector(getUserDetails);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const history = useHistory();

  useEffect(() => {
    async function fetchCategoryData() {
      if (userDetails?._id) {
        dispatch(setGlobalLoading(true));
        await dispatch(getPricingCategory({ vetId: userDetails?._id }));
        dispatch(setGlobalLoading(false));
      }
    }
    fetchCategoryData();
  }, [dispatch]);

  useEffect(() => {
    async function fetchGenericCategoryData() {
      if (userDetails?._id) {
        dispatch(setGlobalLoading(true));
        await dispatch(getGenericCategory(userDetails?._id));
        dispatch(setGlobalLoading(false));
      }
    }
    fetchGenericCategoryData();
  }, [dispatch]);

  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const handleAddCategoryModal = async () => {
    setCategoryModalOpen(true);
  };

  const handleDeleteEvent = async (id) => {
    console.log("iddeleted", id);
    try {
      setIsDeleting(true);
      const res = await deleteCategory(id);
      if (selectedOption.value === "PriceCategories") {
        dispatch(getPricingCategory({ vetId: userDetails?._id }));
      } else {
        dispatch(getGenericCategory(userDetails?._id));
        console.log("selectedOption5", selectedOption);
      }
      if (res.status == 1) {
        dispatch(getPricingCategory());
      }
      setIsDeleting(false);
      setDeleteModal(false);
    } catch (error) {
      setIsDeleting(false);
      setDeleteModal(false);
    }
  };

  const handleEventClick = (arg) => {
    history.push(`/edit-appointment/${arg.event._def.publicId}`);
  };

  const onClickDelete = (arg) => {
    console.log("arg???", arg);
    setCategoryToDelete(arg);
    setDeleteModal(true);
  };

  const onConfirmDelete = () => {
    if (categoryToDelete) {
      handleDeleteEvent(categoryToDelete);
    }
  };

  const onClickUpdate = (arg) => {
    setCurrentSelected(arg._id);
    setUpdateModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Category Name",
        accessor: "pricingCategoryName",
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {
          const rowValue = cellProps.row.original;
          const showEdit =
            rowValue.pricingCategoryName === "Imaging Pricing" ||
            rowValue.pricingCategoryName === "Lab Pricing" ||
            rowValue.pricingCategoryName === "Consultation Pricing" ||
            rowValue.pricingCategoryName === "Procedure Pricing";
          return (
            <div className="d-flex gap-3 justify-content-end">
              {showEdit ? (
                <>
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      const appointmentData = cellProps.row.original._id;
                      console.log(
                        "cellProps.row.original",
                        cellProps.row.original
                      );
                      onClickDelete(appointmentData);
                    }}
                  >
                    <i id="deletetooltip">
                      <IconSVG icon="delete" />
                    </i>
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  console.log("genericCategory", genericCategories);
  const [open, setOpen] = useState(false);
  const handleToggleDrawer = () => {
    setOpen(!open);
  };
  const handleCloseDrawer = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <AddCategoryModal open={open} handleCloseDrawer={handleCloseDrawer} />
      <DeleteModal
        show={deleteModal}
        isDeleting={isDeleting}
        onDeleteClick={onConfirmDelete}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <Container fluid={true}>
          <Row className="mb-4 ">
            <Col className="d-flex align-items-center justify-content-start gap-2">
              <img
                src={LeftArrow}
                width={36}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-36">
                Pricing Categories
              </h4>
            </Col>

            <Col className="d-flex justify-content-end mt-4">
              <div className="create-link">
                <Link
                  color="primary"
                  className="font-16 btn-block font-fontFamily"
                  to={"#"}
                  onClick={() => handleToggleDrawer()}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Add New Categories
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row className="mt-20">
                {selectedOption.value === "PriceCategories" ? (
                  <TableContainer
                    columns={columns}
                    data={categories || []}
                    isGlobalFilter={false}
                    isAddOptions={true}
                    emptyText="No Appointments found"
                    emptyLinkText={
                      <div className="react-select-wrapper">
                        <ReactSelect
                          options={selectOptions}
                          value={selectedOption}
                          onChange={setSelectedOption}
                        />
                      </div>
                    }
                    customPageSize={10}
                  />
                ) : (
                  <TableContainer
                    className="priceCategoryTable"
                    columns={columns}
                    data={genericCategories || []}
                    isGlobalFilter={false}
                    isAddOptions={true}
                    emptyText="No Appointments found"
                    emptyLinkText={
                      <div className="react-select-wrapper">
                        <ReactSelect
                          options={selectOptions}
                          value={selectedOption}
                          onChange={setSelectedOption}
                        />
                      </div>
                    }
                    customPageSize={10}
                  />
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

PricingCategory.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default PricingCategory;
