import ButtonMain from "components/Common/Button"
import {
  addImagingRequest,
  addNewPetParent,
  BASE,
  getAllPetsByParentId,
  getImagingAndLabsBasedOnId,
  getParentById,
  updateImagingRequest,
  updatePatient,
  uploadAttachment,
} from "helpers/api_helper"
import {
  modifyAppointmentType,
  modifyParentType,
  modifyTestType,
  modifyTestTypeOptions,
  validateEmail,
  validatePhone,
} from "helpers/utils"
import * as Yup from "yup"
import { useFormik } from "formik"

import { use } from "i18next"
import React, { useEffect, useReducer, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Alert,
  FormFeedback,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { showMessage } from "components/Notification"
import ReactSelect from "react-select"
import {
  getAllParents,
  getParentList,
  getpricing,
  getPricingDetails,
  getUserDetails,
  setGlobalLoading,
} from "store/slices"
import { useDispatch, useSelector } from "react-redux"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
const CreatePatient = () => {
  //meta title
  document.title = "Imaging Request"
  // const appointmentType = useSelector(getServices)
  const [isLoading, setIsLoading] = useState(false)
  const [details, setDetails] = useState({})

  const [imagingName, setImagingName] = useState(null)
  const [imagingType, setImagingType] = useState()
  const [selectedPet, setSelectedPet] = useState({})
  const [allPets, setAllPets] = useState([])
  const [currentPet, setCurrentSelectedPet] = useState({})
  const [isFetchingPets, setIsFetchingPets] = useState(false)
  const [addPet, setAddPet] = useState(false)
  const parentsList = useSelector(getAllParents)
  const userDetails = useSelector(getUserDetails)
  const [pricing, setPricing] = useState(null)
  const [selectedParent, setSelectedParent] = useState({})

  const [modal, setModal] = useState(false)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [selectedStatus, setStatus] = useState({
    label: "Requested",
    value: "Requested",
  })

  const [selectedFiles, setselectedFiles] = useState([])
  const [ImageUrls, setImageUrl] = useState([])
  const { imagingId } = useParams()
  console.log("imagingId????", imagingId)

  const dispatch = useDispatch()
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      type: details?.type || "",
      notes: details?.notes || "",
      radiologist: details?.radiologist || "",
      result: details?.result || "",
      name: details?.name || "",
    },
    validationSchema: Yup.object({
      // type: Yup.string().required("Please Enter imaging type"),
      radiologist: Yup.string().required("Please Enter radiologist"),
      // notes: Yup.string().required("Please Enter Notes"),
      // xpecto devloper 13/8/2024
      ...(imagingId
        ? {
            result: Yup.string()
              .required("Please Enter Result")
              .test(
                "check-result",
                "Please Fill all details",
                function (value) {
                  if (!value) {
                    showMessage({
                      label: "Please Fill all details",
                      status: "info",
                    })
                    return false
                  }
                  return true
                }
              ),
          }
        : {}),
      // xpecto devloper 13/8/2024
    }),
    onSubmit: async values => {
      if (!currentPet._id) {
        showMessage({
          label: "Please Select Pet Parent and Pet",
          status: "info",
        })
        return
      }
      if (!imagingType) {
        showMessage({ label: "Please Select Test Type", status: "info" })
        return
      }
      if (!imagingName) {
        showMessage({ label: "Please Select Imaging Name", status: "info" })
        return
      }
      if (!values.notes) {
        showMessage({
          label: `Please add notes`,
          status: "warning",
        })
        return
      }
      if (imagingId) {
        if (ImageUrls.length === 0) {
          showMessage({ label: "Please Select Image", status: "warning" })
          return
        }
      }
      const obj = {
        ...values,
        status: "Requested",
        scansOrReports: ImageUrls,
        testType: "imaging",
        type: imagingType.value,
        name: imagingName.value,
        pricing: pricing,
      }
      obj.petObjectId = selectedPet.value
      obj.parentId = selectedParent.value || petObjectId
      obj.doctorId = userDetails._id
      if (imagingId) {
        obj.reportId = imagingId
      }
      handleSubmit(obj)
      console.log(obj)
    },
  })

  const history = useHistory()
  useEffect(() => {
    async function fetchData() {
      if (!imagingId) return
      dispatch(setGlobalLoading(true))
      const _detail = await getImagingAndLabsBasedOnId({
        reportId: imagingId,
        // type: "imaging",s
      })
      setDetails(_detail.data)
      console.log("ImagingTests", details)
      dispatch(setGlobalLoading(true))
      setTimeout(() => {
        setSelectedParent({
          label: _detail?.data.parentId?.fullName,
          value:
            _detail?.data.parentId?._id || _detail?.data.petObjectId?.parentId,
        })
        setSelectedPet({
          label: _detail?.data.petObjectId?.fullName,
          value: _detail?.data.petObjectId?._id,
        })
        setImagingType({
          label: _detail?.data.type,
          value: _detail?.data.type,
        })
        setImagingName({
          label: _detail?.data.name,
          value: _detail?.data.name,
        })
        setCurrentSelectedPet({ ..._detail?.data.petObjectId })
        setPricing(_detail?.data.pricing)
        dispatch(setGlobalLoading(false))
      }, 1000)
    }
    fetchData()
  }, [imagingId])

  const handleSubmit = async values => {
    console.log(values)
    // return
    try {
      setIsLoading(true)
      let res
      if (!imagingId) {
        res = await addImagingRequest(values)
      } else {
        res = await updateImagingRequest({ ...values, status: "Completed" })
        // console.log("values",values)
      }

      if (res.status === 1) {
        showMessage({ label: res.message, status: "success" })
        history.push(imagingId ? "/imaging-completed" : "/imaging-requests")
      } else {
        showMessage({
          label: res.message || "something went wrong",
          status: "error",
        })
      }
      setIsLoading(false)
    } catch (error) {
      showMessage({
        label: error.response.data.message || "something went wrong",
        status: "error",
      })
      setIsLoading(false)
    }
  }
  const handleSelectedParent = async item => {
    setSelectedParent(item)
    setIsFetchingPets(true)
    const response = await getAllPetsByParentId(item.value)
    if (response.status == 1) {
      if (response.data?.length) {
        setAllPets(response.data)
        setIsFetchingPets(false)
        setAddPet(false)
      } else {
        showMessage({
          label: `No pets found for ${item.label} parent`,
          status: "info",
        })
        setAddPet(true)
        setAllPets([])
      }
    }
    setSelectedPet(null)
    setIsFetchingPets(false)
    return response.data
  }
  const handleSelectedPet = async (item, petlist = null) => {
    setSelectedPet(item)
    const selected = [...(petlist?.length ? petlist : allPets)].find(
      ele => ele._id === item.value
    )
    setCurrentSelectedPet(selected)
  }

  useEffect(() => {
    async function newLoad() {
      dispatch(setGlobalLoading(true))
      await dispatch(getParentList({ doctorId: userDetails._id }))
      dispatch(setGlobalLoading(false))
    }
    newLoad()
  }, [dispatch])

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const handleStatus = item => {
    setStatus(item)
    validation.handleChange(item.label)
  }
  const handleUploadScans = async event => {
    try {
      setIsFileUploading(true)
      const file = event.target.files[0]

      if (!file) {
        showMessage({ label: "Please select a file", status: "error" })
        setIsFileUploading(false)
        return
      }

      // Add file type check here
      const allowedFileTypes = [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/svg",
      ]
      if (!allowedFileTypes.includes(file.type)) {
        showMessage({ label: "Invalid file type", status: "error" })
        setIsFileUploading(false)
        return
      }
      const formData = new FormData()

      formData.append("files", event.target.files[0])
      const response = await uploadAttachment(formData)
      if (response.status === 1) {
        showMessage({
          label: `Uploaded Successfully! Please press confirm  to continue`,
          status: "success",
        })
        const imageUrl = [...ImageUrls]
        imageUrl.push(response.data[0])
        setImageUrl(imageUrl)
      }

      setIsFileUploading(false)
    } catch (error) {
      console.log(error)
      setIsFileUploading(false)
      showMessage({ label: "Something went wrong!", status: "error" })
    }
  }
  const FileUpload = () => {
    return (
      <div className="file-upload-container mb-4">
        {ImageUrls?.length
          ? ImageUrls.map((item, index) => (
              <div key={index} className="file-upload-image-container">
                <div
                  className="file-upload-image-delete"
                  onClick={() => {
                    const imageUrl = [...ImageUrls]
                    imageUrl.splice(index, 1)
                    setImageUrl(imageUrl)
                  }}
                >
                  X
                </div>
                <img className="file-upload-image" src={item} />
              </div>
            ))
          : null}
        <div className="d-flex align-items-center">
          <div className="file-upload-square">
            +<br />
            Upload Scan
            <input
              type="file"
              onChange={handleUploadScans}
              accept="image/*"
              className="form-control"
            />
          </div>
          <p className="upload-instructions">
            Allowed file types: JPG,JPEG, PNG
          </p>
        </div>
      </div>
    )
  }

  useEffect(() => {
    async function fetchPricingData() {
      if (userDetails?._id) {
        dispatch(setGlobalLoading(true))
        await dispatch(getPricingDetails({ vetId: userDetails?._id }))
        dispatch(setGlobalLoading(false))
      }
    }
    fetchPricingData()
  }, [dispatch])
  // const ImagingTestTypeOptions = [
  //   {
  //     value: "Radiography",
  //     label: "Radiography",
  //   },
  //   { value: "Ultrasonography", label: "Ultrasonography" },
  //   { value: "Nuclear Medicine", label: "Nuclear Medicine" },
  //   { value: "Diagnostic Procedures", label: "Diagnostic Procedures" },
  // ]

  const dummyAppointment = useSelector(getpricing)
  console.log("dummyAppointment", dummyAppointment)
  const filterPricingOptions = dummyAppointment
    .filter(ele => ele.category.pricingCategoryName === "Imaging Pricing")
    .filter(ele => ele.testingType === imagingType?.value)
  console.log("filterPricingoptions", filterPricingOptions)

  const ImagingTestTypeOptions1 = dummyAppointment
    .filter(ele => ele.category.pricingCategoryName === "Imaging Pricing")
    .filter((obj, index, array) => {
      const firstIndex = array.findIndex(
        item => item.testingType === obj.testingType
      )
      return index === firstIndex
    })
  const ImagingTestTypeOptions = modifyTestTypeOptions(ImagingTestTypeOptions1)
  // console.log("IM",im)

  const handleImagingTypeMenuOpen = () => {
    setTimeout(() => {
      if (ImagingTestTypeOptions.length === 0) {
        showMessage({
          label: "No Tests Available! Need to Create First",
          status: "info",
        })
      }
    }, 1000)
  }

  const handleParentListMenuOpen = () => {
    setTimeout(() => {
      if (parentsList.length === 0) {
        showMessage({
          label: "No Parents Available! Need to Create First",
          status: "info",
        })
      }
    }, 1000)
  }
  const handleTestNameChange = selectedOption => {
    setImagingName(selectedOption)
    setPricing(selectedOption?.pricing)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="mx-2 mb-4 ">
            <Col className="d-flex align-items-center justify-content-start gap-2">
              <img
                src={LeftArrow}
                width={36}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-36">
                Imaging Requests
              </h4>
            </Col>

            <Col className="d-flex align-items-center justify-content-between gap-4"></Col>
            {/* <div className="create-link">
              <Link
                color="primary"
                className="font-16 btn-block"
                to={"/add-appointment"}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New Appointment
              </Link>
            </div> */}
          </Row>
          {/* <Breadcrumbs
            title="Imaging"
            titleLink="/imaging-requests"
            breadcrumbItem={"Imaging Request"}
          /> */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Pet parent details</CardTitle> */}
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    {/* <Row> */}
                    <Row className="justify-content-end mt-8">
                      <Col lg={5}>
                        <FormGroup className="row mb-9">
                          <Label className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400">
                            Pet Parent
                          </Label>
                          <div className="col-lg-7">
                            <ReactSelect
                              value={selectedParent}
                              onChange={handleSelectedParent}
                              isDisabled={!!imagingId}
                              options={modifyParentType(parentsList)}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                              onMenuOpen={handleParentListMenuOpen}
                            />
                          </div>
                        </FormGroup>
                        {addPet && (
                          <div className="history-doc-cta mb-4 ">
                            <Link
                              className="btn btn-primary"
                              to={`/add-pet/parent/${selectedParent?.value}`}
                            >
                              + Add Pet
                            </Link>
                          </div>
                        )}
                        <FormGroup className="row mb-9">
                          <Label className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400">
                            Pet
                          </Label>
                          <div className="col-lg-7">
                            <ReactSelect
                              value={selectedPet}
                              isDisabled={!allPets.length || isFetchingPets}
                              onChange={handleSelectedPet}
                              options={modifyAppointmentType(allPets)}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup className="row mb-9">
                          <Label className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400">
                            Imaging Type
                          </Label>
                          <div className="col-lg-7">
                            <ReactSelect
                              name="type"
                              value={imagingType}
                              onChange={setImagingType}
                              // isDisabled={!!labId}
                              options={ImagingTestTypeOptions}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                              onMenuOpen={handleImagingTypeMenuOpen}
                            />
                          </div>
                        </FormGroup>

                        <FormGroup className="row mb-9">
                          <Label className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400">
                            Select Imaging
                          </Label>
                          <div className="col-lg-7">
                            <ReactSelect
                              name="imagingName"
                              value={imagingName}
                              onChange={handleTestNameChange}
                              // isDisabled={!!labId}
                              options={modifyTestType(filterPricingOptions)}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                            />
                          </div>
                        </FormGroup>
                        {/* <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-6  text-right text-input-label">
                            Imaging Type
                          </Label>
                          <div className="col-lg-6">
                            <Input
                              id="projectname"
                              name="type"
                              type="text"
                              className="form-control"
                              placeholder="Imaging Type"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.type || ""}
                              invalid={
                                validation.touched.type &&
                                validation.errors.type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.type &&
                            validation.errors.type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup> */}

                        <FormGroup className="mb-4 row">
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400"
                          >
                            Radiologist
                          </Label>
                          <div className="col-lg-7">
                            <Input
                              id="radiologist"
                              name="radiologist"
                              type="text"
                              className="form-control"
                              placeholder="Radiologist"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.radiologist || ""}
                              invalid={
                                validation.touched.radiologist &&
                                validation.errors.radiologist
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.radiologist &&
                            validation.errors.radiologist ? (
                              <FormFeedback type="invalid">
                                {validation.errors.radiologist}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                        {imagingId && (
                          <FormGroup className="mb-4 row">
                            <Label
                              htmlFor="projectdesc"
                              className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400"
                            >
                              Result
                            </Label>
                            <div className="col-lg-7">
                              <Input
                                id="result"
                                name="result"
                                type="text"
                                className="form-control"
                                placeholder="Result"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.result || ""}
                                invalid={
                                  validation.touched.result &&
                                  validation.errors.result
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.result &&
                              validation.errors.result ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.result}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </FormGroup>
                        )}
                      </Col>
                    </Row>
                    {imagingId && (
                      <Row className="justify-content-end mt-8 mb-4">
                        <Col lg={10}>
                          <Label className="col-form-label text-input-label font-fontFamily-400">
                            Add Scans
                          </Label>
                          <Form>
                            <FileUpload />
                          </Form>
                        </Col>
                      </Row>
                    )}
                    <Row className="justify-content-end mt-4">
                      <Col lg="12">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-3 text-right text-input-label font-fontFamily-400"
                          >
                            Notes
                          </Label>
                          <div className="col-lg-9">
                            <textarea
                              className="form-control"
                              id="projectdesc"
                              rows="8"
                              name="notes"
                              style={{ resize: "none", minHeight: 200 }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.notes || ""}
                              placeholder="Notes"
                            />
                            {validation.touched.notes &&
                            validation.errors.notes ? (
                              <FormFeedback type="invalid">
                                {validation.errors.notes}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-4  justify-content-end " row>
                          <span className="gray-note">
                            To Pay :
                            <span className="gray-note--primary">
                              INR {pricing}
                            </span>
                          </span>
                        </FormGroup>
                        <div className="d-flex justify-content-end">
                          <ButtonMain
                            isLoading={isLoading}
                            type="submit"
                            className="btn btn-primary"
                          >
                            {imagingId ? "Complete" : "Create Request"}
                          </ButtonMain>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreatePatient
