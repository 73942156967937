import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

import { Col, Container, Row, UncontrolledTooltip } from "reactstrap"
import TableContainer from "../../components/Common/TableContainer"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Line } from "react-chartjs-2"
import { Bar } from "react-chartjs-2"
import Chart from "chart.js/auto"
// import {
//   Chart as ChartJS,
//   LineElement,
//   BarElement,
//   CategoryScale,
//   LinearScale,
//   PointElement,
// } from "chart.js"
//import Images

import DeleteModal from "../Appointments/DeleteModal"

//css
import "@fullcalendar/bootstrap/main.css"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
  getTodaysAppointments,
  getAppointmentsOfDoc,
  getAppointmentsByDocId,
  getUserDetails,
  getWeeklyRevenue,
  getWeeklyPatientCount,
  getAllWeeklyRevenue,
  getAllWeeklyPatientCount,
  getyearlyRevenueCount,
  getAllYearlyRevenue,
  setGlobalLoading,
} from "store/slices"
import { modifyEventsData } from "helpers/utils"
import { BillingName, Time, Total } from "pages/Pets/components/PatientsCol"
import { deleteAppointment } from "helpers/api_helper"
import { IconSVG } from "components/Common/IconSvg"
import "../../assets/scss/custom/pages/_dashboard.scss"
import navigateForword from "../../assets/icons/navigate-forword.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

// ChartJS.register(
//   LineElement,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   BarElement
// )

const Appointments = props => {
  //meta title
  document.title = "Appointments | Supaw"
  const history = useHistory()
  const dispatch = useDispatch()

  const [currentSelected, setCurrentSelected] = useState(-1)

  // events validation

  const appointments = useSelector(getAppointmentsOfDoc) || []
  const userDetails = useSelector(getUserDetails)
  const weeklyRevenue = useSelector(getAllWeeklyRevenue)
  const weeklyPatientCount = useSelector(getAllWeeklyPatientCount)
  const yearlyRevenuArray = useSelector(getAllYearlyRevenue)
  const [deleteModal, setDeleteModal] = useState(false)
  const [isDeleting, setIsdeleting] = useState(false)
  const weeklyRevenuePercent = weeklyRevenue?.percentageChange
  const weeklyPatientPercent = weeklyPatientCount?.percentage
  console.log("WeeklypatientPercent:", weeklyPatientPercent)
  useEffect(() => {
    async function fetchData() {
      if (userDetails?._id) {
        dispatch(setGlobalLoading(true))
        await dispatch(getAppointmentsByDocId(userDetails?._id))
        dispatch(getWeeklyRevenue({ vetId: userDetails._id }))
        dispatch(getWeeklyPatientCount({ vetId: userDetails._id }))
        dispatch(getyearlyRevenueCount({ vetId: userDetails._id }))
        dispatch(setGlobalLoading(false))
      }
    }
    fetchData()
  }, [dispatch])
  /**
   * Handling click on event on calendar
   */

  const handleDeleteEvent = async () => {
    try {
      setIsdeleting(true)
      const res = await deleteAppointment(currentSelected)
      if (res.status == 1) {
        dispatch(getTodaysAppointments())
      }
      setIsdeleting(false)
      setDeleteModal(false)
    } catch (error) {
      setIsdeleting(false)
      setDeleteModal(false)
    }
  }
  const handleDateClick = arg => {}
  /**
   * On delete event
   */
  const handleEventClick = arg => {}

  /**
   * On delete event
   */

  const onClickDelete = arg => {
    setCurrentSelected(arg._id)
    setDeleteModal(true)
  }
  const Parent = cell => {
    return (
      <Link to={`/parent/${cell.value}`}>{cell.value ? cell.value : ""}</Link>
    )
  }
  const DateModified = cell => {
    if (cell.value) {
      const datePart = cell.value.split("T")[0]
      return datePart
    }
    return ""
  }
  const columns = useMemo(
    () => [
      {
        Header: "Pets Name",
        accessor: "petObjectId.fullName",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Pet Parent",
        accessor: "parentId.fullName",
        filterable: true,
        Cell: cellProps => {
          return <Parent {...cellProps} />
        },
      },
      {
        Header: "Breed",
        accessor: "petObjectId.petBreed",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Date",
        accessor: "date",
        filterable: true,
        Cell: cellProps => {
          return <DateModified {...cellProps} />
        },
      },
      {
        Header: "Start time",
        accessor: "time",
        filterable: true,
        Cell: cellProps => {
          return <Time {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "visitType",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3 justify-content-end">
              {cellProps.row.original.status === "scheduled" &&
              cellProps.row.original.status !== "completed" ? (
                <Link
                  to={`/edit-appointment/${cellProps.row.original._id}`}
                  className="text-info"
                >
                  <i id="edit1tooltip">
                    <IconSVG icon="edit" />
                  </i>
                  <UncontrolledTooltip placement="top" target="edit1tooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              ) : null}
              {cellProps.row.original.status === "completed" && (
                <Link
                  to={`/prescription-details/${cellProps.row.original._id}`}
                  className="text-success"
                >
                  <i id="prec">
                    <IconSVG icon="more" />
                  </i>
                  <UncontrolledTooltip placement="top" target="prec">
                    Prescription
                  </UncontrolledTooltip>
                </Link>
              )}
              {cellProps.row.original.status !== "completed" &&
                cellProps.row.original.status !== "cancelled" && (
                  <Link
                    to={`${
                      cellProps.row.original.status === "checkIn"
                        ? "prescription"
                        : "checkin"
                    }/${cellProps.row.original._id}`}
                    className="text-success"
                  >
                    <i id="edittooltip">
                      <IconSVG icon="checkin" />
                    </i>
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      {cellProps.row.original.status === "checkIn"
                        ? "Prescription"
                        : "Checkin"}
                    </UncontrolledTooltip>
                  </Link>
                )}

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const appointmentData = cellProps.row.original
                  onClickDelete(appointmentData)
                }}
              >
                <i id="deletetooltip">
                  <IconSVG icon="delete" />
                </i>
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  function getFormattedDateWithSuffix(day) {
    let suffix
    if (day >= 11 && day <= 13) {
      suffix = "th"
    } else {
      const lastDigit = day % 10
      switch (lastDigit) {
        case 1:
          suffix = "st"
          break
        case 2:
          suffix = "nd"
          break
        case 3:
          suffix = "rd"
          break
        default:
          suffix = "th"
          break
      }
    }

    return `${day}${suffix}`
  }

  const currentDate = new Date()
  const todayDay = currentDate.getDate()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()

  const prices = yearlyRevenuArray.map(item => item.price)
  console.log("todaysDate", todayDay)
  console.log("currentMonth", monthNames[currentMonth])
  console.log("currentYear", currentYear)
  console.log("CurrentDate", currentDate)
  console.log("Weekly Revenue", weeklyRevenue)
  console.log("Weekly patient Count", weeklyPatientCount)
  console.log("yearlyRevenue", yearlyRevenuArray)
  console.log("yearlyIncome", prices)
  console.log("appointment:", appointments)
  const upcomingAppointments = appointments.filter(
    ele => ele.status === "scheduled"
  )
  const boxStyle = {
    boxShadow: "0px 8px 32px 0px #3326AE14",
    height: "112px",
    width: "442px",
  }
  const boxStyle2 = {
    boxShadow: "0px 8px 32px 0px #3326AE14",
  }
  const data = {
    labels: ["Mon", "Tue", "Wed"],
    datasets: [
      {
        data: [5, 6, 5],
        backgroundColor: "#652B88",
        borderColor: "#652B88",
        pointBorderColor: "#652B88",
        fill: false,
        tension: 0.4,
      },
    ],
  }

  const options = {
    plugins: {
      legend: false,
      title: {
        display: false, // this hides the legend
      },
      fullSize: true,
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
  }

  const data2 = {
    labels: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
    datasets: [
      {
        label: "Monthly Sales",
        data: prices,

        backgroundColor: "#66AE45",
        borderColor: "#66AE45",
        barThickness: 20,
        borderRadius: 10,
        categoryPercentage: 1.0,
        barPercentage: 0.8,
        fill: false,
      },
    ],
  }

  const options2 = {
    plugins: {
      legend: false,
      title: {
        display: false, // this hides the legend
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // this hides the x-axis grid lines
        },
        ticks: {
          display: true, // this shows the x-axis ticks
        },
      },
      y: {
        grid: {
          display: false, // this hides the y-axis grid lines
        },
        ticks: {
          display: true, // this shows the y-axis ticks
        },
      },
    },
  }
  const orangeStyle = {
    color: "#E75C25",
  }
  const purpleStyle = {
    color: "#652B88",
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        isDeleting={isDeleting}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <Container fluid={true}>
          <Row
            className="d-flex justify-content-between align-items-center"
            style={{ marginBottom: "75px" }}
          >
            <div className="w-auto">
              <h4 className="font-fontFamily fontSize-36">
                Business Dashboard
              </h4>
            </div>
            <div className="w-auto ">
              <h4 className="font-fontFamily fontSize-14">
                as of {getFormattedDateWithSuffix(todayDay)}{" "}
                {monthNames[currentMonth]} {currentYear}
              </h4>
            </div>
          </Row>
          {/* Render Breadcrumb */}

          <Row style={{ marginBottom: "75px" }}>
            <Col xs="12" md="6" className="d-flex mb-3 mb-md-0">
              <div className="w-100 d-flex p-2 box-radius-12" style={boxStyle}>
                <div className="w-50 d-flex flex-column align-items-start justify-content-between py-2 px-2">
                  <h4
                    style={
                      weeklyRevenue?.percentageChange >= 0
                        ? purpleStyle
                        : orangeStyle
                    }
                    className="font-fontFamily fontSize-16"
                  >
                    Weekly Revenue
                  </h4>
                  <h4 className="font-fontFamily fontSize-24">
                    ₹ {weeklyRevenue.totalCurrentWeekRevenue}
                  </h4>
                </div>
                <div
                  className="flex-grow-1 ml-md-4 py-2 px-2"
                  style={{ width: "320px" }}
                >
                  <Line data={data} options={options}></Line>
                </div>
                <div className="py-2 px-2 ">
                  <h4
                    style={purpleStyle}
                    className="font-fontFamily fontSize-24"
                  >
                    {weeklyRevenuePercent === "NaN%" || "Infinity%"
                      ? "0%"
                      : weeklyRevenuePercent}
                  </h4>
                </div>
              </div>
            </Col>
            <Col xs="12" md="6" className="d-flex">
              <div className="w-100 d-flex p-2 box-radius-12" style={boxStyle}>
                <div className="w-50 d-flex flex-column align-items-start justify-content-between py-2 px-2">
                  <h4
                    style={orangeStyle}
                    className="font-fontFamily fontSize-16"
                  >
                    Weekly Patients
                  </h4>
                  <h4 className="font-fontFamily fontSize-24">
                    {weeklyPatientCount.currentRevenue}
                  </h4>
                </div>
                <div
                  className="flex-grow-1 ml-md-4 py-2 px-2"
                  style={{ width: "320px" }}
                >
                  <Line data={data} options={options}></Line>
                </div>
                <div className="py-2 px-2">
                  <h4
                    style={orangeStyle}
                    className="font-fontFamily fontSize-24"
                  >
                    {weeklyPatientPercent === "NaN%" || "Infinity%"
                      ? "0%"
                      : weeklyPatientPercent}
                  </h4>
                </div>
              </div>
            </Col>
          </Row>

          <Row style={boxStyle2} className="mb-4 box-radius-16 p-4">
            <Col
              xs="12"
              className="my-2 d-flex flex-column flex-md-row justify-content-between align-items-center"
            >
              <h2 className="w-auto font-fontFamily fontSize-28 text-color-primary mb-4">
                Monthly Revenue
              </h2>
              {/* <Link className="w-auto font-fontFamily fontSize-14 text-color-primary">
                Advance Report <i className="fas fa-arrow-right"></i>
              </Link> */}
            </Col>
            <Col xs="12">
              <div className="w-100">
                <Bar
                  data={data2}
                  options={{ ...options2, maintainAspectRatio: false }}
                  style={{ height: "400px" }}
                />
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h2 className="font-fontFamily fontSize-20">
                Upcoming Appointments
              </h2>
            </div>
            <div
              className="font-fontFamily fontSize-14 text-color-primary font-hover-underline"
              onClick={() => history.push(`/all-appointment`)}
              role="button"
            >
              All Appointments <img src={navigateForword} alt="forword" />
            </div>
          </div>
          <Row>
            <Col className="col-12">
              <Row className="mt-20">
                <TableContainer
                  columns={columns}
                  data={upcomingAppointments || []}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  emptyListLink="/add-appointment"
                  emptyText="No Appointments found"
                  emptyLinkText="+ Add Appointment"
                  customPageSize={10}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Appointments.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

export default Appointments
