import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector } from "react-redux"
import { getAllDiagnosis } from "store/slices"
import ReactSelect from "react-select"
import AsyncSelect from "react-select/async"
import { modifyDiagnosis } from "helpers/utils"
import { BASE } from "helpers/api_helper"
import debounce from "lodash.debounce"
import axios from "axios"

const TypeModal = ({ type, onConfirm, onCloseClick }) => {
  const [checked, setIsChecked] = useState(false)
  const [title, setTitle] = useState("")
  const [addFromList, setAddFromList] = useState(false)
  const [selectedDiagnosis, setSelectedDiagnosis] = useState(null)
  const [, setValue] = useState("")

  useEffect(() => {
    setIsChecked(false)
    setTitle("")
  }, [type])

  const handleSubmit = () => {
    handleConfirm()
  }

  const Map = {
    primaryDiagnosis: "Diagnosis",
    patientAllergies: "Allergies",
    operativePlan: "Operative Plan",
  }

  const handleConfirm = () => {
    const values = {}
    if (type === "primaryDiagnosis") {
      values.type = checked ? "secondary" : "primary"
    }
    values.selectedData = selectedDiagnosis
    if (type === "primaryDiagnosis") {
      values.title = selectedDiagnosis.Term_Name
      values.id = selectedDiagnosis.Code
    } else {
      values.title = title
      values.id = title
    }

    console.log(values, type)
    onConfirm(values, type)
  }

  const handleSecondaryDiagnosis = _checked => {
    setIsChecked(_checked)
  }

  // handle input change event
  const handleInputChange = value => {
    console.log(value)
    setValue(value)
  }
  const debouncedResults = useMemo(() => {
    return debounce(handleInputChange, 1000)
  }, [])

  // handle selection
  const handleChange = value => {
    // setSelectedValue(value)
    setSelectedDiagnosis(value)
  }

  // load options using API call
  const loadOptions = async inputValue => {
    const { data } = await axios.get(
      `${BASE}api/Diagnosis/v1/searchDiagnosis/${inputValue}`
    )
    return data.data || []
  }

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })
  return (
    <Modal isOpen={!!type} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Col lg={12}>
          <div>
            <h2>Add {Map[type]}</h2>
            <br />
            <Form className="form-horizontal" onSubmit={handleSubmit}>
              <div>
                <Row>
                  <Col lg={12} className="mb-3">
                    <Label className="col-form-label">Title</Label>
                    {type !== "primaryDiagnosis" && (
                      <>
                        <Input
                          name="title"
                          // value={name}
                          id="ttil"
                          className="form-control"
                          placeholder="Enter title"
                          type="text"
                          onChange={e => setTitle(e.target.value)}
                          value={title || ""}
                        />
                      </>
                    )}

                    {type === "primaryDiagnosis" && (
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={selectedDiagnosis}
                        getOptionLabel={e => e.Term_Name}
                        getOptionValue={e => e.Code}
                        loadOptions={loadOptions}
                        onInputChange={debouncedResults}
                        onChange={handleChange}
                      />
                    )}
                    {/* {type === "primaryDiagnosis" && (
                      <span
                        className="text-primary"
                        role={"button"}
                        tabIndex={0}
                        onClick={() => setAddFromList(prev => !prev)}
                      >
                        {!addFromList ? "Add from list" : "Add Custom"}
                      </span>
                    )} */}
                  </Col>

                  {type === "primaryDiagnosis" && (
                    <Col lg={12} className="mb-3">
                      <div className="form-check form-checkbox-outline form-check-warning">
                        <input
                          type="checkbox"
                          defaultChecked={checked}
                          className="form-check-input"
                          onChange={e => {
                            handleSecondaryDiagnosis(e.target.checked)
                          }}
                        />
                        <label className="form-check-label">
                          Secondary Diagnosis
                        </label>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
              <Row>
                <Col>
                  <div className="mt-3">
                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg ms-2"
                        // onClick={handleConfirm}
                      >
                        Confirm
                      </button>
                      <button
                        type="button"
                        className="btn  btn-primary btn-danger btn-lg ms-2"
                        onClick={onCloseClick}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </ModalBody>
    </Modal>
  )
}

TypeModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default TypeModal
