import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import Lottie from "react-lottie"
import * as animationData from "../../assets/images/lottie/empty.json"
import { Link } from "react-router-dom"

const EmptyTableContainer = ({ emptyListLink, emptyText, emptyLinkText }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <React.Fragment>
      <div className="empty-container">

        {emptyListLink && (
          <Link className="btn btn-secondary" to={emptyListLink}>  <Lottie options={defaultOptions} height={100} width={100} />{emptyLinkText || "+ Add"}</Link>
        )}
        </div>
    </React.Fragment>
  )
}
export default EmptyTableContainer
