import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

export const ALLOWED_HTML_TAGS_IN_NOTIFICATION = ['a', 'b', 'u', 'i', 'strong'];


const Message = ({ className, id, label, onClose, hide, status, overrideDefaultAnimation }) => {
  const lastActiveElement = useRef(null);
  const closeButtonRef = useRef(null);
  const handleTheFocusBeforeUnmount = () => {
    const currentCloseBtnRef = closeButtonRef.current;
    if (document.activeElement === currentCloseBtnRef) {
      lastActiveElement.current.focus();
    }
  };
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        handleTheFocusBeforeUnmount();
        onClose();
      }
    },
    [onClose]
  );
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.click();
    }
  };
  useEffect(() => {
    lastActiveElement.current = document.activeElement;
    if (!hide) document.addEventListener('keydown', handleKeyDown);
    return () => {
      handleTheFocusBeforeUnmount();
      if (!hide) document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, hide]);

  // eslint-disable-next-line consistent-return
  const transform = (node) => {
    if (node.type === 'tag' && !ALLOWED_HTML_TAGS_IN_NOTIFICATION.includes(node.name)) {
      return node?.children[0]?.data || null;
    }
  };
  const options = {
    decodeEntities: true,
    transform
  };

  if (!label) return null;
  return (
    <div
      className={`notification-message notification-message--${status} ${
        hide ? 'notification-message--hide' : ''
      } ${className}`}
      style={overrideDefaultAnimation ? { animation: 'none' } : {}}
    >
      <div
        className="notification-message__header"
        id="notification-message"
        role={!hide ? 'alert' : null}
        aria-atomic={!hide ? 'true' : null}
      >
        {typeof label === 'string' ? <p>{ReactHtmlParser(label, options)}</p> : label}
      </div>
      <div
        onClick={() => onClose(id)}
        onKeyPress={handleKeyPress}
        className="notification-message__close"
        ref={closeButtonRef}
        role="button"
        tabIndex="0"
      >
        Close
      </div>
    </div>
  );
};

Message.defaultProps = {
  className: '',
  hide: false,
  onClose: () => {},
  status: 'info',
  overrideDefaultAnimation: false
};

Message.propTypes = {
  hide: PropTypes.bool,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClose: PropTypes.func,
  status: PropTypes.string,
  overrideDefaultAnimation: PropTypes.bool
};

export default Message;
