import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar_placeholder.png"
import { getDetails, getUserDetails } from "store/slices"
import { set } from "lodash"
import { BASE } from "helpers/api_helper"
import { IconSVG } from "components/Common/IconSvg"
import avatar1 from "../../../assets/images/users/avatar_placeholder.png"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const userDetails = useSelector(getUserDetails)
  const [img, setImg] = useState(BASE + userDetails?.images[0])
  const dispatch = useDispatch()

  useEffect(() => {
    try {
      const isAuthProtected = localStorage.getItem("authUser")
      if (!isAuthProtected && !JSON.parse(isAuthProtected)._id) {
        localStorage.removeItem("authUser")
        history.push("/login")
        return
      }

      if (isAuthProtected && JSON.parse(isAuthProtected)._id) {
        dispatch(getDetails())
      } else {
        localStorage.removeItem("authUser")
        history.push("/login")
      }
    } catch (error) {
      localStorage.removeItem("authUser")
      history.push("/login")
    }
  }, [dispatch])

  const handleLogout = () => {
    localStorage.removeItem("authUser")
  }
  const handleImageError = e => {
    console.log(">>>>", e)
    setImg(avatar1)
  }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {userDetails?.images[0] ? (
            <img
              className="rounded-circle header-profile-user"
              src={userDetails?.images[0]}
              alt="Header Avatar"
              // onError={handleImageError}
            />
          ) : (
            <i className="bx bx-user font-size-16 align-middle me-1" />
          )}

          <span className="d-none d-xl-inline-block ms-2 me-1">
            {userDetails?.fullName}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem> */}
          <Link className="dropdown-item" to="/profile">
            <IconSVG icon="profile" /> Profile
          </Link>
          {/* </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/login" onClick={handleLogout} className="dropdown-item">
            <IconSVG icon="logout" /> <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

export default ProfileMenu
