// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-select-wrapper {
  width: 150px;
  text-align: start;
}

.new-actions-cta .react-select-wrapper {
  margin-right: -35px;
  margin-top: 5px;
}

.mb-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/custom/pages/_categoryPage.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF","sourcesContent":[".react-select-wrapper {\n  width: 150px;\n  text-align: start;\n}\n\n.new-actions-cta .react-select-wrapper {\n  margin-right: -35px;\n  margin-top: 5px;\n}\n\n.mb-2{\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
