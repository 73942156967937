import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"

const CarouselPage = ({ landingPage, imageTitle }) => {
  return (
    <React.Fragment>
      <Col xl={7}>
        <div className="auth-full-bg w-100">
          <div className="w-100 d-flex flex-column justify-content-center background-image-bottom">
            <div className="landingPage-text-div">
              <h3
                className="font-fontFamily font-weight-500 fontSize-24"
                style={{
                  color: "#11142D",
                }}
              >
                {imageTitle}
              </h3>
              <p style={{ color: "#808191" }} className="fontSize-16">
                Quickly get a view of all the business
              </p>
              {/* <div className="d-flex gap-2">
                <div
                  style={{
                    width: "8px",
                    aspectRatio: "1",
                    background: "#652B88",
                    borderRadius: "50%",
                  }}
                ></div>
                <div
                  style={{
                    width: "8px",
                    aspectRatio: "1",
                    background: "#D8D8DC",
                    borderRadius: "50%",
                  }}
                ></div>
                <div
                  style={{
                    width: "8px",
                    aspectRatio: "1",
                    background: "#D8D8DC",
                    borderRadius: "50%",
                  }}
                ></div>
              </div> */}
            </div>
            <div className="landingPage-img-div">
              <img
                src={landingPage}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
