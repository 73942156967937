import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { addImagingRequest, updateImaging } from "helpers/api_helper"
import { showMessage } from "components/Notification"
import Type from "../Type"
import {
  getPricingDetails,
  getUserDetails,
  getpricing,
  setGlobalLoading,
} from "store/slices"
import { useDispatch, useSelector } from "react-redux"
import ReactSelect from "react-select"
import { modifyTestType, modifyTestTypeOptions } from "helpers/utils"

const ImagingAndLabTest = ({ relationIds,onLabTestValues,id }) => {
  const [modal, setModal] = useState(false)
  const [typeOfModal, setTypeOfModal] = useState("")
  const [patientTests, setPatientTests] = useState([])
  const userDetails = useSelector(getUserDetails)

  const dispatch = useDispatch()
  // ********************************

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      appointmentId:"",
      type: "",
      notes: "",
      name: "",
    },
    validationSchema: Yup.object({
      // type: Yup.string().required("Please Enter type"),
      notes: Yup.string().required("Please Enter notes"),
    }),
    onSubmit: async values => {
      handleConfirm(values)
    },
  })

  const handleConfirm = async values => {
    const obj = {
      appointmentId:id,
      type: testType.value,
      notes: values.notes,
      status: "Requested",
      scansOrReports: [],
      testType: "labs",
      name: testName.value,
      labTests: patientTests,
      pricing: parseInt(testName.pricing),
      ...relationIds,
    }
    const labTestValues = {
      title: testName.value,
      description: "Lab Test for your pet",
      url: "",
      instructions: values.notes,
    }
    onLabTestValues(labTestValues)
    try {
      const res = await addImagingRequest(obj)
      if (res.status === 1) {
        showMessage({ label: "Added Successfully", status: "success" })
        console.log("setTypeOfModal")
        setTypeOfModal("")
      } else {
        showMessage({ label: "Something went wrong", status: "error" })
      }
    } catch (error) {
      showMessage({ label: "Something went wrong", status: "error" })
    } finally {
      setTypeOfModal("")
      setPatientTests([])
      setModal("")
    }
  }
  const handleDataSet = data => {
    const _patientTests = [...patientTests]
    _patientTests.push(data)
    setPatientTests(_patientTests)

    setTypeOfModal("")
  }
  const handleOpenModal = type => {
    setTypeOfModal(type)
  }
  const renderTests = () => {
    return (
      <div className="cell-tag-container">
        {patientTests?.length ? (
          <>
            <h6>Tests:</h6>
            <div className="cell-tags-roe">
              {patientTests.map(({ title }, index) => {
                return (
                  <div key={`${title}-${index}`} className="cell-tags">
                    <button
                      className="cell-tag-close"
                      onClick={() => handlePatientTestsDelete(index)}
                    >
                      x
                    </button>
                    {title}
                  </div>
                )
              })}
            </div>
          </>
        ) : null}
      </div>
    )
  }
  const handlePatientTestsDelete = index => {
    const _patientTests = [...patientTests]
    _patientTests.splice(index, 1)
    setPatientTests(_patientTests)
  }
  console.log(typeOfModal)

  useEffect(() => {
    async function fetchPricingData() {
      if (userDetails?._id) {
        dispatch(setGlobalLoading(true))
        await dispatch(getPricingDetails({ vetId: userDetails?._id }))
        dispatch(setGlobalLoading(false))
      }
    }
    fetchPricingData()
  }, [dispatch])
  const [testType, settestType] = useState(null)
  const [testName, setTestName] = useState(null)

  // const LabTestTypeOptions = [
  //   {
  //     value: "Hematology",
  //     label: "Hematology",
  //   },
  //   { value: "Clinical Chemistry", label: "Clinical Chemistry" },
  //   { value: "Clinical Pathology", label: "Clinical Pathology" },
  //   { value: "Parasitology", label: "Parasitology" },
  //   { value: "Infectious Diseases", label: "Infectious Diseases" },
  //   { value: "Coagulation Disorders", label: "Coagulation Disorders" },
  //   { value: "Endocrinology", label: "Endocrinology" },
  //   { value: "Inflammation", label: "Inflammation" },
  //   { value: "Musculoskeletal", label: "Musculoskeletal" },
  //   { value: "Musculoskeletal", label: "Musculoskeletal" },
  //   { value: "Ultrasonography", label: "Ultrasonography" },
  //   { value: "Gastroenterology", label: "Gastroenterology" },
  //   { value: "Parasitology", label: "Parasitology" },
  // ]
  const dummyAppointment = useSelector(getpricing)
  console.log("dummyAppointment", dummyAppointment)
  const filterPricingOptions = dummyAppointment
    .filter(ele => ele.category.pricingCategoryName === "Lab Pricing")
    .filter(ele => ele.testingType === testType?.value)
  console.log("filterPricingoptions", filterPricingOptions)

  const LabTestTypeOption1 = dummyAppointment
    .filter(ele => ele.category.pricingCategoryName === "Lab Pricing")
    .filter((obj, index, array) => {
      const firstIndex = array.findIndex(
        item => item.testingType === obj.testingType
      )
      return index === firstIndex
    })
  const LabTestTypeOptions = modifyTestTypeOptions(LabTestTypeOption1)

  const handleOptionChange = value => {
    console.log(value)
    setTestName({})
    settestType(value)
  }
  return (
    <>
      <button
        type="button"
        className="btn btn-dark"
        value="Add"
        style={{ marginRight: 12 }}
        onClick={() => setModal(true)}
      >
        + Add Lab Tests
      </button>
      {typeOfModal && (
        <Type
          type={typeOfModal}
          onConfirm={handleDataSet}
          onCloseClick={() => {
            setTypeOfModal("")
          }}
        />
      )}
      {modal ? (
        <Modal
          isOpen={modal}
          toggle={() => setModal(prevState => !prevState)}
          // centered={true}
          size="md"
        >
          <ModalBody className="py-3 px-5">
            <Col lg={12}>
              <div>
                <h2 className="font-fontFamily">Lab Tests</h2>
                <br />

                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  
                  <div>
                    <div className="col-lg-12">
                    <Label className="col-form-label text-input-label font-fontFamily">
                      Type
                    </Label>
                    <div className="col-lg-11">
                      <ReactSelect
                        name="type"
                        value={testType}
                        onChange={handleOptionChange}
                        // isDisabled={!!labId}
                        options={LabTestTypeOptions}
                        classNamePrefix="form-react-select"
                        className="form-react-select"
                      />
                    </div>
                    </div>
                    <div>
                    <Label className="col-form-label text-input-label font-fontFamily">
                      Select Test
                    </Label>
                    <div className="col-lg-11">
                      <ReactSelect
                        name="testName"
                        value={testName}
                        onChange={setTestName}
                        // isDisabled={!!labId}
                        options={modifyTestType(filterPricingOptions)}
                        classNamePrefix="form-react-select"
                        className="form-react-select"
                      />
                    </div>
                    </div>
                    <div>
                    <Label className="col-form-label text-input-label font-fontFamily">
                      Notes
                    </Label>
                    <div className="col-lg-11">
                    <Input
                      name="notes"
                      id="ttil"
                      className="form-control"
                      placeholder="Enter Notes"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.notes || ""}
                      invalid={
                        validation.touched.notes && validation.errors.notes
                          ? true
                          : false
                      }
                    />
                    {validation.touched.notes && validation.errors.notes ? (
                      <FormFeedback type="invalid">
                        {validation.errors.notes}
                      </FormFeedback>
                    ) : null}
                    </div>
                  </div>
                  </div>
                  <div className="mt-3 col-lg-11">
                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg ms-2"
                      >
                        Confirm
                      </button>
                      <button
                        type="button"
                        className="btn  btn-primary btn-danger btn-lg ms-4"
                        onClick={() => setModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  )
}

ImagingAndLabTest.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default ImagingAndLabTest
