import PropTypes from "prop-types"
import React from "react"
import Lottie from "react-lottie"
import { Modal, ModalBody } from "reactstrap"

import * as animationData from "../../assets/images/lottie/delete.json"

const DeleteModal = ({ show, onDeleteClick, onCloseClick }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <Lottie options={defaultOptions} height={48} width={48} />
            </div>
          </div>
          <p className="text-muted font-size-16 mb-4">
            Are you sure you want to permanently delete this.
          </p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-primary btn-secondary btn-med btn-lg ms-2"
              onClick={onDeleteClick}
            >
              Delete Now
            </button>
            <button
              type="button"
              style={{ minWidth: 140 }}
              className="btn btn-primary btn-lg ms-2"
              onClick={onCloseClick}
            >
              Close
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default DeleteModal
