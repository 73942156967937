import React from "react"
import PropTypes from "prop-types"

export const ButtonMain = props => {
  const defaultProps = { ...props }
  delete defaultProps.isLoading
  return (
    <button {...defaultProps}>
      {props.isLoading ? (
        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
      ) : (
        props.children
      )}
    </button>
  )
}

export default ButtonMain
