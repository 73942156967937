import React from "react"
import Lottie from "react-lottie"
import * as animationData from "../../assets/images/lottie/loading.json"


export const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <div className="global-loading">
      <Lottie options={defaultOptions} height={100} width={100} />
    </div>
  )
}

export default Loading
