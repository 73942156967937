export const validateEmail = email => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  )
}
export const validatePhone = phone => {
  return /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phone)
}

export const getTime = date => {
  date = new Date(date)
  const hours = date.getHours()
  const mins = date.getMinutes()
  return `${hours}:${mins}`
}

export const formatAMPM = date => {
  date = new Date(date)
  var hours = date.getUTCHours()
  var minutes = date.getUTCMinutes()
  var ampm = hours >= 12 ? "PM" : "AM"
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes
  var strTime = hours + ":" + minutes + " " + ampm
  return strTime
}
export const modifyEventsData = data => {
  const _data = data.map(item => {
    const _date = new Date(item.date)
    const _time = item?.time?.split(":")
    if (_time?.length) {
      _date.setHours(Number(_time[0]))
      _date.setMinutes(Number(_time[1]))
    }

    return {
      id: item._id,
      time: item.time,
      title: item.petObjectId?.fullName,
      start: _date,
      className:
        item.status === "scheduled" ? "event-scheduled" : "event-completed",
    }
  })
  return _data
}
export const reasonOptions = [
  { value: "Consultation", label: "Consultation" },
  { value: "Surgery", label: "Surgery" },
  { value: "Vaccination", label: "Vaccination" },
  { value: "Imaging / XRay", label: "Imaging / XRay" },
  { value: "Lab Test", label: "Lab Test" },
  { value: "Injury", label: "Injury" },
]

const appointmentTypes = {
  Consultation: "Virtual Care",
  Surgery: "Primary Care",
  Vaccination: "Vaccination",
  "Imaging / XRay": "Primary Care",
  "Lab Test": "Primary Care",
  Injury: "Urgent Care",
}

// Function to get appointment type by reason
export function getAppointmentType(reason) {
  return appointmentTypes[reason] || "Unknown"
}

// Function to get reason by appointment type
export function getReason(appointmentType) {
  return (
    Object.keys(appointmentTypes).find(
      key => appointmentTypes[key] === appointmentType
    ) || "Unknown"
  )
}

export const modifyAppointmentType = data => {
  const _data = data.map(item => {
    return {
      value: item._id,
      label: item.name || item.fullName,
      ...item,
    }
  })
  return _data
}
export const modifyCountryArray = data => {
  const _data = data.map(item => {
    return {
      value: item.COUNTRY_NAME,
      label: item.COUNTRY_NAME,
    }
  })
  return _data
}
export const modifyStateArray = data => {
  const _data = data.map(item => {
    return {
      value: item.STATE,
      label: item.STATE,
    }
  })
  return _data
}
export const modifyCityArray = data => {
  const _data = data.map(item => {
    return {
      value: item.CITY,
      label: item.CITY,
    }
  })
  return _data
}

export function calculateWeeksOfBirth(dob) {
  // Convert the DOB string to a Date object
  console.log(dob)
  const dobDate = new Date(dob)
  console.log(dobDate)

  // Current date
  const currentDate = new Date()

  // Calculate the difference in milliseconds
  const differenceMs = currentDate - dobDate

  // Convert milliseconds to weeks
  const weeks = differenceMs / (1000 * 60 * 60 * 24 * 7)
  console.log("weeks", Math.round(weeks))

  // Round the weeks to the nearest integer
  return Math.round(weeks)
}

//Modify test Type
export const modifyTestType = data => {
  const _data = data.map(item => {
    return {
      value: item.name,
      label: item.name,
      ...item,
    }
  })
  return _data
}

//Modify options for testType
export const modifyTestTypeOptions = data => {
  const _data = data.map(item => {
    return {
      value: item.testingType,
      label: item.testingType,
      ...item,
    }
  })
  return _data
}

//Modify the inventory data

export const modifyInventoryType = data => {
  const _data = data.map(item => {
    return {
      value: item,
      label: item,
    }
  })
  return _data
}

export const modifyPricingType = data => {
  const _data = data.map(item => {
    return {
      value: item._id,
      label: item.pricingCategoryName,
    }
  })
  return _data
}

export const modifyDiagnosis = data => {
  const _data = data.map(item => {
    return {
      value: item._id,
      label: item.Term_Name || item.Top_level_modelling,
    }
  })
  return _data
}

export const modifyMedicine = data => {
  const _data = data.map((item, index) => {
    if (index > 100) {
      return
    }
    return {
      value: item._id,
      label: item.Medicine || item.Brand,
    }
  })
  return _data
}

export const modifyParentType = data => {
  const _data = data.map(item => {
    return {
      value: item._id,
      label: `${item.name || item.fullName} (${item.email})`,
    }
  })
  return _data
}

export const getDateDDMMYYYY = date => {
  date = new Date(date)
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  let day = date.getUTCDate()
  const suffix = ["th", "st", "nd", "rd"]
  const value = day % 100
  day = `${day}${suffix[(value - 20) % 10] || suffix[value] || suffix[0]}`
  const monthIndex = date.getUTCMonth()
  const year = date.getUTCFullYear()
  const hrs = date.getUTCDate()
  // const monthIndex = date.getUTCMonth()
  return `${day} ${monthNames[monthIndex]}, ${year}`
  // return `${year} ${monthNames[monthIndex]}, ${day}`
}

export const getDateTime = date => {
  date = new Date(date)
  const _date = getDateDDMMYYYY(date)
  const time = date.toLocaleTimeString()
  return `${getMinutesHrsString(_date)} ${getMinutesHrsString(time)}`
}

export const getUserIdFromStorage = () => {
  try {
    let details = localStorage.getItem("authUser")
    details = JSON.parse(details)
    if (details && details._id) {
      return details._id
    } else {
      window.location.assign("/logout")
    }
  } catch (error) {
    window.location.assign("/logout")
  }
}

export function timestampToDatetimeInputString(timestamp) {
  const date = new Date(timestamp + _getTimeZoneOffsetInMs())
  // slice(0, 19) includes seconds
  return date.toISOString().slice(0, 19)
}

function _getTimeZoneOffsetInMs() {
  return new Date().getTimezoneOffset() * -60 * 1000
}

// Timings

export const GetDates = (startDate, daysToAdd) => {
  var aryDates = []

  for (var i = 0; i <= daysToAdd; i++) {
    var currentDate = new Date()
    currentDate.setDate(startDate.getDate() + i)
    aryDates.push({
      value:
        DayAsString(currentDate.getDay()) +
        ", " +
        currentDate.getDate() +
        " " +
        MonthAsString(currentDate.getMonth()) +
        " " +
        currentDate.getFullYear(),
      date: currentDate,
      uniqueKey: currentDate.getDate(),
    })
  }

  return aryDates
}

// export const dateFormatAsdddMMYYYY = date => {
//   date = new Date(date)
//   const formattedDate =
//     DayAsString(date.getDay()) +
//     ", " +
//     date.getDate() +
//     " " +
//     MonthAsString(date.getMonth()) +
//     " " +
//     date.getFullYear()
//   return formattedDate
// }

// function DayAsString(dayIndex) {
//   const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
//   return days[dayIndex];
// }

// function MonthAsString(monthIndex) {
//   const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
//   return months[monthIndex];
// }

function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return 'th'; // covers 11th to 19th
  switch (day % 10) {
    case 1:  return 'st';
    case 2:  return 'nd';
    case 3:  return 'rd';
    default: return 'th';
  }
}

export const dateFormatAsdddMMYYYY = date => {
  date = new Date(date);
  const dayOfWeek = DayAsString(date.getDay());
  const day = date.getDate();
  const ordinalSuffix = getOrdinalSuffix(day);
  const formattedDate =
    dayOfWeek + ", " +
    day + ordinalSuffix + " " +
    MonthAsString(date.getMonth()) + ", " +
    date.getFullYear();
  return formattedDate;
}

function MonthAsString(monthIndex) {
  var d = new Date()
  var month = new Array()
  month[0] = "January"
  month[1] = "February"
  month[2] = "March"
  month[3] = "April"
  month[4] = "May"
  month[5] = "June"
  month[6] = "July"
  month[7] = "August"
  month[8] = "September"
  month[9] = "October"
  month[10] = "November"
  month[11] = "December"

  return month[monthIndex]
}

function DayAsString(dayIndex) {
  var weekdays = new Array(7)
  weekdays[0] = "Sunday"
  weekdays[1] = "Monday"
  weekdays[2] = "Tuesday"
  weekdays[3] = "Wednesday"
  weekdays[4] = "Thursday"
  weekdays[5] = "Friday"
  weekdays[6] = "Saturday"

  return weekdays[dayIndex]
}

export const getStringtime = time => {
  time = new Date(time)
  const hours = time.getUTCHours()
  const mins = time.getUTCMinutes()
  return `${getMinutesHrsString(hours)}:${getMinutesHrsString(mins)}`
}
export const getRealtime = time => {
  time = time.split(":")
  const d = new Date()
  d.setHours(time[0])
  d.setMinutes(time[1])
  return d
}

// Get Time Slot From and end

export const addMinutes = (time, minutes) => {
  var date = new Date(
    new Date("01/01/2015 " + time).getTime() + minutes * 60000
  )
  var tempTime =
    (date.getHours().toString().length == 1
      ? "0" + date.getHours()
      : date.getHours()) +
    ":" +
    (date.getMinutes().toString().length == 1
      ? "0" + date.getMinutes()
      : date.getMinutes()) +
    ":" +
    (date.getSeconds().toString().length == 1
      ? "0" + date.getSeconds()
      : date.getSeconds())
  return tempTime
}

export const getTimeSlots = (starttime, endtime, interval) => {
  var timeslots = [{ time: starttime, status: "" }]

  while (starttime != endtime) {
    starttime = addMinutes(starttime, interval)
    timeslots.push({ time: starttime, status: "" })
  }

  return timeslots
}

export const getMinutesHrsString = f => {
  if (f.toString().length === 1) {
    return `0${f}`
  }
  return f
}

export const disableTodaysAppointmentIdPassedOrComingInAnHour = time => {
  const currentTime = new Date()
  currentTime.setSeconds(0)
  currentTime.setHours(11) // need to remove
  let _minutes = currentTime.getMinutes()
  _minutes = _minutes + 60
  currentTime.setMinutes(_minutes)
  const arr = time.split(":")
  const _time = new Date().setHours(arr[0], arr[1], 0)
  return currentTime > _time
}
export const currenttTimeIs60MinsLessThanSelected = time => {
  let currentTime = new Date()
  currentTime.setSeconds(0)
  let _minutes = currentTime.getMinutes()
  _minutes = _minutes
  currentTime.setMinutes(_minutes)
  currentTime = new Date(currentTime)
  console.log(currentTime, time, currentTime < time)
  return currentTime < time
}

export const modifyImagingAndLab = (data, type = "imagingTests") => {
  const _data = data.map(item => {
    return item[type].map(el => {
      return {
        ...el,
        petDetails: item.petObjectId,
        date: item.createdAt,
        _id: el._id,
      }
    })
  })
  return [].concat.apply([], [..._data])
}

export const modifySpecializations = data => {
  const _data = data.map(item => {
    return {
      label: item.title,
      value: item.title,
      subMenu: item?.subCategory?.map(el => {
        return {
          label: el,
          value: el,
        }
      }),
    }
  })

  return [].concat.apply([], [..._data])
}

//Modifying array to give total appointments and their dates
export const getAppointmentsByWeek = data => {
  const groups = data.reduce((acc, item) => {
    const date = new Date(item.date)
    const start = new Date(date.getFullYear(), date.getMonth(), date.getDate())

    if (!acc[start]) {
      acc[start] = {
        count: 0,
        times: [],
        events: [],
      }
    }

    acc[start].count++
    acc[start].times.push(item.time)
    acc[start].events.push({
      id: item._id,
      time: item.time,
      title: item.petObjectId?.fullName,
      start: date,
      className:
        item.status === "scheduled" ? "event-scheduled" : "event-completed",
    })

    return acc
  }, {})

  const _data = Object.entries(groups).map(
    ([start, { count, times, events }]) => ({
      title: `${count} Appointment${count > 1 ? "s" : ""}`,
      start: new Date(start),
      extendedProps: {
        times: times,
      },
      allDay: true,
      events: events,
    })
  )

  return _data
}
