import PropTypes from "prop-types"
import React, { useState } from "react"
import Lottie from "react-lottie"
import {
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import * as animationData from "../../assets/images/lottie/delete.json"
import {
  getGenericCategory,
  getPricingCategory,
  getUserDetails,
} from "store/slices"
import { useDispatch, useSelector } from "react-redux"
import { addNewPricingCategory } from "helpers/api_helper"
import "../../assets/scss/custom/pages/_formModal.scss"
import ReactSelect from "react-select"
import Drawer from "@mui/material/Drawer"
import { showMessage } from "components/Notification"
import { Link, useLocation } from "react-router-dom"
const InfoMessage = ({ open, handleCloseDrawer }) => {
  const dispatch = useDispatch()
  const userDetails = useSelector(getUserDetails)
  const [details, setDetails] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const [selectedOption, setSelectedOption] = useState(null)

  const [pricingOption, setPricingOption] = useState(null)

  const selectOptions = [
    { value: "PriceCategories", label: "Pricing Categories" },
    // { value: "generic_pricing", label: "Generic Pricing" },
    // Add more options as needed
  ]

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleCloseDrawer}
      PaperProps={{ style: { width: "400px" } }}
    >
      <Row className="mt-4">
        <h3 className="font-size-24 mb-4 font-fontFamily mx-4">
          Follow the Steps
        </h3>
        <button
          type="button"
          onClick={() => {
            handleCloseDrawer()
          }}
          className="btn-close position-absolute end-0 top-0 m-3 mt-4 pt-4"
        ></button>
      </Row>

      <div className="d-flex gap-2 flex-column justify-content-center align-items-center">
        <div
          style={{
            width: "90%",
            height: "fit-content",
            border: "1px solid orange",
            borderRadius: "15px",
          }}
        >
          <p className="mt-2 p-4 font-fontFamily">
            Customize your clinic's{" "}
            <Link to="/timings">operational hours here</Link>. After logging in,
            access the Administration Section to update availability for the
            week or specific days.
          </p>
        </div>
        <div
          style={{ width: "90%", height: "fit-content", border: "1px solid orange",borderRadius: "15px", }}
        >
          <p className="mt-2 p-4 font-fontFamily" >
            {" "}
            Here, you can tailor your clinic's{" "}
            <Link to="/pricing">Imaging and Lab tests pricing</Link> with ease.
            Once logged in, navigate to the Billing Section to modify Pricing
            for different tests, ensuring accurate and up-to-date lab pricing
            information.
          </p>
        </div>
        <div
          style={{ width: "90%", height: "fit-content", border: "1px solid orange",borderRadius: "15px", }}
        >
          <p className="mt-2 p-4 font-fontFamily">
            This is where you can provide details about both{" "}
            <Link to="/profile">yourself and your clinic</Link>. This
            information will be utilized throughout the system, including areas
            such as invoices, your appointment profile within the Pet Parent
            app, and prescriptions.
          </p>
        </div>
      </div>
    </Drawer>
  )
}

InfoMessage.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default InfoMessage
