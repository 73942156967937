import PropTypes from "prop-types"
import React from "react"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import logo from "../../assets/images/supaw-vets.svg"
import logoLightPng from "../../assets/images/supaw-vets.svg"
import logoDark from "../../assets/images/supaw-vets.svg"
import smallLogo from "../../assets/images/logo-1.png"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
import { useDispatch } from "react-redux"
import { changeSidebarType } from "store/actions"
const Sidebar = props => {
  const leftSideBarType = useSelector(state => state.Layout.leftSideBarType)
  // console.log(leftSideBarType)

  const dispatch = useDispatch()

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
      if (leftSideBarType === "default") {
        dispatch(changeSidebarType("condensed"))
      } else {
        dispatch(changeSidebarType("default"))
      }
    }
  }
  const rotateImgStyle = {
    transform: "rotate(180deg)",
  }
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <div className="logo logo-dark">
            <span className="logo-sm ----" >
              <div className="d-flex-column justify-content-center align-items-center">
                <img
                  src={LeftArrow}
                  alt=""
                  height="30"
                  onClick={() => {
                    tToggle()
                  }}
                  style={rotateImgStyle}
                />
                {/* <img src={smallLogo} alt="" height="22" /> */}
              </div>
            </span>
            <span className="logo-lg">
              <div className="d-flex align-items-center justify-content-between gap-4 mt-3">
                <img src={logoDark} alt="" height="40" />
                <img
                  src={LeftArrow}
                  alt=""
                  height="30"
                  onClick={() => {
                    tToggle()
                  }}
                />
              </div>
            </span>
          </div>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm ---333">
              <img src={logoLightPng} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="30" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
