import { combineReducers } from "redux"

import Patients from "./slices"
import Layout from "./layout/reducer"

const rootReducer = combineReducers({
  Patients,
  Layout,
})

export default rootReducer
