import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import { isEmpty, map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Import Image
import logo from "../../../assets/images/supaw-vets.svg"
import { fetchAppointmentsBasedOnId } from "helpers/api_helper"
import { getDateDDMMYYYY } from "helpers/utils"
import { useDispatch } from "react-redux"
import { setGlobalLoading } from "store/slices"

import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"

const InvoiceDetail = props => {
  const [details, setDetails] = useState({})
  const [noPrescriptionFound, setPrescriptionFound] = useState(false)
  console.log("Details", details)
  //meta title
  document.title = "Prescription Detail | Supaw"

  const {
    match: { params },
  } = props
  const dispatch = useDispatch()
  useEffect(() => {
    async function fetchData() {
      document.body.classList.add("prescription-container")
      if (params && params.id ) {
        dispatch(setGlobalLoading(true))
        const _details = await fetchAppointmentsBasedOnId(params.id)
        if (_details.status === 1 && _details.data) {
          setDetails(_details.data)
          dispatch(setGlobalLoading(false))
        } else {
          setPrescriptionFound(true)
          dispatch(setGlobalLoading(false))
        }
      }
    }
    fetchData()
  }, [params])

  //Print the Invoice
  const printInvoice = () => {
    window.print()
  }
  const _specialization =
    typeof details?.doctorId?.specialization === "object"
      ? details?.doctorId?.specialization?.map(el => el.label).join(", ")
      : details?.doctorId?.specialization
      const currentDate = new Date();
      const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}${(currentDate.getMonth() + 1).toString().padStart(2, '0')}${currentDate.getFullYear()}`;
  const handleDownloadPDF = () => {
    dispatch(setGlobalLoading(true))
    const content = document.querySelector(".download-pdf-content")

    const scale = 0.8 // Adjust the scale factor to control the zoom level

    html2canvas(content, {
      scale: scale,
      useCORS: true,
      scrollX: 0,
      scrollY: -window.scrollY,
    }).then(canvas => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF("p", "mm", "a4")
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = pdfWidth * scale
      const imgHeight = pdfHeight * scale
      const offsetX = (pdfWidth - imgWidth) / 2
      const offsetY = (pdfHeight - imgHeight) / 2
      pdf.addImage(
        imgData,
        "PNG",
        offsetX,
        offsetY,
        imgWidth,
        imgHeight,
        "",
        "FAST"
      )
      pdf.save(`Prescription-${details?.petObjectId?.petId}-${formattedDate}.pdf`)
      // setIsLoading(false);
      dispatch(setGlobalLoading(false))
    })
  }
  return (
    <React.Fragment>
      <div className="page-content prescription-container">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Invoices" breadcrumbItem="Prescription: Detail" />
          {!isEmpty(details) || !noPrescriptionFound ? (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="download-pdf-content">
                      <div className="invoice-title">
                        <h4 className="float-end font-size-20">
                          Pet Id:{" "}
                          <span className="text-primary">
                            {details?.petObjectId?.petId}
                          </span>
                        </h4>
                        <div className="mb-4">
                          <div className="prescription-doc-details">
                            <img src={logo} alt="logo" height="20" />
                            <span className="prescription-doc-details-name mt-2">
                              {details?.doctorId?.fullName}
                            </span>
                            <span className="prescription-doc-details-name mt-2">
                              {details?.doctorId?.clinicName}
                            </span>
                            <span className="prescription-doc-details-name mt-2">
                              {details?.doctorId?.address}
                            </span>
                            <span className="prescription-doc-details-name mt-2">
                              {details?.doctorId?.email}
                            </span>
                            <span className="prescription-doc-details-name mt-2">
                              +91-{details?.doctorId?.phone}
                            </span>
                            {/* <span className="prescription-doc-details-spec">
                              {_specialization || "NA"}
                            </span> */}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <Row>
                        <Col sm="4">
                          <span>
                            {" "}
                            <h3 className="font-size-15 fw-bold">
                              Invoice To
                            </h3>{" "}
                            {details?.parentId?.fullName}
                            {/* {details?.petObjectId?.fullName} */}
                          </span>
                        </Col>
                        <Col sm="4">
                          <span>
                            {" "}
                            <h3 className="font-size-15 fw-bold">
                              Admission Date
                            </h3>{" "}
                            {getDateDDMMYYYY(details?.date)}
                            {/* {details?.parentId?.fullName} */}
                          </span>
                        </Col>
                        <Col sm="4">
                          <span>
                            {" "}
                            <h3 className="font-size-15 fw-bold">
                              Appointment Type
                            </h3>{" "}
                            {details?.visitType}
                            {/* {details?.petObjectId?.petType}(
                            {details?.petObjectId?.petBreed}) */}
                          </span>
                        </Col>
                        {/* <Col sm="6" className="text-sm-end">
                          <address>
                            <strong>Date: </strong>
                            {getDateDDMMYYYY(details?.date)}
                          </address>
                        </Col> */}
                      </Row>
                      {details?.medicines?.length && (<>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 fw-bold">Medicines</h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table table-bordered">
                          <thead>
                            <tr>
                              <th style={{ width: "70px" }}>No.</th>
                              <th>Medicine Name</th>
                              <th>Dosage</th>
                              <th>Duration</th>
                              <th className="text-end">Instructions</th>
                            </tr>
                          </thead>
                          <tbody className="table-group-divider">
                            {details?.medicines?.length
                              ? details?.medicines?.map((item, index) => (
                                  <tr key={index}>
                                    <td className="font-size-14-black">
                                      {index + 1}
                                    </td>
                                    <td>
                                      <div className="font-size-14-black">
                                        {item.medicine}
                                      </div>
                                      <div>
                                        {details?.petObjectId?.fullName}(
                                        {details.petObjectId.petBreed}/
                                        {details.petObjectId.gender}/
                                        {details.petObjectId.colour})
                                      </div>
                                    </td>
                                    <td className="font-size-14-black">
                                      {item.dosage}
                                    </td>
                                    <td className="font-size-14-black">
                                      {item.duration}
                                    </td>
                                    <td className="text-end font-size-14-black">
                                      {item.instructions}
                                    </td>
                                  </tr>
                                ))
                              : null}
                          </tbody>
                        </Table>
                      </div>
                      </>
                      )}

                      {
  details?.vaccines?.length ? (
    <>
      <div className="py-2 mt-3">
        <h3 className="font-size-15 fw-bold">Vaccines</h3>
      </div>
      <div className="table-responsive">
        <Table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ width: "70px" }}>No.</th>
              <th>Vaccines Name</th>
              <th>Type</th>
              <th>Date of Vaccine</th>
              <th className="text-end">Expiry Date</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {details?.vaccines?.length ? (
              details?.vaccines?.map((item, index) => (
                <tr key={index}>
                  <td className="font-size-14-black">{index + 1}</td>
                  <td>
                    <div className="font-size-14-black">{item.vaccine}</div>
                    <div>
                      {details?.petObjectId?.fullName}(
                      {details.petObjectId.petBreed}/
                      {details.petObjectId.gender}/
                      {details.petObjectId.colour})
                    </div>
                  </td>
                  <td className="font-size-14-black">{item.type}</td>
                  <td className="font-size-14-black">{item.DOV}</td>
                  <td className="text-end font-size-14-black">
                    {item.EOV}
                  </td>
                </tr>
              ))
            ) : null}
          </tbody>
        </Table>
      </div>
    </>
  ) : null
}

                     
                      {details?.imagingTests?.length ? (
                        <>
                          <div className="py-2 mt-3">
                            <h3 className="font-size-15 fw-bold">
                              Imaging Tests
                            </h3>
                          </div>
                          <div className="table-responsive">
                            <Table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th style={{ width: "70px" }}>No.</th>
                                  <th style={{ width: "470px" }}>Test Name</th>

                                  <th style={{ width: "170px" }}>Description</th>
                                  <th className="text-end">Instructions</th>
                                </tr>
                              </thead>
                              <tbody className="table-group-divider">
                                {details?.imagingTests?.length
                                  ? details?.imagingTests?.map(
                                      (item, index) => (
                                        <tr key={index}>
                                          <td className="font-size-14-black">
                                            {index + 1}
                                          </td>
                                          <td>
                                            <div className="font-size-14-black">
                                              {item.type}
                                            </div>
                                            <div>
                                              {details?.petObjectId?.fullName}(
                                              {details.petObjectId.petBreed}/
                                              {details.petObjectId.gender}/
                                              {details.petObjectId.colour})
                                            </div>
                                          </td>
                                          <td className="font-size-14-black">
                                            {item.result}
                                          </td>
                                          <td className="text-end font-size-14-black">
                                            {item.notes}
                                          </td>
                                        </tr>
                                      )
                                    )
                                  : null}
                              </tbody>
                            </Table>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {details?.labTests?.length ? (
                        <>
                          <div className="py-2 mt-3">
                            <h3 className="font-size-15 fw-bold">Lab Tests</h3>
                          </div>
                          <div className="table-responsive">
                            <Table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th style={{ width: "70px" }}>No.</th>
                                  <th>Test Name</th>

                                  <th>Description</th>
                                  <th className="text-end">Instructions</th>
                                </tr>
                              </thead>
                              <tbody className="table-group-divider">
                                {details?.labTests?.length
                                  ? details?.labTests?.map((item, index) => (
                                      <tr key={index}>
                                        <td className="font-size-14-black">
                                          {index + 1}
                                        </td>
                                        <td>
                                          <div className="font-size-14-black">
                                            {item.title}
                                          </div>
                                          <div>
                                            {details?.petObjectId?.fullName}(
                                            {details.petObjectId.petBreed}/
                                            {details.petObjectId.gender}/
                                            {details.petObjectId.colour})
                                          </div>
                                        </td>
                                        <td className="font-size-14-black">
                                          {item.description}
                                        </td>
                                        <td className="text-end font-size-14-black">
                                          {item.instructions}
                                        </td>
                                      </tr>
                                    ))
                                  : null}
                              </tbody>
                            </Table>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <Row>
                        <Col sm="6">
                          <address>
                            <strong>Primary Diagnosis:</strong>
                            
                            <span className="mx-2">
                              {details?.primaryDiagnosis
                                ?.filter(el => el.type === "primary")
                                .map(el1 => {
                                  return (
                                    <span key={el1.title}>{el1.title}</span>
                                  )
                                })}
                            </span>
                            <br />
                            <strong>Secondary Diagnosis:</strong>

                            <span>
                              {details?.primaryDiagnosis
                                ?.filter(el => el.type === "secondary")
                                .map(el1 => {
                                  return (
                                    <span key={el1.title}>{el1.title}</span>
                                  )
                                })}
                            </span>
                          </address>
                        </Col>
                        <Col sm="6" className="text-sm-end">
                          <address>
                            <strong>Allergy: </strong>
                            <span>
                              {details?.patientAllergies?.map(el1 => {
                                return <span key={el1.title}>{el1.title}</span>
                              })}
                            </span>
                          </address>
                        </Col>
                      </Row>

                      <div className="prescription-notes">
                        <h3 className="font-size-15 fw-bold">Notes: </h3>
                        {details?.notes}
                      </div>
                      <Row>
                        <Col sm="6" className="text-sm-end">
                          <div className="authorized-signatory">
                            <h3 className="text-left px-4">
                              Terms & Conditions
                            </h3>
                            <ol className="font-size-10 text-left">
                              <li>
                                Medicines/Pharmacy products once purchased,
                                cannot not be returned or exchanged.{" "}
                              </li>
                              <li>
                                Items once purchased cannot be returned for
                                refund and the returned amount will be provided
                                as Supaw Pet Clinic Credit Note.
                              </li>
                              <li>
                                The returned product should be unused, with
                                original packaging and product tag intact. The
                                product should be in Resellable condition,
                                discretion of which will lie with Supaw Pet
                                Clinic.
                              </li>
                            </ol>
                          </div>
                        </Col>
                        <Col sm="6" className="text-sm-end mt-auto pb-2">
                          <div className="authorized-signatory">
                            Authorised Signatory{" "}
                          </div>
                        </Col>
                      </Row>
                      <div className="text-center">
                        Prescription generated on Supaw.co
                      </div>
                    </div>
                    <div className="d-print-none">
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={handleDownloadPDF}
                          className="btn me-2"
                          style={{ background: "#E89F23", color: "white" }}
                        >
                          <i className="fa fa-download" />
                        </Link>
                        <Link
                          to="#"
                          onClick={printInvoice}
                          className="btn me-2"
                          style={{ background: "#E75C25", color: "white" }}
                        >
                          <i className="fa fa-print" />
                        </Link>
                        {/* <Link
                          to="#"
                          className="btn w-md "
                          style={{ background: "#E75C25", color: "white" }}
                        >
                          Send
                        </Link> */}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Card>
                <CardBody>
                  <CardText>No Prescription found</CardText>
                </CardBody>
              </Card>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

InvoiceDetail.propTypes = {
  match: PropTypes.any,
}

export default withRouter(InvoiceDetail)
