// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pet-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f5fa;
  padding: 20px;
  margin: 15px 0;
  border-radius: 4px;
}
.pet-list-name {
  margin: 0;
  display: flex;
  align-items: center;
}
.pet-list-age-species {
  margin: 0;
}

.petdetails {
  margin-top: 30px;
}
.petdetails ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.petdetails ul li {
  width: 33%;
  margin-bottom: 30px;
}
.petdetails ul li .title {
  color: #000;
  margin-right: 20px;
}
.petdetails ul li .title:after {
  content: ":";
}`, "",{"version":3,"sources":["webpack://./src/pages/Parents/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;EACA,cAAA;EACA,kBAAA;AACF;AACE;EACE,SAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACE;EACE,SAAA;AACJ;;AAGA;EACE,gBAAA;AAAF;AACE;EACE,gBAAA;EACA,UAAA;EACA,SAAA;EACA,aAAA;EACA,eAAA;AACJ;AAAI;EACE,UAAA;EACA,mBAAA;AAEN;AADM;EACE,WAAA;EACA,kBAAA;AAGR;AAFQ;EACI,YAAA;AAIZ","sourcesContent":[".pet-list {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background: #f5f5fa;\n  padding: 20px;\n  margin: 15px 0;\n  border-radius: 4px;\n  // box-shadow: 2px 2px 5px 0px #c8c8c8;\n  &-name {\n    margin: 0;\n    display: flex;\n    align-items: center;\n  }\n  &-age-species {\n    margin: 0;\n  }\n\n}\n.petdetails  {\n  margin-top: 30px;\n  ul {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n    display: flex;\n    flex-wrap: wrap;\n    li {\n      width: 33%;\n      margin-bottom: 30px;\n      .title {\n        color: #000;\n        margin-right: 20px;\n        &:after {\n            content: ':';\n        }\n        }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
